/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { SkillsetMatching, SkillsetSkills } from 'types/SkillsetMatching';
import { Row, Col, Badge, Tooltip, Popover } from 'antd';
import React from 'react';
import SkillsetMatchingActionsComponent from 'components/Actions/SkillsetMatchingActions';
import { SkillsetContainer } from 'ui-v2/routes/SkillsetMatching/SkillsetMatchingStyles';
import ShortListActions from 'components/Actions/ShortListActions';
import { t } from 'i18next';
import i18next from '../i18n';
import * as Styled from '../ui-v2/components/Skills/SkillsStyles';

const employee: string = i18next.t('employee');
const candidate: string = i18next.t('candidate');
const archivedCandidate: string = i18next.t('archivedCandidate');

const SkillBadges = ({ skills }: { skills: SkillsetSkills[] }) =>
  skills?.map((skill: SkillsetSkills) => (
    <Tooltip title={skill?.description} key={skill?.id}>
      <Badge count={skill?.yearsOfExperience} style={Styled.SkillBadgeStyles}>
        <Styled.Tag>
          <Row justify="center" align="middle" className="skill-tag">
            <Col>
              <Styled.TagLabel>{skill?.subCategoryName}</Styled.TagLabel>
            </Col>
          </Row>
        </Styled.Tag>
      </Badge>
    </Tooltip>
  ));

const CandidateSkillBadges = ({ skills }: any) =>
  skills?.map((skill: any) => (
    <Tooltip title={skill?.description} key={skill?.id}>
      <Badge
        count={skill?.yearsOfExperience}
        style={Styled.SkillBadgeStyles}
        key={skill?.id}
      >
        <Styled.Tag>
          <Row justify="center" align="middle" className="skill-tag">
            <Col>
              <Styled.TagLabel>
                {skill?.skillsSubCategory?.subCategoryName}
              </Styled.TagLabel>
            </Col>
          </Row>
        </Styled.Tag>
      </Badge>
    </Tooltip>
  ));

export const SkillsetColumns = [
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    render: (record: string) =>
      record === 'employee' ? (
        <Styled.LabelStyled color="#CBC3E3" name="label" placeholder="label">
          {employee}
        </Styled.LabelStyled>
      ) : record === 'candidate' ? (
        <Styled.LabelStyled color="#90EE90" name="label" placeholder="label">
          {candidate}
        </Styled.LabelStyled>
      ) : (
        <Styled.LabelStyled color="#FAD5A5" name="label" placeholder="label">
          {archivedCandidate}
        </Styled.LabelStyled>
      ),
    width: 150,
    default: false,
    ellipsis: true,
    order: 0,
  },
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    width: 120,
    default: true,
    order: 1,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    width: 120,
    ellipsis: true,
    default: true,
    order: 2,
  },
  {
    title: i18next.t('applied'),
    dataIndex: 'applied',
    key: 'applied',
    width: 70,
    ellipsis: true,
    default: true,
    order: 3,
    render: (record: SkillsetMatching, row: any) =>
      row.applied ? t('yes') : t('no'),
  },
  {
    title: i18next.t('skills'),
    dataIndex: 'skills',
    key: 'skills',
    width: 250,
    default: false,
    order: 4,
    ellipsis: true,
    render: (record: SkillsetSkills[]) => (
      <div>
        <Popover
          content={
            <div
              style={{
                maxWidth: 400,
                display: 'flex',
                flexWrap: 'wrap',
                padding: '8px',
                gap: '15px',
              }}
            >
              {SkillBadges({ skills: record })}
            </div>
          }
          placement="topLeft"
        >
          <div>
            <SkillsetContainer>
              {SkillBadges({ skills: record })}
            </SkillsetContainer>
          </div>
        </Popover>
      </div>
    ),
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 100,
    render: (record: string, row: SkillsetMatching) => (
      <SkillsetMatchingActionsComponent row={row} />
    ),
    default: false,
    order: 4,
    fixed: 'right',
  },
];

export const CandidateSkillsetColumns = [
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    width: 100,
    default: true,
    order: 0,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    width: 100,
    ellipsis: true,
    default: true,
    order: 1,
  },
  {
    title: i18next.t('skills'),
    dataIndex: 'skills',
    key: 'skills',
    width: 250,
    default: false,
    order: 2,
    ellipsis: true,
    render: (_: string, record: any) => (
      <div>
        <Popover
          content={
            <div
              style={{
                maxWidth: 400,
                display: 'flex',
                flexWrap: 'wrap',
                padding: '8px',
                gap: '15px',
              }}
            >
              {CandidateSkillBadges({
                skills: record?.skillSubCategoriesCandidates,
              })}
            </div>
          }
          placement="topLeft"
        >
          <div>
            <SkillsetContainer>
              {CandidateSkillBadges({
                skills: record?.skillSubCategoriesCandidates,
              })}
            </SkillsetContainer>
          </div>
        </Popover>
      </div>
    ),
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 70,
    render: (record: string, row: SkillsetMatching) => (
      <SkillsetMatchingActionsComponent row={row} />
    ),
    default: false,
    order: 4,
    fixed: 'right',
  },
];

export const ShortListColumns = [
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    render: (_: string, row: any) => {
      if (row.employee !== null) {
        return (
          <Styled.LabelStyled color="#CBC3E3" name="label" placeholder="label">
            {employee}
          </Styled.LabelStyled>
        );
      }
      if (row.candidate?.deletedAt === null) {
        return (
          <Styled.LabelStyled color="#90EE90" name="label" placeholder="label">
            {candidate}
          </Styled.LabelStyled>
        );
      }
      if (row.candidate?.deletedAt !== null) {
        return (
          <Styled.LabelStyled color="#FAD5A5" name="label" placeholder="label">
            {archivedCandidate}
          </Styled.LabelStyled>
        );
      }
      return null;
    },
    width: 100,
    default: false,
    ellipsis: true,
    order: 0,
  },
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    width: 120,
    default: true,
    order: 1,
    render: (_: string, row: any) =>
      row.employee !== null
        ? row?.employee?.firstName
        : row?.candidate?.firstName,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    width: 120,
    ellipsis: true,
    default: true,
    order: 2,
    render: (_: string, row: any) =>
      row.employee !== null
        ? row?.employee?.lastName
        : row?.candidate?.lastName,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 70,
    render: (record: string, row: any) => <ShortListActions row={row} />,
    default: false,
    order: 4,
    fixed: 'right',
  },
];
