import agent from 'api/agent';
import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getFeatureFlagsTenant = (id: string) =>
  agent.get(`${apiURLs.featureFlagsTenant}/${id}`);

export const createFeatureFlagsTenant = (
  payload: UpdateFeatureFlagsTenantDTO
) => agent.post(`${apiURLs.featureFlagsTenant}/post`, payload);

export const deleteFeatureFlagsTenant = (tenantId: string, featureId: string) =>
  agent.delete(`${apiURLs.featureFlagsTenant}/${tenantId}/${featureId}`);

export const updateFeatureFlagsTenants = (
  payload: UpdateFeatureFlagsTenantDTO
) => agent.patch(`${apiURLs.featureFlagsTenant}`, payload);

export const getAllFeatureFlagsTenant = (params: QueryParamsDTO) =>
  agent.post(`${apiURLs.featureFlagsTenant}/get`, params);

export const getFeatureFlagsTenantPagination = (
  tenantId: string,
  params: QueryParamsDTO
) => agent.post(`${apiURLs.featureFlagsTenant}/${tenantId}`, params);
