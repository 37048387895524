import { Col, Popover } from 'antd';
import React from 'react';
import {
  NodataLabel,
  RowItemAsset,
} from 'ui-v2/components/Trainings/TrainingItemStyled';
import { LegalDaysOff } from 'types/LegalDaysOff';
import { formatDate } from 'utils/utilFunctions';

interface IProps {
  data: LegalDaysOff;
}

export function TenantDaysOffItem({ data }: IProps) {
  return (
    <>
      <RowItemAsset>
        <Col span={4}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={data?.daysCount}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {data?.daysCount}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={4}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={formatDate(data?.validFrom)}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {formatDate(data?.validFrom)}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={5}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={formatDate(data?.validTo)}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {formatDate(data?.validTo)}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={4}>
          <NodataLabel>{data?.tenantLocation?.city}</NodataLabel>
          <NodataLabel>{data?.tenantLocation?.country}</NodataLabel>
        </Col>
      </RowItemAsset>
    </>
  );
}
