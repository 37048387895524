import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import {
  getDisabledHours,
  getDisabledMinutes,
  getTime,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { Col, Row } from 'antd';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { AUTH_ROLES } from 'types/Auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPrjoectEmployeeAssignments,
  fetchPrjoectEmployeeAssignments,
  fetchProjectsOfEmployee,
} from 'redux/projects/actions';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { useTranslation } from 'react-i18next';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import {
  TrackedHourCreateDTO,
  TrackedHourDTO,
  TrackedHourJobLocation,
  TrackedHourType,
  TrackedHourUpdateDTO,
} from 'types/tracking';
import { OptionType } from 'types/OptionTypes';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import {
  createTrackingHour,
  updateTrackingHours,
} from 'api/trackedHoursService';
import { Moment } from 'moment';
import { FormItemStyled, StyledButton } from '../FormStyled';
import GenericForm from '../Form';
import {
  defaultBreakTimes,
  getThFormConfigurations,
  getThPayload,
} from './utils';
import { ValueType } from '../SelectWithLoad/SelectWithLoad';
import { isFormEdited } from '../Form/utils';
import { StyledBoldText } from '../ApiKeysForm/ApiKeysFormStyles';
import MultiRangeDatePicker from './ThCalendar';

interface TrackinHourModalFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

type TrackingHoursCalculate = {
  start: any;
  end: any;
  breakStart: any;
  breakEnd: any;
};

type DisabledHours = {
  disabledHours: number[];
  disabledMinutes: number[];
};

export default function TrackinHourModalForm({
  open,
  onClose,
  onSuccess,
}: TrackinHourModalFormProps) {
  const dispatch = useDispatch();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const { authUserRole, authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const [form] = useForm();
  const [hourType, setHourType] = useState<TrackedHourType>(
    TrackedHourType.REGULAR_HOURS
  );
  const [loggedHour, setLoggedHour] = useState<TrackingHoursCalculate>();
  const [loggedTime, setLoggedTime] = useState<string>('0h:00m');
  const [disabledTime, setDisabledTime] = useState<DisabledHours>({
    disabledHours: [],
    disabledMinutes: [],
  });
  const [isRemote, setIsRemote] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: tenantConfig } = useTenantConfigData();
  const {
    trackedHoursDetails: { data: list },
  } = useTrackedHoursData();
  const {
    employeeAssignment: { data: assignments },
    employeeProject,
  } = useProjectsData();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [defaultBreak, setDefaultBreak] = useState(true);
  const [defaultDatesRange, setDefaultDatesRange] = useState(false);
  const [trackedHour, setTrackedHour] = useState<TrackedHourDTO>();
  const [defaultProjectOption, setDefaultProjectOption] =
    useState<OptionType>();
  const [defaultEmployeeOption, setDefaultEmployeeOption] =
    useState<OptionType>();
  const [dates, setDates] = useState<string[]>([]);
  useEffect(() => {
    if (authUserRole === AUTH_ROLES.EMPLOYEE) {
      dispatch(fetchProjectsOfEmployee());
    }
  }, [authUserRole]);

  const onEditHour = (id: string | null) => {
    if (!id) {
      onClose();
      return;
    }
    const foundTrackedHour = list?.find((item) => item?.id === id);

    if (foundTrackedHour?.employee?.id) {
      if (!authUser?.employee?.id) {
        dispatch(
          fetchPrjoectEmployeeAssignments(foundTrackedHour.employee?.id)
        );
      }
      setTrackedHour(foundTrackedHour);
      if (foundTrackedHour?.assignment?.project?.id) {
        setDefaultProjectOption({
          id: foundTrackedHour?.assignment?.id,
          label: `${foundTrackedHour?.assignment?.project?.name}`,
          value: foundTrackedHour?.assignment?.id,
        });
        dispatch(
          fetchPrjoectEmployeeAssignments(
            foundTrackedHour?.employee?.id as string
          )
        );
      }
      setDefaultEmployeeOption({
        id: foundTrackedHour?.employee?.id,
        label: `${foundTrackedHour?.employee?.firstName} ${foundTrackedHour?.employee?.lastName}`,
        value: foundTrackedHour?.employee?.id,
      });
      setIsRemote(!!foundTrackedHour?.isRemote);
      searchParams.delete('edit-hour');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-hour')) {
      onEditHour(searchParams.get('edit-hour') as string);
    }
  }, [searchParams]);

  const breakStartTimeDefaultValue = trackedHour?.breakStartTime;
  const breakEndTimeDefaultValue = trackedHour?.breakEndTime;

  useEffect(() => {
    if (
      trackedHour?.breakStartTime === null ||
      trackedHour?.breakEndTime === null
    ) {
      setDefaultBreak(false);
      form.setFieldsValue({
        breakStartTime: null,
        breakEndTime: null,
      });
    } else {
      setDefaultBreak(true);
      form.setFieldsValue({
        breakStartTime: getTime(breakStartTimeDefaultValue),
        breakEndTime: getTime(breakEndTimeDefaultValue),
      });
    }
  }, [trackedHour]);

  useEffect(() => {
    if (defaultBreak) {
      form.setFieldsValue({
        breakStartTime: getTime(defaultBreakTimes.breakStartTime),
        breakEndTime: getTime(defaultBreakTimes.breakEndTime),
      });
    } else {
      form.setFieldsValue({
        breakStartTime: null,
        breakEndTime: null,
      });
    }
  }, [defaultBreak]);

  const onEmployeeSelect = useCallback(
    (selectedItem: ValueType) => {
      form.resetFields(['projectId']);
      setDefaultProjectOption(undefined);
      setDefaultEmployeeOption({
        id: selectedItem?.value as string,
        label: selectedItem?.label as string,
        value: selectedItem.value as string,
      });
      dispatch(fetchPrjoectEmployeeAssignments(selectedItem?.value as string));
    },
    [form]
  );

  const onProjectSelect = useCallback((selectedItem?: string) => {
    if (selectedItem) {
      form.resetFields(['projectId']);
      setDefaultProjectOption({
        id: selectedItem,
        label: selectedItem,
        value: selectedItem,
      });
    } else {
      setDefaultProjectOption(undefined);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (
      !authUserRole ||
      authUserRole === AUTH_ROLES.GUEST_TRACKING_HOURS ||
      submitted
    ) {
      return;
    }
    if (defaultDatesRange && dates.length === 0) {
      toast.warning(t('datesRequired'));
    }
    setSubmitted(true);
    const payload = {
      ...getThPayload(
        form.getFieldsValue(true),
        dates,
        defaultDatesRange,
        defaultEmployeeOption?.id || authUser?.employee.id,
        !!trackedHour
      ),
      isRemote,
      hourType: trackedHour?.id
        ? (trackedHour?.hourType as TrackedHourType)
        : hourType,
    };
    if (!trackedHour) {
      createTrackingHour(payload as TrackedHourCreateDTO)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Time entry has been created!'));
            onSuccess();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
          setSubmitted(false);
        })
        .finally(() => {
          setLoading(false);
          setDefaultEmployeeOption(undefined);
        });
    } else {
      if (
        isFormEdited({
          formValues: payload,
          valuesToCheck: trackedHour,
          entity: 'trackingHours',
        })
      ) {
        onClose();
        setLoading(false);
        setDefaultEmployeeOption(undefined);
        return;
      }
      updateTrackingHours(trackedHour.id, payload as TrackedHourUpdateDTO)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Time entry has been updated!'));
            onSuccess();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
          setSubmitted(false);
        })
        .finally(() => {
          setLoading(false);
          setDefaultEmployeeOption(undefined);
        });
    }
  }, [
    submitted,
    hourType,
    isRemote,
    trackedHour,
    defaultEmployeeOption,
    dates,
    defaultProjectOption,
    defaultDatesRange,
  ]);

  const onModalClose = () => {
    dispatch(clearPrjoectEmployeeAssignments());
    setDefaultEmployeeOption(undefined);
    searchParams.delete('edit-hour');
    setSearchParams(searchParams);
    onClose();
  };

  const calculateDifference = (
    anything: TrackingHoursCalculate | undefined
  ) => {
    if (!anything) return;
    if (anything.start && anything.end) {
      const differenceInMs = Math.abs(anything.end.diff(anything.start));
      let breakLength = 0;

      if (anything.breakStart && anything.breakEnd) {
        breakLength = Math.abs(anything.breakEnd.diff(anything.breakStart));
      }

      const differenceInMinutes = Math.round(
        (differenceInMs - breakLength) / (1000 * 60)
      );
      const loggedString = `${Math.floor(differenceInMinutes / 60)}h:${`0${
        differenceInMinutes % 60
      }`.slice(-2)}m`;
      setLoggedTime(loggedString);
      return;
    }

    setLoggedTime('0h:00m');
  };

  const handleTimeChange = () => {
    setLoggedHour({
      start: form.getFieldValue('startTime'),
      end: form.getFieldValue('endTime'),
      breakStart: form.getFieldValue('breakStartTime'),
      breakEnd: form.getFieldValue('breakEndTime'),
    });
  };

  const disabledTimeChange = () => {
    if (form?.getFieldValue('startTime')) {
      const starter: Moment = form?.getFieldValue('startTime');
      const ender: Moment = form.getFieldValue('endTime');
      const disTime: DisabledHours = {
        disabledHours: getDisabledHours(starter?.hour?.()),
        disabledMinutes: getDisabledMinutes(
          starter?.hour?.(),
          ender?.hour?.() ?? 0,
          starter?.minute?.()
        ),
      };
      if (ender?.hour?.() && ender.hour() < starter.hour()) {
        form?.setFields([
          {
            name: 'endTime',
            value: null,
          },
        ]);
      }
      setDisabledTime(disTime);
      return disTime;
    }
    setDisabledTime({
      disabledHours: [],
      disabledMinutes: [],
    });
    form?.setFields([
      {
        name: 'endTime',
        value: null,
      },
    ]);
    return [];
  };

  const onHourTypeSelect = useCallback(
    (hourType: string) => {
      setHourType(hourType as TrackedHourType);
    },
    [hourType]
  );

  const onJobLocationSelect = useCallback(
    (jobLocation: string) => {
      setIsRemote(jobLocation === TrackedHourJobLocation.REMOTE);
    },
    [isRemote]
  );

  const component = useMemo(
    () => (
      <MultiRangeDatePicker
        defaultDatesRange={defaultDatesRange}
        selectedDates={dates}
        onSelectedDatesChange={setDates}
        workWeekType={authUser?.employee?.tenantLocation?.weekWork}
      />
    ),
    [defaultDatesRange, dates, form]
  );

  const TrackinHourFormConfiguration: FormConfigurationType[][] = useMemo(
    () =>
      getThFormConfigurations({
        form,
        onEmployeeSelect,
        trackedHour,
        tenantConfig,
        loggedInUserRole: authUserRole as AUTH_ROLES | undefined,
        assignments,
        employeeProject: employeeProject?.data,
        onHourTypeSelect,
        onJobLocationSelect,
        defaultEmployeeOption,
        defaultProjectOption,
        authUser,
        onProjectSelect,
        getEmployeeSkimSelectWithLoadOptions,
        handleTimeChange,
        defaultBreak,
        setDefaultBreak,
        onDisable: disabledTime,
        disabledEnd: disabledTime.disabledHours.length < 1,
        disableTimeChange: disabledTimeChange,
        defaultDatesRange,
        setDefaultDatesRange,
        component,
      }),
    [
      form,
      trackedHour,
      authUser,
      tenantConfig,
      authUserRole,
      assignments,
      defaultEmployeeOption,
      defaultProjectOption,
      employeeProject?.data,
      defaultBreak,
      setDefaultBreak,
      disabledTime,
      defaultDatesRange,
      setDefaultDatesRange,
      dates,
      component,
    ]
  );

  useEffect(() => {
    calculateDifference(loggedHour);
  }, [loggedHour]);

  return (
    <GenericModal
      title={`${trackedHour ? t('edit') : t('registerhour')} ${t('loghour')}`}
      open={open}
      closeModal={onModalClose}
      icon={<AssignTaskIcon />}
    >
      <GenericForm
        formConfiguration={TrackinHourFormConfiguration}
        form={form}
        onFinish={onSubmit}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 0, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledBoldText>{`You will log: ${loggedTime}`}</StyledBoldText>
            </Col>
          </Row>
        </FormItemStyled>
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={onModalClose} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
