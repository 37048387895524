/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Avatar, Col, Row, Select } from 'antd';
import { Item } from 'ui-v2/components/PersonalInfo';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import Content from 'ui-v2/components/Content';
import CardItem from 'ui-v2/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { convertUTCtoLocalTime, getUserDateFormat } from 'utils/utilFunctions';
import { useSearchParams } from 'react-router-dom';
import UserForm from 'components/NewForms/UserForm';
import { getAuthUser } from 'redux/authUser/actions';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined } from '@ant-design/icons';
import {
  USER_DATE_FORMAT,
  USER_LANGUAGE,
  dateFormatOptions,
} from 'utils/constants';
import { updateUserLang } from 'api/userProfileService';
import { UserUpdateType } from 'types/User';
import { toast } from 'react-toastify';
import { getAllTenantLocations } from 'api/tenantLocationService';
import { LanguageIcon } from '../../../Icons/LanguageIcon';
import CustomSelect from '../../../ui-v2/components/CustomSelect/CustomSelect';

const getTranslatedLanguange = (t: any, lang: string) => {
  if (lang === 'al') {
    return t('Albanian');
  }
  if (lang === 'en-GB' || lang === 'en') {
    return t('English');
  }
  if (lang === 'it') {
    return t('Italian');
  }
  return t('Language');
};

function UserProfile() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, isVisible] = useState(false);
  const authUserData: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const role = authUserData?.authUserRole;
  const authUser = authUserData?.authUser;
  const userDateFormat = getUserDateFormat();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<any>([]);
  const fetchLocations = () => {
    setLoading(true);
    getAllTenantLocations()
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data || []);
        }
      })
      .catch(() => {
        toast.error(
          t("Something went wrong! Can't get locations for this tenant")
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const toogleModalVisibility = () => isVisible((visible) => !visible);

  useEffect(() => {
    if (searchParams.get('edit-my-profile') === 'true') {
      toogleModalVisibility();
      searchParams.delete('edit-my-profile');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getAuthUser());
  }, []);

  const changeLanguage = async (value: any) => {
    if (!authUser?.id) return;

    setLoading(true);

    try {
      const response = await updateUserLang(authUser?.id, {
        lang: value,
      } as UserUpdateType);
      if (response.status === 200) {
        i18n.changeLanguage(value);
        localStorage.setItem(USER_LANGUAGE, value);
        window.location.reload();
      }
      setLoading(false);
    } catch {
      toast.error('Failed to update user language!');
      setLoading(false);
    }
  };

  const changeDateFormat = (selectedDateFormat: string) => {
    localStorage.setItem(USER_DATE_FORMAT, selectedDateFormat);
    window.location.reload();
  };

  const defaultLanguage = getTranslatedLanguange(t, authUser?.lang || '');

  return (
    <>
      <Content position="center">
        <CardItem title={`${t('Personal Info')}`}>
          <Row gutter={[0, 16]}>
            <Styled.RowItem gutter={[0, 10]}>
              <Col span={24}>
                <Row align="middle" gutter={[20, 10]} wrap>
                  <Col>
                    <Avatar
                      size={64}
                      src={authUser?.avatar}
                      icon={authUser?.firstName.charAt(0).toUpperCase()}
                      style={{
                        ...{
                          ...(!authUser?.avatar && { background: '#0074c1' }),
                        },
                      }}
                    />
                  </Col>
                  <Col>
                    <Row gutter={[10, 0]}>
                      <Col>
                        <Styled.ItemValue>
                          {authUser?.firstName}
                        </Styled.ItemValue>
                      </Col>
                      <Col>
                        <Styled.ItemValue>
                          {authUser?.lastName}
                        </Styled.ItemValue>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Styled.RowItem>
            <Styled.RowItem>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('firstName')}
                  value={authUser?.firstName}
                  children={undefined}
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('lastName')}
                  value={authUser?.lastName}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item label={t('role')} value={role} children={undefined} />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('email')}
                  value={authUser?.email}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('birthdate')}
                  value={convertUTCtoLocalTime(authUser?.birthDate)?.format(
                    userDateFormat
                  )}
                  children={undefined}
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('phoneNumber')}
                  value={`${authUser?.phoneNumberPrefix}${authUser?.phoneNumber}`}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <CustomSelect
                defaultValue={defaultLanguage}
                prefixIcon={<LanguageIcon />}
                onChange={changeLanguage}
                style={{
                  border: '2px solid black',
                  borderRadius: '20px',
                }}
                loading={loading}
              >
                <Select.Option value="en">{t('English')}</Select.Option>
                <Select.Option value="it">{t('Italian')}</Select.Option>
                <Select.Option value="al">{t('Albanian')}</Select.Option>
              </CustomSelect>
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <CustomSelect
                defaultValue={
                  dateFormatOptions.find(
                    (option) => option.value === userDateFormat
                  )?.label
                }
                prefixIcon={<CalendarOutlined />}
                onSelect={changeDateFormat}
                style={{
                  border: '2px solid black',
                  borderRadius: '20px',
                }}
              >
                {dateFormatOptions.map((option) => (
                  <Select.Option value={option.value} key={option.id}>
                    {t(option.label)}
                  </Select.Option>
                ))}
              </CustomSelect>
            </Col>
          </Row>
        </CardItem>
      </Content>
      {visible && (
        <UserForm
          open
          closeModal={toogleModalVisibility}
          authUserData={authUserData}
          locationData={locations}
        />
      )}
    </>
  );
}
export default UserProfile;
