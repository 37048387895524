import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import GenericModal from 'ui-v2/components/GenericModal';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import { toast } from 'react-toastify';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { useTranslation } from 'react-i18next';
import { convertUTCtoLocalTime, getUserDateFormat } from 'utils/utilFunctions';
import { RootState } from 'redux/store';
import { InterviewsState } from 'redux/candidates/types';
import { fetchCandidateOpenPositionInterviews } from 'redux/candidates/actions';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { convertBase64, submitTask, taskSelectOptions } from './utils';

interface IProps {
  closeModal: () => void;
}

const Preview = () => <FileOutlinedIcon />;

export default function CandidateTaskModal({ closeModal }: IProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const { selectedCandidateOpenPosition } = useCandidateData();
  const {
    data: { interviews },
  } = useSelector((state: RootState) => state.interviews as InterviewsState);

  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const task = interviews
    ?.map((interview) => interview?.tasks)
    ?.flat()
    ?.find((task) => task?.id === searchParams?.get('taskId'));

  const onSubmit = async () => {
    if (loading || !interviews.length || !interviews[0]?.id) return;
    setLoading(true);

    const formValues = form.getFieldsValue(true);

    const values: any = {
      name: formValues.name,
      description: formValues.description,
      progress: formValues.progress,
      interviewId: interviews[0]?.id,
      dueDate: formValues.dueDate,
      evaluation: formValues.evaluation,
    };

    if (formValues.file) {
      values.fileTaskName = { ...fileToUpload };
    }

    const result: any = await submitTask(values, task);

    setLoading(false);

    if (result === true) {
      closeModal();
      toast.success(task?.id ? 'Task Updated!' : 'Task Created!');
    }
    if (result?.error) {
      toast.error(result?.error);
    }

    if (selectedCandidateOpenPosition.data?.id) {
      dispatch(
        fetchCandidateOpenPositionInterviews(
          selectedCandidateOpenPosition.data.id
        )
      );
    }
  };

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64.split(',').pop() });
    return false;
  }

  const AssignTaskFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: InputTypes.INPUT,
          defaultValue: task?.name,
          rules: [
            {
              required: true,
              message: t('requestedTaskName'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: InputTypes.TEXTAREA,
          defaultValue: task?.description,
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'progress',
          label: 'Progress',
          type: InputTypes.SELECT,
          rules: [
            {
              required: true,
              message: t('requiredProgress'),
            },
          ],
          selectOptions: taskSelectOptions,
          defaultValue: task?.progress,
        },
        {
          col: 11,
          offset: 2,
          name: 'dueDate',
          label: t('dueDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(task?.dueDate),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current) =>
              current && current < moment().endOf('day'),
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'evaluation',
          label: t('enterEvaluation'),
          type: InputTypes.INPUT,
          defaultValue: task?.evaluation,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'file',
          label: t('task'),
          type: InputTypes.UPLOAD,
          rules: [
            {
              required: true,
              message: t('requiredFile'),
            },
          ],
          uploadProps: {
            accept: '.pdf,.doc,.docx,.zip,.gz,.rar',
            beforeUpload: (file) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            iconRender: Preview,
            initialValue: task?.taskDocuments?.length
              ? task.taskDocuments.map((doc) => ({
                  uid: doc?.id,
                  name: doc?.name,
                }))
              : [],
            defaultFileList: task?.taskDocuments?.length
              ? task.taskDocuments.map((doc) => ({
                  uid: doc?.id,
                  name: doc?.name,
                }))
              : [],
          },
        },
      ],
    ],
    [task, fileToUpload]
  );

  return (
    <GenericModal
      title={task?.id ? t('editTask') : t('assignTask')}
      open
      closeModal={closeModal}
      icon={<AssignTaskIcon />}
    >
      <GenericForm
        formConfiguration={AssignTaskFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
