import { Col, Form, Row } from 'antd';
import { RcFile } from 'antd/es/upload';
import { createEmployeeDocument } from 'api/employeeService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import { UploadMOdalIcon } from 'Icons/UploadModalIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeeDocuments } from 'redux/employees/actions';
import { IEmployeeDocumentDTO, IFileDocuments } from 'types/Employee';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  convertBase64,
  getValidFileContent,
  toastErrorMessages,
} from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId: string;
}

export default function AddDocumentModal({
  open,
  closeModal,
  employeeId,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [file, setFile] = useState<IFileDocuments>({
    content: '',
    fileName: '',
  });

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFile({ fileName: file.name, content: validBase64 });
    return false;
  }

  const onSubmit = () => {
    if (loading || !employeeId) return;

    setLoading(true);
    const formValues: IEmployeeDocumentDTO = form.getFieldsValue(true);

    createEmployeeDocument({ ...formValues, name: file, employeeId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch(fetchEmployeeDocuments(employeeId));
          toast.success(t('successfullyAddedDocument'));
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        closeModal();
        setLoading(false);
      });
  };

  const AddDocFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'type',
          label: t('documentType'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('Training is required'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: 'textarea',
          rules: [
            {
              required: true,
              message: t('requiredDescription'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          type: 'upload',
          label: `${t(
            'uploadDocument'
          )} ( .pdf, .doc, .docx, .rar, .zip, .gz )`,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          uploadProps: {
            accept: '.pdf,.doc,.docx,.zip,.gz,.rar',
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            // eslint-disable-next-line react/no-unstable-nested-components
            iconRender: () => <FileOutlinedIcon />,
          },
        },
      ],
    ],
    []
  );

  return (
    <GenericModal
      title={t('uploadDocuments')}
      open={open}
      closeModal={closeModal}
      icon={<UploadMOdalIcon />}
    >
      <GenericForm
        formConfiguration={AddDocFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
