import { FormInstance } from 'antd';
import moment from 'moment';
import { AssigmentType } from 'types/Assigment';
import { AUTH_ROLES } from 'types/Auth';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { ProjectType } from 'types/Project';
import { TenantConfigDTO } from 'types/TenantConfig';
import {
  convertUTCtoLocalTime,
  getTime,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { getUTCConvertedDateTime } from 'ui-v2/routes/tracking/utils';
import { UserDataType } from 'redux/authUser/types';
import {
  TrackedHourCreateDTO,
  TrackedHourDTO,
  TrackedHourJobLocation,
  TrackedHourType,
  TrackedHourUpdateDTO,
} from 'types/tracking';
import { isNil } from 'lodash';
import i18next from '../../../i18n';
import { SelectLoadPayload } from '../SelectWithLoad/SelectWithLoad';

export const getThProjectFormItemLabel = (
  userRole: string | undefined,
  hasAssignments: boolean,
  hasOwnProjects: boolean,
  name: string | undefined,
  authUserFullName?: string
): string => {
  const fallbackMsg = i18next.t('therearenoprojectsassigned');
  let label = 'Project List';
  switch (userRole) {
    case AUTH_ROLES.EMPLOYEE:
    case AUTH_ROLES.PAYROLL_MANAGER:
      if (!hasOwnProjects) {
        if (authUserFullName && !name) {
          label = `${fallbackMsg} to you ${authUserFullName}`;
        } else {
          label = fallbackMsg;
        }
      }
      break;
    case AUTH_ROLES.AMDIN:
      if (!hasAssignments) {
        label = name ? `${fallbackMsg} to ${name}` : fallbackMsg;
      }
      break;
    case AUTH_ROLES.HR:
      if (!hasAssignments) {
        label = name ? `${fallbackMsg} to ${name}` : fallbackMsg;
      }
      break;
    case AUTH_ROLES.GUEST_TRACKING_HOURS:
      if (!hasAssignments) {
        label = fallbackMsg;
      }
      break;
    default:
      break;
  }
  return label;
};

export const getThProjectFormItemDefaultSelectOption = (
  trackingHour?: TrackedHourDTO
): OptionType[] => {
  if (trackingHour?.assignment) {
    return [
      {
        id: trackingHour.assignment.id,
        value: trackingHour.assignment.id,
        label: trackingHour.assignment.project.name,
      },
    ];
  }
  return [];
};

export const getThProjectFormItemSelectOptions = (
  employeeProjects: ProjectType[],
  assignments?: AssigmentType[]
): OptionType[] => {
  if (employeeProjects.length) {
    return [
      ...(employeeProjects?.map((p: ProjectType) => [
        ...(p?.assignments?.map((a: AssigmentType) => ({
          id: a?.id,
          label: p?.name,
          value: a?.id,
        })) ?? []),
      ]) ?? []),
    ].flat();
  }
  const assignmentProjectOptions = assignments
    ?.filter((el) => !isNil(el?.project))
    .map((item) => ({
      id: item?.id,
      value: item?.id,
      label: item?.project?.name,
    }));

  return assignmentProjectOptions || [];
};

export const defaultBreakTimes = {
  breakStartTime: moment('13:00', 'HH:mm').toDate(),
  breakEndTime: moment('14:00', 'HH:mm').toDate(),
};

export const getThFormConfigurations = ({
  form,
  onEmployeeSelect,
  trackedHour,
  tenantConfig,
  loggedInUserRole,
  assignments,
  employeeProject,
  onHourTypeSelect,
  onJobLocationSelect,
  defaultEmployeeOption,
  defaultProjectOption,
  authUser,
  onProjectSelect,
  getEmployeeSkimSelectWithLoadOptions,
  handleTimeChange,
  defaultBreak,
  defaultDatesRange,
  setDefaultBreak,
  onDisable,
  disableTimeChange,
  setDefaultDatesRange,
  component,
  disabledEnd,
}: {
  form: FormInstance;
  onEmployeeSelect: (value: any) => void;
  trackedHour?: TrackedHourDTO;
  tenantConfig?: TenantConfigDTO | null;
  loggedInUserRole?: string;
  assignments?: AssigmentType[];
  employeeProject?: ProjectType[];
  onHourTypeSelect?: (id: string) => void;
  onJobLocationSelect?: (id: string) => void;
  defaultEmployeeOption?: OptionType;
  defaultHoursValue?: OptionType;
  defaultProjectOption?: OptionType;
  authUser?: UserDataType | null;
  onProjectSelect?: (value: any) => void;
  handleTimeChange: any;
  getEmployeeSkimSelectWithLoadOptions: (
    str: string
  ) => Promise<SelectLoadPayload>;
  defaultBreak: boolean;
  setDefaultBreak: (defaultBreak: boolean) => void;
  onDisable: any;
  disableTimeChange: any;
  defaultDatesRange: boolean;
  setDefaultDatesRange: (defaultDatesRange: boolean) => void;
  component: any;
  disabledEnd: boolean;
}) => {
  const projetListLabel = getThProjectFormItemLabel(
    loggedInUserRole,
    !!assignments?.length,
    !!employeeProject?.length,
    form.getFieldsValue(true)?.employeeId?.label,
    `${authUser?.firstName} ${authUser?.lastName}`
  );

  const projectSelectOptions = getThProjectFormItemSelectOptions(
    employeeProject ?? [],
    assignments
  );

  let defaultEmployeeValue: OptionType[] = [];
  if (!defaultEmployeeOption && trackedHour?.id) {
    defaultEmployeeValue = [
      {
        id: trackedHour?.employee?.id,
        value: trackedHour?.employee?.id,
        label: `${trackedHour?.employee?.firstName} ${trackedHour?.employee?.lastName}`,
      },
    ];
  } else if (defaultEmployeeOption) {
    defaultEmployeeValue = [defaultEmployeeOption];
  }

  const userDateFormat = getUserDateFormat();

  const startTimeDefaultValue = trackedHour?.startTime
    ? trackedHour?.startTime
    : tenantConfig?.workingHourStart ?? form.getFieldValue('startTime');

  const endTimeDefaultValue = trackedHour?.startTime
    ? trackedHour?.endTime
    : tenantConfig?.workingHourEnd ?? form.getFieldValue('endTime');

  const dateDefaultValue = form.getFieldValue('date');

  const onCheck = (checked: boolean) => {
    setDefaultBreak(checked);
  };

  const formConfiguration: FormConfigurationType[][] = [
    !trackedHour && loggedInUserRole
      ? [
          {
            col: 11,
            offset: 0,
            name: 'hourType',
            label: i18next.t('hourType'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourType)?.map(
              (hourType) => ({
                id: hourType,
                value: hourType,
                label: hourType,
              })
            ),
            checkedOptions: [
              {
                id: TrackedHourType.REGULAR_HOURS,
                label: TrackedHourType.REGULAR_HOURS,
                value: TrackedHourType.REGULAR_HOURS,
              },
            ],

            onSelect: onHourTypeSelect,
          },
          {
            col: 11,
            offset: 0,
            name: 'isRemote',
            label: i18next.t('jobLocation'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourJobLocation)?.map(
              (jobLocation) => ({
                id: jobLocation,
                value: jobLocation,
                label: jobLocation,
              })
            ),
            onSelect: onJobLocationSelect,
          },
        ]
      : [
          {
            col: 11,
            offset: 0,
            name: 'isRemote',
            label: i18next.t('jobLocation'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourJobLocation)?.map(
              (jobLocation) => ({
                id: jobLocation,
                value: jobLocation,
                label: jobLocation,
              })
            ),
            checkedOptions: [
              trackedHour?.isRemote
                ? {
                    id: TrackedHourJobLocation.REMOTE,
                    value: TrackedHourJobLocation.REMOTE,
                    label: TrackedHourJobLocation.REMOTE,
                  }
                : {
                    id: TrackedHourJobLocation.OFFICE,
                    value: TrackedHourJobLocation.OFFICE,
                    label: TrackedHourJobLocation.OFFICE,
                  },
            ],
            onSelect: onJobLocationSelect,
          },
        ],
    loggedInUserRole === AUTH_ROLES.AMDIN || loggedInUserRole === AUTH_ROLES.HR
      ? [
          {
            col: 24,
            offset: 0,
            name: 'employeeId',
            label: i18next.t('employeeList'),
            type: InputTypes.SELECT_LOAD,
            fetchOptions: getEmployeeSkimSelectWithLoadOptions,
            onSelect: onEmployeeSelect,
            defaultValue: defaultEmployeeValue,
            rules: [
              {
                required: true,
                message: i18next.t('Employee is required'),
              },
            ],
          },
        ]
      : [],
    [
      {
        col: 24,
        offset: 0,
        name: 'projectId',
        label: projetListLabel,
        type: InputTypes.SELECT,
        disabled: !projectSelectOptions?.length,
        selectOptions: projectSelectOptions,
        defaultValue: defaultProjectOption?.id,
        onSelect: onProjectSelect,
      },
      {
        col: 11,
        offset: 0,
        name: 'startTime',
        label: i18next.t('startTime'),
        type: InputTypes.TIMEPICKER,
        onChange: () => {
          handleTimeChange();
          disableTimeChange();
        },
        defaultValue: getTime(startTimeDefaultValue),
        rules: [
          {
            required: true,
            message: i18next.t('Start time is required!'),
          },
        ],
      },
      {
        col: 11,
        offset: 2,
        name: 'endTime',
        label: i18next.t('endTime'),
        type: InputTypes.TIMEPICKER,
        defaultValue: getTime(endTimeDefaultValue),
        onChange: () => {
          handleTimeChange();
          disableTimeChange();
        },
        disabledTime: () => ({
          disabledHours: () => [...onDisable.disabledHours],
          disabledMinutes: () => [...onDisable.disabledMinutes],
        }),
        disabled: disabledEnd,
        rules: [
          {
            required: true,
            message: i18next.t('End time is required!'),
          },
        ],
      },
    ],
    [
      {
        col: 8,
        offset: 0,
        name: 'dateRange',
        label: i18next.t('dateRange'),
        type: InputTypes.SWITCH,
        defaultChecked: defaultDatesRange,
        onChecked: setDefaultDatesRange,
        disabled: !!trackedHour,
      },
      defaultDatesRange
        ? {
            col: 13,
            offset: 0,
            name: '',
            type: InputTypes.RICH_TEXT,
            component,
            label: '',
          }
        : {
            col: 13,
            offset: 3,
            name: 'date',
            label: i18next.t('date'),
            type: InputTypes.DATEPICKER,
            defaultValue:
              convertUTCtoLocalTime(trackedHour?.date) ||
              convertUTCtoLocalTime(dateDefaultValue) ||
              convertUTCtoLocalTime(new Date()),
            dateProps: {
              format: userDateFormat,
              showTime: false,
            },
            rules: [
              {
                required: true,
                message: i18next.t('Date is required!'),
              },
            ],
          },
    ],
    [
      {
        col: 8,
        offset: 0,
        name: 'break',
        label: i18next.t('defaultBreak'),
        type: InputTypes.SWITCH,
        defaultChecked: defaultBreak,
        onChecked: onCheck,
      },
      {
        col: 7,
        offset: 0,
        name: 'breakStartTime',
        label: i18next.t('breakStartTime'),
        type: InputTypes.TIMEPICKER,
        onChange: handleTimeChange,
        rules: [
          {
            required: !!defaultBreak,
            message: i18next.t('requiredBreakStartTime'),
          },
        ],
      },
      {
        col: 7,
        offset: 2,
        name: 'breakEndTime',
        label: i18next.t('breakEndTime'),
        type: InputTypes.TIMEPICKER,
        onChange: handleTimeChange,
        rules: [
          {
            required: !!defaultBreak,
            message: i18next.t('requiredBreakEndTime'),
          },
        ],
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'description',
        label: i18next.t('description'),
        type: InputTypes.TEXTAREA,
        defaultValue: trackedHour?.description,
        rules: [
          {
            required: true,
            message: i18next.t('requiredDescription'),
          },
        ],
      },
    ],
  ];
  return formConfiguration;
};
export const getThPayload = (
  formValues: any,
  selectedMultipleDates: string[] | string,
  dateRange: boolean,
  employeeId?: string,
  isEditing = false
): TrackedHourCreateDTO | TrackedHourUpdateDTO => {
  let startTime: any = moment(formValues?.startTime);
  let endTime: any = moment(formValues?.endTime);
  let breakStartTime: any = formValues?.breakStartTime
    ? moment(formValues?.breakStartTime)
    : null;
  let breakEndTime: any = formValues?.breakEndTime
    ? moment(formValues?.breakEndTime)
    : null;

  if (endTime.isSameOrBefore(startTime)) {
    endTime = getUTCConvertedDateTime({
      date: moment(formValues?.date).add(1, 'day'),
      time: endTime,
    });
  } else {
    endTime = getUTCConvertedDateTime({
      date: formValues?.date,
      time: endTime,
    });
  }

  startTime = getUTCConvertedDateTime({
    date: formValues?.date,
    time: startTime,
  });

  if (breakStartTime) {
    breakStartTime = getUTCConvertedDateTime({
      date: formValues?.date,
      time: breakStartTime,
    });
  }

  if (breakEndTime) {
    breakEndTime = getUTCConvertedDateTime({
      date: formValues?.date,
      time: breakEndTime,
    });
  }

  const projectIdValue =
    typeof formValues?.projectId === 'string'
      ? formValues?.projectId
      : formValues?.projectId?.value;

  const basePayload = {
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    hourType: TrackedHourType.REGULAR_HOURS,
    description: formValues?.description,
    employeeId: employeeId || formValues?.employeeId?.value,
    assignmentId: projectIdValue,
  };

  if (isEditing) {
    return {
      ...basePayload,
      date: getUTCConvertedDateTime({
        date: formValues?.date,
        time: formValues?.startTime,
      }),
    } as TrackedHourUpdateDTO;
  }

  return {
    ...basePayload,
    dates:
      dateRange && selectedMultipleDates?.length > 0
        ? selectedMultipleDates
        : [
            getUTCConvertedDateTime({
              date: formValues?.date,
              time: formValues?.startTime,
            }),
          ],
  } as TrackedHourCreateDTO;
};
