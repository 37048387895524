import FeatureFlagActions from 'components/Actions/FeatureFlagActions';
import { FeatureFlagsTenantType } from 'types/FeatureFlagsTenant';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import React from 'react';
import { Row } from 'antd';
import moment from 'moment';
import i18next from '../i18n';

const capital = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export const FeatureColumns = [
  {
    title: 'Tenant Name',
    dataIndex: 'tenantName',
    key: 'tenantName',
    default: true,
    order: 1,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <p>{`${row?.tenant.name}`}</p>
    ),
  },
  {
    title: 'Feature Name',
    dataIndex: 'featureName',
    key: 'featureName',
    default: true,
    order: 1,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <p>{`${capital(row?.featureFlag.featureName)}`}</p>
    ),
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    default: true,
    order: 1,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <p>{`${moment(row?.updatedAt).format('YYYY-MM-DD | HH:mm A')}`}</p>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    default: true,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <Row justify="start">
        <Styled.FeatureEnabledTag status={row?.status ? row.status : false}>
          <p>{row?.status === true ? 'Enabled' : 'Disabled'}</p>
        </Styled.FeatureEnabledTag>
      </Row>
    ),
    order: 4,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <FeatureFlagActions row={row} />
    ),
    default: false,
    order: 6,
  },
];
