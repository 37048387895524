import {
  CandidateCreateDTO,
  CandidateResumeType,
  CandidateResumesDTO,
  ICandidateOpenPositionDTO,
  ShortListDto,
  UpdateCandidatesType,
} from 'types/Candidates';
import agent from 'api/agent';
import { CandidatesQueryParamsType, QueryParamsDTO } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllCandidates = (queryParams?: CandidatesQueryParamsType) =>
  agent.post(
    `${apiURLs.candidates}/get`,
    searchFields(queryParams, apiURLs.candidates)
  );

// export const searchAllCandidates = (queryParams?: SearchParamsType) =>
//   agent.post(`${apiURLs.candidates}/get`, queryParams);

export const getCandidate = (id: string) =>
  agent.get(`${apiURLs.candidates}/${id}`);

export const getCandidateLite = (id: string) =>
  agent.get(`${apiURLs.candidates}/${id}`);

export const getCandidateDetails = (id: string) =>
  agent.get(`${apiURLs.candidates}/${id}`);

export const searchCandidates = (searchText: string | null) =>
  agent.get(
    `${
      apiURLs.candidates
    }?name=${searchText}&limit=${1000}&sortBy=createdAt&sortType=DESC`
  );

export const getCandidatesResumeFiles = (payload: CandidateResumesDTO) =>
  agent.post(`${apiURLs.candidates}/resumesPerPage`, payload);

export const candidateFilterByOpenPosition = (openPositionName: string) =>
  agent.get(`${apiURLs.candidates}?openPosition=${openPositionName}`);

export const registerCandidate = (candidateData: CandidateCreateDTO) =>
  agent.post(apiURLs.candidates, candidateData);

export const getOpenPositions = async (getImages = false) =>
  agent.get(`${apiURLs.openPositions}?getImages=${getImages}`);

export const getCandidateOpenPositionStage = () =>
  agent.post(`${apiURLs.stages}/get`);

export const updateCandidateOpenPositionStage = (
  payload: ICandidateOpenPositionDTO
) => agent.post(`${apiURLs.candidateOpenPositions}/stage`, payload);

export const getCandidatesResume = (
  resume: string | undefined
): Promise<CandidateResumeType> =>
  agent.get(`${apiURLs.candidates}/resume?name=${resume}`);

export const updateCandidate = (
  id: string,
  candidateData: UpdateCandidatesType
) => agent.patch(`${apiURLs.candidates}/${id}`, candidateData);

export const removeCandidate = (id: string) =>
  agent.delete(`${apiURLs.candidates}/${id}`);

export const bulkUploadCandidates = (payload: any) =>
  agent.post(`${apiURLs.candidatesBulkUpload}`, payload);

export const getCandidatesByOpenPositionId = async (
  id: string,
  pageOptionsDto?: QueryParamsDTO
) => agent.post(`${apiURLs.candidates}/openPosition/${id}`, pageOptionsDto);

export const addCandidateInShortList = (payload: ShortListDto) =>
  agent.post(apiURLs.confiremdList, payload);

export const getShortList = (id: string, pageOptionsDto?: QueryParamsDTO) =>
  agent.post(`${apiURLs.confiremdList}/openPosition/${id}`, pageOptionsDto);

export const removeFromShortlist = (id: string) =>
  agent.delete(`${apiURLs.confiremdList}/${id}`);

export const addCandidateManualy = (id: string, payload: CandidateCreateDTO) =>
  agent.patch(`${apiURLs.openPositions}/candidates/${id}`, payload);
