import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PersonalInfo from 'ui-v2/components/PersonalInfo';
import Task from 'ui-v2/components/Task';
import Content from 'ui-v2/components/Content';
import WorkExperience from 'ui-v2/components/WorkExperience';
import CandidateDocuments from 'ui-v2/components/CandidateDocuments';
import SkillList from 'ui-v2/components/Skills';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { fetchCandidate } from 'redux/candidates/actions';
import CandidateNotesComponent from 'ui-v2/components/CandidateNotesComponent/CandidateNotes';
import { CandidateNotesType } from 'types/CandidateNotes';
import { getCandidateNotes } from 'api/candidateNotesService';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import InterviewList from './InterviewList';
import OpenPositionsTabs from './OpenPositionsTabs/OpenPositionsTabs';

export default function CandidateProfileUser() {
  const { candidate } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useScreenSize();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { authUserRole } = authUserState;
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<CandidateNotesType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNote = async () => {
      setLoading(true);
      try {
        const response = await getCandidateNotes(candidate?.id);
        setNotes(response.data);
      } catch (error) {
        console.error('Error fetching note:', error);
      } finally {
        setLoading(false);
      }
    };

    if (candidate?.id) {
      fetchNote();
    }
  }, [candidate]);

  useEffect(() => {
    if (
      (!searchParams.get('id') ||
        searchParams.get('id') !== authUserState?.authUser?.candidates?.id) &&
      authUserState?.authUser?.candidates?.id
    ) {
      searchParams.set('id', authUserState?.authUser?.candidates?.id);
      setSearchParams(searchParams);
      dispatch(fetchCandidate(searchParams.get('id') || ''));
    }
    if (searchParams.get('schedule') === 'true') {
      searchParams.delete('schedule');
      setSearchParams(searchParams);
    }
    if (searchParams.get('offer') === 'true') {
      searchParams.delete('offer');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const isCandidateHired = false;
  return (
    <Content position="stretch">
      <>
        <OpenPositionsTabs />
        <Content position={isMobile ? 'flexcol' : 'grid'}>
          <>
            <>
              <PersonalInfo isDisabled={isCandidateHired} />
              <WorkExperience isDisabled={isCandidateHired} />
              <SkillList
                isEmployeePortal={false}
                isDisabled={isCandidateHired}
              />
            </>
            <>
              <InterviewList
                isDisabled={isCandidateHired}
                authUserRole={authUserRole ?? undefined}
              />
              <Task authUserRole={authUserRole} isDisabled={isCandidateHired} />
              <CandidateDocuments
                userType={authUserRole}
                isDisabled={isCandidateHired}
              />
              <CandidateNotesComponent
                notes={notes ?? []}
                candidateId={candidate?.id}
                loading={loading}
              />
            </>
          </>
        </Content>
      </>
    </Content>
  );
}
