import React, { useEffect } from 'react';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import { CandidateSkillsetColumns } from 'table-configuration/SkillsetMatchingConfig';
import { fetchSkillsetMatchingByOpenPositionId } from 'redux/skillsetMatching/action';
import { useSkillsetMatchingData } from 'ui-v2/hooks/useSkillsetMatchingData';
import { t } from 'i18next';
import { Title } from 'ui-v2/components/Card/CardStyles';

export default function CandidatesSkillSetMatching() {
  const { candidatesList, candidatesPagination, candidatesLoading } =
    useSkillsetMatchingData();
  const [searchParams] = useSearchParams();
  const { take } = useHeader();
  const dispatch = useDispatch();

  useEffect(() => {
    const id: string = searchParams.get('id') as string;
    const yearsOfExperienceFilter = !!searchParams.get(
      'yearsOfExperienceFilter'
    );
    const params = {
      pageOptions: {
        page: 1,
        take,
        yearsOfExperienceFilter,
      },
    };
    dispatch(fetchSkillsetMatchingByOpenPositionId(id, params));
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const id: string = searchParams.get('id') as string;
    const params = {
      pageOptions: {
        page,
        take,
      },
    };
    dispatch(fetchSkillsetMatchingByOpenPositionId(id, params));
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%', paddingTop: '40px' }}>
        <Content position="stretch">
          <>
            <Title>{t('listOfApplicants')}</Title>
            <DataGrid
              loading={candidatesLoading}
              columns={CandidateSkillsetColumns}
              data={candidatesList}
              paginationName="CandidatesSkillset"
              rowSelectionType="checkbox"
              totalItems={candidatesPagination.itemCount}
              currentPage={candidatesPagination.page}
              totalPages={candidatesPagination.pageCount}
              onPageChange={onPageChange}
              multipleSelect={false}
            />
          </>
        </Content>
      </div>
    </>
  );
}
