import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericModal from 'ui-v2/components/GenericModal';
import { convertBase64, toastErrorMessages } from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';
import { InputTypes } from 'types/FormTypes';
import useHeader from 'ui-v2/hooks/useHeader';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import { CreateExpensesReport } from 'types/ExpensesReport';
import {
  fetchEmployeeOptions,
  fetchEmployeeProjectAssignments,
  fetchEmployeeProjects,
} from 'components/NewForms/SelectWithLoad/utils';
import useExpenseReport from 'ui-v2/hooks/useExpenseReportData';
import { getExpenseDocument, updateExpenseReport } from 'api/expensesService';
import { fetchExpenses, fetchSingleExpenseReport } from 'redux/expenses/action';
import { RcFile } from 'antd/lib/upload';
import { BenefitPlanText } from 'pages/Benefits/BenefitList/BenefitListStyles';
import { RootState } from 'redux/store';
import { OptionType } from 'types/OptionTypes';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import ExpenseLineForm from './ExpenseReportLine';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export default function ExpenseReportModalForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [, setDefDocument] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { singleExpenseReport } = useExpenseReport();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { take } = useHeader();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [employee, setEmployee] = useState<OptionType>();
  const [projects, setProjects] = useState<OptionType>();
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});

  const canChangeEmployee = !!(
    authUserRole === AUTH_ROLES.AMDIN ||
    authUserRole === AUTH_ROLES.HR ||
    authUserRole === AUTH_ROLES.PAYROLL_MANAGER
  );

  useEffect(() => {
    const fetchExpenseData = async () => {
      const expenseId = searchParams.get('edit-expense');
      if (expenseId) {
        dispatch(fetchSingleExpenseReport(expenseId as string));
        const def = await getExpenseDocument(expenseId as string);
        setDefDocument(def.data.docUrl);
      }
    };

    fetchExpenseData();
  }, [searchParams]);

  function onFormFinish(values: any) {
    const expenseLinesData = values.expenseLines?.map((line: any) => {
      const updatedLine = {
        ...line,
        amount: parseFloat(line.amount),
        expenseDate: line.date,
        currency: line.currencyId,
      };
      delete updatedLine.date;
      return updatedLine;
    });

    const valuesToSend: CreateExpensesReport = {
      employeeId: canChangeEmployee
        ? values?.employee.value || values?.employee
        : singleExpenseReport?.employee.id,
      projectId: values?.projects?.id || values?.projects,

      document: {
        fileName: (fileToUpload?.name as string) || values?.name,
        content:
          (fileToUpload.content?.split(',').pop() as string) || values?.name,
      },
      expenseLines: expenseLinesData,
    };
    setLoading(true);
    if (singleExpenseReport?.id) {
      updateExpenseReport(singleExpenseReport?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('expenseReportUpdatedSuccessfully'));
            const expenseId = searchParams.get('id');
            if (expenseId) {
              dispatch(fetchSingleExpenseReport(expenseId as string));
              closeModal();
            }
            dispatch(
              fetchExpenses({ pageOptions: { page: currentPage, take } })
            );
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
          closeModal();
        });
    }
  }

  useEffect(() => {
    if (searchParams.get('edit-expense')) {
      searchParams.delete('edit-expense');
      setSearchParams(searchParams);
    }

    setCurrentPage(
      searchParams.get('page')
        ? parseInt(searchParams.get('page') as string, 10)
        : 1
    );
  }, [searchParams, form, singleExpenseReport]);

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64 });
    return false;
  }

  useEffect(() => {
    const fetchProjects = async () => {
      if (employee?.key) {
        const res = await fetchEmployeeProjectAssignments(employee.key);
        setProjects(res.data);
      }
    };

    fetchProjects();
  }, [employee]);

  const ExpenseFormConfiguration: any[][] = useMemo(
    () => [
      canChangeEmployee
        ? [
            {
              col: 24,
              offset: 0,
              name: 'employee',
              label: t('employee'),
              type: InputTypes.SELECT_LOAD,
              fetchOptions: fetchEmployeeOptions,
              onSelect: (value: any) => {
                setEmployee(value);
                form.setFieldsValue({ projects: undefined });
              },
              defaultValue: !employee && {
                id: singleExpenseReport?.employee?.id,
                label: `${singleExpenseReport?.employee?.firstName} ${singleExpenseReport?.employee?.lastName}`,
                value: singleExpenseReport?.employee?.id,
              },
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 24,
              offset: 0,
              name: 'projects',
              label: t('projects'),
              type: InputTypes.SELECT,
              selectOptions: projects,
              onDeselect: () => {
                form.setFieldsValue({ employee: undefined });
              },
              defaultValue: !employee && {
                id: singleExpenseReport?.project?.id,
                label: singleExpenseReport?.project?.name,
                value: singleExpenseReport?.employee?.id,
              },
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
            {
              col: 24,
              offset: 0,
              name: 'name',
              type: 'upload',
              label: `${t(
                'uploadDocument'
              )} ( .pdf, .doc, .docx, .rar, .zip, .gz )`,
              defaultValue: singleExpenseReport?.documentName,
              uploadProps: {
                accept: '.pdf,.doc,.docx,.zip,.gz,.rar',
                beforeUpload: (file: RcFile) => uploadAction(file),
                maxCount: 1,
                listType: 'picture',
                iconRender: () => <FileOutlinedIcon />,
                defaultFileList:
                  singleExpenseReport?.documentName &&
                  ([
                    {
                      uid: '1',
                      name: singleExpenseReport?.documentName,
                    },
                  ] as any),
              },
            },
          ]
        : [
            {
              col: 24,
              offset: 0,
              name: 'projects',
              label: t('projects'),
              type: InputTypes.SELECT_LOAD,
              fetchOptions: fetchEmployeeProjects,
              defaultValue: {
                id: singleExpenseReport?.project?.id,
                label: singleExpenseReport?.project?.name,
                value: singleExpenseReport?.employee?.id,
              },
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
            {
              col: 24,
              offset: 0,
              name: 'name',
              type: 'upload',
              label: `${t(
                'uploadDocument'
              )} ( .pdf, .doc, .docx, .rar, .zip, .7z, .gz )`,
              defaultValue: singleExpenseReport?.documentName,
              uploadProps: {
                accept: '.pdf,.doc,.docx,.zip,.7z,.gz,.rar',
                beforeUpload: (file: RcFile) => uploadAction(file),
                maxCount: 1,
                listType: 'picture',
                iconRender: () => <FileOutlinedIcon />,
                defaultFileList:
                  singleExpenseReport?.documentName &&
                  ([
                    {
                      uid: '1',
                      name: singleExpenseReport?.documentName,
                    },
                  ] as any),
              },
            },
          ],
    ],
    [singleExpenseReport, projects]
  );

  return (
    <GenericModal
      title={t('editExpense')}
      open={open}
      closeModal={closeModal}
      icon={<ScheduleInterviewModalIcon />}
    >
      <GenericForm
        formConfiguration={ExpenseFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <Col span={24}>
          <BenefitPlanText>{t('expenseLines')}</BenefitPlanText>

          {singleExpenseReport?.expenseLines.length > 0 && (
            <ExpenseLineForm initial={singleExpenseReport?.expenseLines} />
          )}
        </Col>
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>

            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
