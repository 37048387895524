import { Reducer } from 'react';
import { Action } from 'redux';
import { FeatureFlagsTenantAction, FeatureFlagsTenantState } from './types';

const initialState: FeatureFlagsTenantState = {
  data: [],
  list: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,

  updating: false,
  updated: false,
  updateError: null,
  updateErrored: false,
};

const reducer: Reducer<FeatureFlagsTenantState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_START:
      state = { ...state, loading: true };
      break;
    case FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_SUCCESS:
      state = { ...state, loading: false, loaded: true, list: action.payload };
      break;
    case FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_START:
      state = { ...state, loading: true };
      break;
    case FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_SUCCESS:
      state = { ...state, loading: false, loaded: true, list: action.payload };
      break;
    case FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_START:
      state = { ...state, loading: true };
      break;
    case FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_START:
      state = { ...state, updating: true };
      break;
    case FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_SUCCESS:
      state = {
        ...state,
        updating: false,
        updated: true,
        data: action.payload,
      };
      break;
    case FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_ERRORED:
      state = {
        ...state,
        updating: false,
        updated: false,
        updateErrored: true,
        updateError: action.payload,
      };
      break;
    case FeatureFlagsTenantAction.CLEAR_FEATURE_FLAGS_TENANT:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as FeatureFlagsTenantReducer };
