import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllFeatureFlagsTenants,
  fetchFeatureFlagsTenants,
  fetchFeatureFlagTenantsPagination,
} from 'redux/featureFlagsTenant/action';
// import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';
import { QueryParamsDTO } from 'types/QueryParams';

export function useTenantFeatureData() {
  const tenantFeatureList = useSelector(
    (state: any) => state.featureFlagsTenant
  );
  const dispatch = useDispatch();

  const getAllFeatureFlagTenants = (params: QueryParamsDTO) => {
    dispatch(fetchAllFeatureFlagsTenants(params));
  };

  const getTenantFeatureList = (tenantId: string) => {
    // fire api call by tenantid
    // store to redux

    // filter calls this
    dispatch(fetchFeatureFlagsTenants(tenantId));
  };

  const getTenantFeatureListPagination = (
    tenantId: string,
    params: QueryParamsDTO
  ) => {
    dispatch(fetchFeatureFlagTenantsPagination(tenantId, params));
  };

  // const updateTenantFeatureStatus = (
  //   tenantId: string,
  //   featureId: string,
  //   status: boolean
  // ) => {
  //   const updater: UpdateFeatureFlagsTenantDTO = {
  //     tenantId,
  //     featureId,
  //     status,
  //   };
  //   // fire api call update
  //   // store in redux
  //   // dispatch(updateFeatureFlagsTenant(updater));
  // };

  return {
    stuff: tenantFeatureList,
    data: tenantFeatureList?.data || [],
    list: tenantFeatureList?.list?.data || [],
    pagination: tenantFeatureList?.list?.meta || [],
    loading: tenantFeatureList?.loading || false,
    updating: tenantFeatureList?.updating || false,
    getTenantFeatureList,
    // updateData,
    //  `   updateTenantFeatureStatus,`
    getAllFeatureFlagTenants,
    getTenantFeatureListPagination,
  };
}
