import moment from 'moment';
import { TracedHoursExportDTO } from 'types/tracking';
import { formatTotalMinutes } from '../utils';

export const trackedHoursFormatedExportData = (data: TracedHoursExportDTO[]) =>
  [
    ...data.map((el) => [
      [`${el?.employeeName}`],
      [`${el?.projectName}`],
      [`${moment(el?.date).format('DD/MM/YYYY')}`],
      [`${moment(el?.startTime).format('hh:mm A')}`],
      [`${moment(el?.endTime).format('hh:mm A')}`],
      [`${moment(el?.breakStartTime).format('hh:mm A')}`],
      [`${moment(el?.breakEndTime).format('hh:mm A')}`],
      [
        `${`${formatTotalMinutes(el?.duration || 0).split(':')[0]}h`}` +
          `:${`${formatTotalMinutes(el?.duration || 0).split(':')[1]}m`}`,
      ],
      [`${el?.description}`],
      [`${el?.hourType?.charAt(0).toUpperCase()}${el?.hourType?.slice(1)}`],
    ]),
  ].join('\n');
