import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { ITimeOffRequestDTO } from 'types/TimeOff';
import Content from 'ui-v2/components/Content';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import useTimeOffRequestsData from 'ui-v2/hooks/useTimeOffRequestsData';
import { useSearchParams } from 'react-router-dom';
import TimeOffRequestDetails from './TimeOffRequestDetails';
import TimeOffRequestList from './TimeOffRequestList';
import TimeOffCalendarAdminCard from '../ProtectedRoutes/Employee/TimeOff/TimeOffCalendarAdminCard/TimeOffCalendarAdminCard';
import { FullWidthRow } from './TimeOffRequestsStyles';

export default function TimeOffRequests() {
  const { requests, loading, fetchDataApprover } = useTimeOffRequestsData();
  const [selectedRequest, setSelectedRequest] = useState<any | null>();
  const [currentData, setCurrentData] = useState<ITimeOffRequestDTO | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const { isMobile } = useScreenSize();

  const requestIdFromUrl = searchParams.get('requestId');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentData]);

  useEffect(() => {
    if (requestIdFromUrl && requests?.timeOffs?.length) {
      const foundRequest = requests.timeOffs.find(
        (request) => request.id === requestIdFromUrl
      );
      if (foundRequest) {
        setSelectedRequest(foundRequest);
      }
    }
  }, [requestIdFromUrl, requests]);

  const setRequests = (requests: ITimeOffRequestDTO | null) => {
    setCurrentData(requests);
    setSelectedRequest(null);
  };

  return (
    <Content position="stretch">
      <Row gutter={[0, 16]}>
        <FullWidthRow gutter={16}>
          <Col xs={24} md={10} lg={10} xl={10}>
            <TimeOffRequestList
              requests={currentData || requests}
              loading={loading}
              onRequest={setSelectedRequest}
            />
          </Col>

          <Col xs={24} md={14} lg={14} xl={14}>
            {selectedRequest?.id && (
              <TimeOffRequestDetails
                fetchDataApprover={fetchDataApprover}
                requests={currentData || requests}
                selectedRequestId={selectedRequest?.id}
                isPhoneScreen={isMobile}
              />
            )}
          </Col>
        </FullWidthRow>
        {!isMobile && (
          <TimeOffCalendarAdminCard
            employees={requests?.employees || []}
            requests={requests || null}
            setRequests={setRequests}
            fetchDataApprover={fetchDataApprover}
          />
        )}
      </Row>
    </Content>
  );
}
