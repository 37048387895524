import { Dispatch } from 'redux';
import {
  getAllFeatureFlagsTenant,
  getFeatureFlagsTenant,
  getFeatureFlagsTenantPagination,
} from 'api/featureFlagsTenantService';
// import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';
import { QueryParamsDTO } from 'types/QueryParams';
import { FeatureFlagsTenantAction } from './types';

export const fetchAllFeatureFlagsTenants =
  (params: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_START,
    });
    getAllFeatureFlagsTenant(params)
      .then((res) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_ALL_FEATURE_FLAGS_TENANT_ERRORED,
          payload: e,
        });
      });
  };

export const fetchFeatureFlagTenantsPagination =
  (tenantId: string, params: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_START,
    });
    getFeatureFlagsTenantPagination(tenantId, params)
      .then((res) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_PAGE_FEATURE_FLAGS_TENANT_ERRORED,
          payload: e,
        });
      });
  };

export const fetchFeatureFlagsTenants =
  (tenantId: string) => (dispatch: Dispatch) => {
    dispatch({
      type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_START,
    });
    getFeatureFlagsTenant(tenantId)
      .then((res) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_ERRORED,
          payload: e,
        });
      });
  };

// export const updateFeatureFlagsTenant =
//   (payload: UpdateFeatureFlagsTenantDTO) => (dispatch: Dispatch) => {
//     dispatch({
//       type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_START,
//     });
//     updateFeatureFlagsTenants(payload)
//       .then((res) => {
//         dispatch({
//           type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_SUCCESS,
//           payload: [...res.data],
//         });
//       })
//       .catch((e) => {
//         dispatch({
//           type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_ERRORED,
//           payload: e,
//         });
//       });
//   };

export const clearFetchFeatureFlagsTenants = () => (dispatch: Dispatch) => {
  dispatch({
    type: FeatureFlagsTenantAction.CLEAR_FEATURE_FLAGS_TENANT,
  });
};
