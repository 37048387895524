import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Calendar } from 'antd';
// import DateHolidays from 'date-holidays';

import { TimeOffRecord, TimeOffRequest } from 'types/TimeOff';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { getHoliday } from 'api/holidays';
import { RootState } from 'redux/store';
import { HolidayDetail } from 'types/Holidays';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { WorkWeekType } from 'types/TenantLocation';
import {
  findDate,
  findDateInTimeOffRequests,
  getDaysBetweenDates,
  isDayIncluded,
  isInRange,
} from './utils';
import CellRender from './CellRender';

export default function TimeOffCalendar({
  timeOff,
  onChange,
  timeOffList,
}: {
  timeOff: Array<TimeOffRecord>;
  onChange: (data: any) => void;
  timeOffList: Array<TimeOffRequest>;
}) {
  const [a, setA] = useState<any>('');
  const [b, setB] = useState<any>('');
  const [holidays, setHolidays] = useState<Moment[]>([]);
  const [holidayNames, setHolidayNames] = useState<Record<string, string>>({});
  const { isMobile } = useScreenSize();

  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  useEffect(() => {
    if (!a && !b) return;

    // When a or b changes - IF theres already selected options in that day, store them again

    if (a && !b) {
      onChange((data: any) => [
        ...data,
        {
          date: a,
        },
      ]);
      return;
    }

    if (a && b) {
      const range = getDaysBetweenDates(a, b);

      onChange(
        range.map((item) => {
          const exists = findDate(timeOff, item);
          if (exists) {
            return exists;
          }
          return {
            date: item,
          };
        })
      );
    }
  }, [a, b]);

  useEffect(() => {
    if (!timeOff.length) {
      setA('');
      setB('');
    }
  }, [timeOff]);

  const [calendarYear, setCalendarYear] = useState(moment().year());

  useEffect(() => {
    const fetchHolidays = async () => {
      const locationId = authUser?.employee?.tenantLocation?.id;
      if (!locationId) {
        return;
      }

      try {
        const response = await getHoliday(locationId || '');

        const holidayNamesMap: Record<string, string> = {};
        const holidayDates: Moment[] = [];

        if (response?.data?.holidayObject) {
          JSON.parse(response?.data?.holidayObject).forEach(
            (holiday: HolidayDetail) => {
              const holidayDate = moment(holiday.date);
              const adjustedHolidayDate = holidayDate.year(calendarYear);
              holidayNamesMap[adjustedHolidayDate.format('YYYY-MM-DD')] =
                holiday.name;
              holidayDates.push(adjustedHolidayDate);
            }
          );
        }

        setHolidayNames(holidayNamesMap);
        setHolidays(holidayDates);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    fetchHolidays();
  }, [authUser, calendarYear]);

  const dateList: Array<Moment> = useMemo(
    () =>
      timeOffList
        .map((t) => t.dates)
        .map((i) => JSON.parse(i))
        .flat()
        .map((i) => moment(i.date)),
    [timeOffList]
  );

  const onPanelChange = (value: Moment) => {
    setCalendarYear(value.year());
    if (moment(value).isSame(a)) {
      setA('');
      setB('');
    }
    if (!a) {
      setA(value);
      return;
    }

    setB(value);
  };

  const onTimes = (startTime: Moment, endTime: Moment) => {
    onChange([
      ...timeOff.filter((item: any) => {
        if (!moment(item.date).isSame(startTime, 'date')) {
          return item;
        }
        return false;
      }),
      {
        date: startTime,
        timeOffStart: startTime,
        timeOffEnd: endTime,
      },
    ]);
  };
  function isWeekendDisabled(
    date: Moment,
    weekWork: WorkWeekType | undefined
  ): boolean {
    const day = date.day();
    if (weekWork === WorkWeekType.MondayToFriday) {
      return day === 0 || day === 6;
    }
    if (weekWork === WorkWeekType.MondayToSaturday) {
      return day === 0;
    }
    return false;
  }
  const isDateDisabled = (date: Moment) =>
    isWeekendDisabled(date, authUser?.employee?.tenantLocation?.weekWork) ||
    isDayIncluded(date, dateList) ||
    holidays.some((holiday) => holiday.isSame(date, 'day'));

  const dateCellRender = (cellDate: any) => {
    if (isDateDisabled(cellDate)) {
      const result: any = findDateInTimeOffRequests(timeOffList, cellDate);

      let holidayName: string | null =
        holidayNames[cellDate.format('YYYY-MM-DD')];

      if (isMobile) {
        holidayName = null;
      }
      let reason: string | null = 'No reason provided';
      let status = 'Unknown';

      if (result !== null) {
        reason = result.reason !== null ? result.reason : reason;
        status = result.aprovalStatus !== null ? result.aprovalStatus : status;
      }

      if (
        isWeekendDisabled(
          cellDate,
          authUser?.employee?.tenantLocation?.weekWork
        )
      ) {
        reason = 'Weekend';
        status = 'Weekend';
      }

      if (isMobile) {
        reason = null;
      }

      return (
        <CellRender
          holidayName={holidayName}
          cellDate={cellDate}
          disabled
          description={reason || holidayName}
          status={status}
          holidays={holidays}
        />
      );
    }

    if (moment(a).isSame(cellDate) || isInRange(a, b, cellDate)) {
      const holidayName = holidayNames[cellDate.format('YYYY-MM-DD')];
      return (
        <CellRender
          holidayName={holidayName}
          cellDate={cellDate}
          selected
          onTimes={onTimes}
          holidays={holidays}
        />
      );
    }

    return null;
  };

  return (
    <Calendar
      fullscreen={!isMobile}
      dateCellRender={dateCellRender}
      disabledDate={isDateDisabled as any}
      onChange={onPanelChange}
    />
  );
}
