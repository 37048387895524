/* eslint-disable */

import { getCandidate, updateCandidate } from 'api/candidateService';
import { toast } from 'react-toastify';
import { AUTH_ROLES } from 'types/Auth';
import { CandidateStatus } from 'types/CandidateStatus';
import i18next from '../../../i18n';
import { USER_ROLE } from 'utils/constants';
import { UserRole } from 'types/Vendor';

const userRole = localStorage.getItem(USER_ROLE);

export enum HEADER_BUTTON_TYPE {
  EDIT_VENDOR = 'Edit vendor',
  ADD_NEW_VENDOR = 'Add new vendor',
  ADD_NEW_SKILL = 'Add new category',
  CHANGE_USER_PASSWORD = 'Change Password',
  CHANGE_SUPER_ADMIN_USER_PASSWORD = 'Change superadmin password',
  CHANGE_USER_PASSWORD_SAVE = 'Change Password Save',
  CHANGE_USER_PASSWORD_CANCEL = 'Change Password Cancel',
  EDIT_USER_PROFILE = 'Edit User Profile',
  EDIT_SUPER_ADMIN_USER_PROFILE = 'Edit superadmin profile',
  CONFIGURE = 'Configure',
  FILTER_BY = 'Filter by',
  TRACKING_HOURS_FILTER = 'Tracking Filters',
  ADD_NEW_CANDIDATE = 'Add a new candidate',
  ADD_NEW_OPEN_POSITION = 'Add a new open position',
  ADD_NEW_PROJECT = 'Add a new project',
  ADD_NEW_QUESTIONNAIRE = 'Add a new questionnaire',
  ADD_NEW_IDEABOX = 'Add a new ideabox',
  ADD_NEW_ASSET = 'Add a new asset',
  EDIT_ASSET = 'Edit Asset',
  ADD_NEW_DEPARTMENT = 'Add a new department',
  ADD_NEW_WORK_POSITION = 'Add new work position',
  ADD_NEW_CERTIFICATION = 'Add certification',
  ADD_NEW_CANDIDATE_APPROVE = 'Add candidate',
  ADD_NEW_CANDIDATE_CANCEL = 'Cancel',
  ADD_NEW_TRAINING = 'Add new training',
  ADD_NEW_EVALUATION_PERIOD = 'Add new evaluation',
  SCHEDULE_INTERVIEW = 'Schedule Interview',
  ADD_USER_LIST = 'Add New User',
  ADD_USER_SAVE = 'ADD_USER_SAVE',
  ADD_USER_CANCEL = 'ADD_USER_CANCEL',
  DISQUALIFY_CANDIDATE = 'Disqualify',
  DISQUALIFY_CANDIDATE_APPROVE = 'DISQUALIFY_CANDIDATE_APPROVE',
  DISQUALIFY_CANDIDATE_CANCEL = 'DISQUALIFY_CANDIDATE_CANCEL',
  HIRE_CANDIDATE = 'Hire',
  OFFER_CANDIDATE = 'Offer',
  HIRE_CANDIDATE_CANCEL = 'HIRE_CANDIDATE_CANCEL',
  HIRE_CANDIDATE_APPROVE = 'HIRE_CANDIDATE_APPROVE',
  HIRE_CANDIDATE_FORM_CANCEL_BUTTON = 'No, Cancel',
  HIRE_CANDIDATE_FORM_SAVE = 'Save changes',
  ADD_CANDIDATE_CANCEL = 'ADD_CANDIDATE_CANCEL',
  ADD_CANDIDATE_SAVE = 'ADD_CANDIDATE_SAVE',
  ADD_OPEN_POSITION_CANCEL = 'ADD_OPEN_POSITION_CANCEL',
  ADD_OPEN_POSITION_SAVE = 'ADD_OPEN_POSITION_SAVE',
  ADD_DEPARTMENT_CANCEL = 'ADD_DEPARTMENT_CANCEL',
  ADD_DEPARTMENT_SAVE = 'ADD_DEPARTMENT_SAVE',
  ADD_WORK_POSITION_CANCEL = 'ADD_WORK_POSITION_CANCEL',
  ADD_WORK_POSITION_SAVE = 'ADD_WORK_POSITION_SAVE',
  ADD_PROJECT_CANCEL = 'ADD_PROJECT_CANCEL',
  ADD_PROJECT_SAVE = 'ADD_PROJECT_SAVE',
  ADD_ASSET_CANCEL = 'ADD_ASSET_CANCEL',
  ADD_ASSET_SAVE = 'ADD_ASSET_SAVE',
  ADD_TRAINING_SAVE = 'ADD_TRAINING_SAVE',
  ADD_TRAINING_CANCEL = 'ADD_TRAINING_CANCEL',
  ADD_BENEFIT_SAVE = 'ADD_BENEFIT_SAVE',
  ADD_BENEFIT_CANCEL = 'ADD_BENEFIT_CANCEL',
  ADD_IP_WHITELISTING = 'Add IP Whitelisting',
  ADD_PUBLIC_SUPER_ADMIN = 'ADD SUPER ADMIN',
  ADD_VENDOR_QUESTIONNAIRE = 'addVendorQuestionnaire',
  ADD_NEW_BENEFIT = 'Add a new benefit',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  EDIT_QUESTIONNAIRE = 'Edit Questionnaire',
  EDIT_IDEABOX = 'Edit Ideabox',
  EDIT_EXPENSE = 'Edit Expense',
  EDIT_PROJECT = 'Edit Project',
  EDIT_WORK_POSITION = 'Edit work position',
  EDIT_TRAINING = 'Edit training',
  EDIT_BENEFIT = 'Edit benefit',
  EDIT_ONBOARDING = 'Edit onboarding',
  EDIT_ASSET_SAVE = 'EDIT_ASSET_SAVE',
  EDIT_ASSET_CANCEL = 'EDIT_ASSET_CANCEL',
  EDIT_WORK_POSITION_CANCEL = 'EDIT_WORK_POSITION_CANCEL',
  EDIT_WORK_POSITION_SAVE = 'EDIT_WORK_POSITION_SAVE',
  EDIT_OPEN_POSITION_SAVE = 'Save changes',
  EDIT_OPEN_POSITION_CANCEL = 'Cancel',
  EDIT_CANDIDATE = 'Edit Profile',
  EDIT_OPEN_POSITION = 'Edit open position',
  EDIT_CANDIDATE_SAVE = 'Edit profile Save changes',
  EDIT_CANDIDATE_CANCEL = 'Edit profile cancel',
  EDIT_TRAINING_SAVE = 'Save changes',
  EDIT_TRAINING_CANCEL = 'Cancel',
  EDIT_BENEFIT_SAVE = 'Save changes',
  EDIT_BENEFIT_CANCEL = 'Cancel',
  EDIT_USER_PROFILE_SAVE = 'Edit User Profile Save Changes',
  EDIT_USER_PROFILE_CANCEL = 'Cancel',
  EDIT_USER_SAVE = 'Edit User Profile Save Changes',
  EDIT_USER_CANCEL = 'Cancel',
  EDIT_USER = 'Edit User',
  ADD_TO_CANDIDATE_LIST = 'ADD_TO_CANDIDATE_LIST',
  ARCHIVE = 'ARCHIVE',
  TRAINING_DETAILS = 'TRAINING_DETAILS',
  BENEFIT_DETAILS = 'BENEFIT_DETAILS',
  ADD_NEW_EVALUATION = 'Add a new evaluation',
  ADD_NEW_EVALUATION_CANCEL = 'Cancel',
  ADD_NEW_EVALUATION_SAVE = 'Save',
  EDIT_EVALUATION = 'Edit Evaluation',
  GO_TO_EMPLOYEE_PROFILE = 'Go to employee profile',
  ADD_NEW_EMPLOYEE = 'Add a new Employee',
  ADD_EMPLOYEE_CANCLE = 'ADD_EMPLOYEE_CANCLE',
  ADD_EMPLOYEE_SAVE = 'ADD_EMPLOYEE_SAVE',
  EMPLOYEE_VIEW_CANDIDATE = 'View candidate',
  EDIT_EMPLOYEE = 'Edit Employee',
  EDIT_EMPLOYEE_CANCEL = 'EDIT_EMPLOYEE_CANCEL',
  EDIT_EMPLOYEE_SAVE = 'EDIT_EMPLOYEE_SAVE',
  SCHEDULE_NEW_INTERVIEW = 'Schedule a new Interview',
  EDIT_CERTIFICATION = 'Edit Certification',
  EDIT_DEPARTMENT = 'Edit Department',
  EDIT_DEPARTMENT_SAVE = 'EDIT_DEPARTMENT_SAVE',
  EDIT_DEPARTMENT_CANCEL = 'EDIT_DEPARTMENT_CANCEL',
  ADD_WORK_LOG = 'ADD_WORK_LOG',
  ADD_WORK_LOG_FOR_ALL = 'ADD_WORK_LOG_FOR_ALL',
  ADD_NEW_ONBOARDING = 'Add a new onboarding',
  ADD_ONBOARDING_CANCEL = 'ADD_ONBOARDING_CANCEL',
  ADD_ONBOARDING_SAVE = 'ADD_ONBOARDING_SAVE',
  EDIT_ONBOARDING_CANCEL = 'EDIT_ONBOARDING_CANCEL',
  EDIT_ONBOARDING_SAVE = 'EDIT_ONBOARDING_SAVE',
  REGISTER_NEW_TENANT_ACCOUNT = 'New account',
  EDIT_ACCOUNT = 'Edit account',
  EDIT_USERS = 'EDIT_USERS',
  DELETE_USERS = 'DELETE_USERS',
  SETTINGS_SAVE = 'SETTINGS_SAVE',
  ADD_PAYROLL_RULE = 'ADD_PAYROLL_RULE',
  EDIT_PAYROLL_RULE = 'EDIT_PAYROLL_RULE',
  GENERATE_REPORT = 'GENERATE_REPORT',
  NEW_REPORT = 'NEW_REPORT',
  NEW_EXPENSE = 'NEW_EXPENSE',
  SAVE_FEATURE_FLAGS = 'SAVE_FEATURE_FLAGS',
  DATA_ENTRY_SAVE_EMPLOYEE = 'DATA_ENTRY_SAVE_EMPLOYEE',
  ADD_NEW_PURCHASE_REQUEST = 'Add new purchase request',
  ADD_NEW_ASSET_CATEGORY = 'Add new asset category',
  ADD_NEW_PURCHASE_ORDER = 'Add purchase order',
  ADD_NEW_INVOICE = 'Add new invoice',
  EDIT_INVOICE = 'Edit invoice',
  EDIT_PURCHASE_ORDER = 'Edit purchase order',
  ADD_NEW_VENDOR_CATEGORY = 'Add new vendor category',
  EDIT_VENDOR_CATEGORY = 'Edit vendor category',
  EDIT_VENDOR_QUESTIONNAIRE = 'editVendorQuestionnaire',
  NEW_NODE = 'NEW_NODE',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  PENDING = 'Pending',
  ADD_FEATURE = 'ADD_FEATURE'
}

export const actionsMap = {
  tenants: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
  ],
  'vendor-questionnaire': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_VENDOR_QUESTIONNAIRE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-vendor-questionnaire',
            value: 'true',
          })
        ),
    },
  ],
  'vendor-questionnaire-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_VENDOR_QUESTIONNAIRE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-vendor-questionnaire',
            value: 'true',
          })
        ),
    },
  ],

  'tenant-accounts': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.REGISTER_NEW_TENANT_ACCOUNT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'register-account',
            value: 'true',
          })
        ),
    },
  ],

  'ip-whitelisting': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_IP_WHITELISTING,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-ip-whitelisting',
            value: 'true',
          })
        ),
    },
  ],

  'public-super-admins': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_PUBLIC_SUPER_ADMIN,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-public-super-admin',
            value: 'true',
          })
        ),
    },
  ],

  onboardings: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_ONBOARDING,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-new-onboarding',
            value: 'true',
          })
        ),
    },
  ],
  settings: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.SETTINGS_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'settings-save',
            value: 'true',
          })
        ),
    },
  ],
  'onboarding-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_ONBOARDING,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-onboarding',
            value: 'true',
          })
        ),
    },
  ],

  'work-positions': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_WORK_POSITION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-new-work-position',
            value: 'true',
          })
        ),
    },
  ],
  'purchase-requests': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_PURCHASE_REQUEST,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-new-purchase-request',
            value: 'true',
          })
        ),
    },
  ],
  vendors: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_VENDOR,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-vendor',
            value: 'true',
          })
        ),
    },
  ],
  'vendor-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_VENDOR,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-vendor',
            value: 'true',
          })
        ),
    },
  ],
  'work-position-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_WORK_POSITION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-work-position',
            value: 'true',
          })
        ),
    },
  ],
  departments: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_DEPARTMENT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-new-department',
            value: 'true',
          })
        ),
    },
  ],

  'department-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_DEPARTMENT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-department',
            value: 'true',
          })
        ),
    },
  ],

  'add-department': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_DEPARTMENT_CANCEL,
      href: '/departments',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_DEPARTMENT_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-department',
            value: 'true',
          })
        ),
    },
  ],
  'save-department': [],
  candidates: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_CANDIDATE,
      href: 'add-candidate',
    },
  ],
  projects: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: userRole === 'employee' ? '' : HEADER_BUTTON_TYPE.ADD_NEW_PROJECT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-project',
            value: 'true',
          })
        ),
    },
  ],
  questionnaires: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_QUESTIONNAIRE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-questionnaire',
            value: 'true',
          })
        ),
    },
  ],
  'questionnaire-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_QUESTIONNAIRE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-questionnaire-details',
            value: 'true',
          })
        ),
    },
  ],
  ideabox: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_IDEABOX,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-ideabox',
            value: 'true',
          })
        ),
    },
  ],
  'ideabox-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_IDEABOX,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-ideabox-details',
            value: 'true',
          })
        ),
    },
  ],
  'expense-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_EXPENSE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-expense-details',
            value: 'true',
          })
        ),
    },
  ],
  hierarchy: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.NEW_NODE,
      href: 'employee',
    },
  ],
  assets: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_ASSET,
      href: 'add-asset',
    },
  ],

  certifications: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_CERTIFICATION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-certification',
            value: 'true',
          })
        ),
    },
  ],
  'certification-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_CERTIFICATION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-certification',
            value: 'true',
          })
        ),
    },
  ],
  'open-positions': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_OPEN_POSITION,
      href: 'add-open-position',
    },
  ],
  users: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_USER_LIST,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-new-user',
            value: 'true',
          })
        ),
    },
  ],
  skills: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },

    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_SKILL,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-skill-save',
            value: 'true',
          })
        ),
    },
  ],
  'add-open-position': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_OPEN_POSITION_CANCEL,
      href: '/open-positions',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_OPEN_POSITION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-open-position',
            value: 'true',
          })
        ),
    },
  ],

  'edit-open-position': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION_CANCEL,
      onClick: (
        _setSearchParams: any,
        _setActions: any,
        _setMessage: any,
        id: any
      ) => {
        return (window.location.href = `open-position-details?id=${id}`);
      },
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-open-position',
            value: 'true',
          })
        ),
    },
  ],
  'open-position-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-open-position',
            value: 'true',
          })
        ),
    },
  ],
  trainings: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_TRAINING,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-training',
            value: 'true',
          })
        ),
    },
  ],
  'edit-training': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_TRAINING_CANCEL,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-training',
            value: 'true',
          })
        ),
    },
  ],
  'training-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_TRAINING,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-training',
            value: 'true',
          })
        ),
    },
  ],
  benefits: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_BENEFIT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'added-benefit-template',
            value: 'true',
          })
        ),
    },
  ],
  'benefits-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.BENEFIT_DETAILS,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edited-benefit-template',
            value: 'true',
          })
        ),
    },
  ],
  'evaluation-periods': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_PERIOD,
      href: 'add-evaluation-periods',
    },
  ],
  profile: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_CANDIDATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-profile',
            value: 'true',
          })
        ),
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.SCHEDULE_INTERVIEW,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'schedule',
            value: 'true',
          })
        ),
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'disqualify',
            value: 'true',
          })
        ),
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.OFFER_CANDIDATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'offer',
            value: 'true',
          })
        ),
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.HIRE_CANDIDATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'hire',
            value: 'true',
          })
        ),
    },
  ],
  'add-user': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_USER_CANCEL,
      href: '/users',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_USER_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-user',
            value: 'true',
          })
        ),
    },
  ],
  'asset-details': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_ASSET,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-asset',
            value: 'true',
          })
        ),
    },
  ],
  'asset-logs': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.START_DATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'startDate',
            value: 'true',
          })
        ),
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.END_DATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'endDate',
            value: 'true',
          })
        ),
    },
  ],
  'edit-asset': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_ASSET_CANCEL,
      href: '/assets',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_ASSET_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-asset',
            value: 'true',
          })
        ),
    },
  ],
  'add-asset': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_ASSET_CANCEL,
      href: '/assets',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_ASSET_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-asset',
            value: 'true',
          })
        ),
    },
  ],
  'project-details': [
    {
      type: 'link',
      label:
        userRole === UserRole.Employee ? '' : HEADER_BUTTON_TYPE.EDIT_PROJECT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-project',
            value: 'true',
          })
        ),
    },
  ],
  'edit-work-position': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_WORK_POSITION_CANCEL,
      onClick: (
        _setSearchParams: any,
        _setActions: any,
        _setMessage: any,
        id: any
      ) => {
        return (window.location.href = `work-position-details?id=${id}`);
      },
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_WORK_POSITION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-work-position',
            value: 'true',
          })
        ),
    },
  ],
  'add-work-position': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_WORK_POSITION_CANCEL,
      href: '/work-positions',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_WORK_POSITION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-work-position',
            value: 'true',
          })
        ),
    },
  ],

  'add-candidate': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_CANDIDATE_CANCEL,
      href: '/candidates',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_CANDIDATE_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save',
            value: 'true',
          })
        ),
    },
  ],
  'edit-profile': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_CANDIDATE_CANCEL,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_CANDIDATE_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-user',
            value: 'true',
          })
        ),
    },
  ],
  'edit-user': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_USER_CANCEL,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_USER_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-user',
            value: 'true',
          })
        ),
    },
  ],
  hire: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.HIRE_CANDIDATE_CANCEL,
      onClick: (_: any, setActions: any, setMessage: any) => {
        setActions(actionsMap.profile);
        setMessage('');
      },
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.HIRE_CANDIDATE_APPROVE,
      // href: `hire-candidate?id=${getProfileId()}`,
    },
  ],
  'hire-candidate': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.HIRE_CANDIDATE_FORM_CANCEL_BUTTON,
      // onClick: `profile?id=${getProfileId()}`,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.HIRE_CANDIDATE_FORM_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'hire-candidate-save',
            value: 'true',
          })
        ),
    },
  ],
  'is-hired': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.GO_TO_EMPLOYEE_PROFILE,
    },
  ],
  disqualify: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE_CANCEL,
      onClick: (_: any, setActions: any, setMessage: any) => {
        setActions(actionsMap.profile);
        setMessage('');
      },
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE_APPROVE,
      onClick: (
        setSearchParams: any,
        setActions: any,
        setMessage: any,
        ID: string
      ) => {
        updateCandidate(ID, {
          status: CandidateStatus.REJECTED,
        })
          .then((res) => {
            if (res.status === 200) {
              toast.success(i18next.t('candidateDisqualified'));
              getCandidate(ID);
              setActions(actionsMap.profile);
              setMessage('');
              setSearchParams((searchParams: any) =>
                searchParamsAppend({
                  searchParams,
                  key: 'candidate-rejected',
                  value: 'true',
                })
              );
              return;
            }
            toast.error(i18next.t('failedtoDisqualifyCandidate'));
          })
          .catch(() => {
            toast.error(i18next.t('failedtoDisqualifyCandidate'));
          });
      },
    },
  ],
  evaluations: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-evaluation',
            value: 'true',
          })
        ),
    },
  ],
  'add-evaluation': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_CANCEL,
      href: '/evaluations',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-save-evaluation',
            value: 'true',
          })
        ),
    },
  ],
  employees: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EMPLOYEE,
      href: 'add-employee',
    },
  ],
  checkin: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
  ],
  'add-employee': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_EMPLOYEE_CANCLE,
      href: '/employees',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_EMPLOYEE_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-employee-save',
            value: 'true',
          })
        ),
    },
  ],
  'edit-employee': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_EMPLOYEE_CANCEL,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_EMPLOYEE_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-employee-save',
            value: 'true',
          })
        ),
    },
  ],
  'employee-profile': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EMPLOYEE_VIEW_CANDIDATE,
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_EMPLOYEE,
    },
  ],
  'my-profile': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_USER_PROFILE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-my-profile',
            value: 'true',
          })
        ),
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD,
    },
  ],
  'super-admin-profile': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_SUPER_ADMIN_USER_PROFILE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-super-admin-profile',
            value: 'true',
          })
        ),
    },
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.CHANGE_SUPER_ADMIN_USER_PASSWORD,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'change-super-admin-password',
            value: 'true',
          })
        ),
    },
  ],
  'edit-user-profile': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_USER_PROFILE_CANCEL,
      href: '/my-profile',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_USER_PROFILE_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save',
            value: 'true',
          })
        ),
    },
  ],
  'change-password': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD_CANCEL,
      href: '/my-profile',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'change-password-save',
            value: 'true',
          })
        ),
    },
  ],
  'edit-evaluation': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_CANCEL,
      href: '/evaluations',
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_SAVE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-save-evaluation',
            value: 'true',
          })
        ),
    },
  ],
  interviews: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.SCHEDULE_NEW_INTERVIEW,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'schedule-new-interview',
            value: 'true',
          })
        ),
    },
  ],
  'view-evaluation': [
    {
      type: 'link',
      label: HEADER_BUTTON_TYPE.EDIT_EVALUATION,
    },
  ],

  dashboard: [],
  tracking: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_WORK_LOG_FOR_ALL,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-work-log',
            value: 'true',
          })
        ),
    },
  ],
  tracker: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_WORK_LOG,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-work-log',
            value: 'true',
          })
        ),
    },
  ],
  'payroll-rules': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_PAYROLL_RULE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-payroll-rules',
            value: 'true',
          })
        ),
    },
  ],
  'payroll-report': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.NEW_REPORT,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'new-report',
            value: 'true',
          })
        ),
    },
  ],
  'payroll-rule-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_PAYROLL_RULE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-rule',
            value: 'true',
          })
        ),
    },
  ],
  'data-entry': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.DATA_ENTRY_SAVE_EMPLOYEE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'save-employee',
            value: 'true',
          })
        ),
    },
  ],
  'asset-categories': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_ASSET_CATEGORY,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-asset-category',
            value: 'true',
          })
        ),
    },
  ],
  'vendor-categories': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_VENDOR_CATEGORY,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-vendor-category',
            value: 'true',
          })
        ),
    },
  ],
  'vendor-category-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_VENDOR_CATEGORY,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-vendor-category',
            value: 'true',
          })
        ),
    },
  ],
  'purchase-orders': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_PURCHASE_ORDER,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-purchase-order',
            value: 'true',
          })
        ),
    },
  ],
  invoicing: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.ADD_NEW_INVOICE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'add-invoice',
            value: 'true',
          })
        ),
    },
  ],
  'invoice-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_INVOICE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-invoice',
            value: 'true,',
          })
        ),
    },
  ],
  'purchase-order-details': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_PURCHASE_ORDER,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-purchase-order',
            value: 'true,',
          })
        ),
    },
  ],
  expenses: [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.NEW_EXPENSE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'new-expense',
            value: 'true',
          })
        ),
    },
  ],
  'feature-flags': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.CONFIGURE,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.FILTER_BY,
    },
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.SAVE_FEATURE_FLAGS,
    },
    // {
    //   type: 'button',
    //   label: HEADER_BUTTON_TYPE.ADD_FEATURE
    // }
  ],
  'candidate-profile': [
    {
      type: 'button',
      label: HEADER_BUTTON_TYPE.EDIT_CANDIDATE,
      onClick: (setSearchParams: any) =>
        setSearchParams((searchParams: any) =>
          searchParamsAppend({
            searchParams,
            key: 'edit-profile',
            value: 'true',
          })
        ),
    },
  ]
};

export const messages = {
  hire: 'Do you want to hire this candidate?',
  disqualify: 'Do you want to disqualify this candidate?',
};

export const searchParamsAppend = ({
  searchParams,
  key,
  value,
}: {
  searchParams: any;
  key: string;
  value: string;
}) => {
  const params: any = {};
  for (const [k, v] of searchParams) {
    params[k] = v;
  }

  return {
    ...params,
    [key]: value,
  };
};

export type ActionItem = keyof typeof actionsMap;

export function getProfileId() {
  return new URLSearchParams(window.location?.search).get('id');
}

export type RolesButtonType = {
  key: string;
  disabledRoles: AUTH_ROLES[];
};

export const BUTTONS = {
  UPLOAD_BUTTON: {
    key: 'UPLOAD_BUTTON',
    disabledRoles: [AUTH_ROLES.EMPLOYEE, AUTH_ROLES.PAYROLL_MANAGER],
  },

  DELETE_BUTTON: {
    key: 'DELETE_BUTTON',
    disabledRoles: [AUTH_ROLES.GUEST],
  },

  EDIT_BUTTON: {
    key: 'EDIT_BUTTON',
    disabledRoles: [AUTH_ROLES.GUEST],
  },

  ADD_BUTTON: {
    key: 'ADD_BUTTON',
    disabledRoles: [AUTH_ROLES.GUEST],
  },

  EDIT_BUTTON_GTH: {
    key: 'EDIT_BUTTON_GTH',
    disabledRoles: [AUTH_ROLES.GUEST_TRACKING_HOURS],
  },

  DELETE_BUTTON_GTH: {
    key: 'DELETE_BUTTON_GTH',
    disabledRoles: [AUTH_ROLES.GUEST_TRACKING_HOURS],
  },

  ADD_HOUR: {
    key: 'ADD_HOUR',
    disabledRoles: [AUTH_ROLES.GUEST_TRACKING_HOURS],
  },

  DELETE_EVALUATION_DETAILS: {
    key: 'DELETE_EVALUATION_DETAILS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  EDIT_EVALUATION_DETAILS: {
    key: 'EDIT_EVALUATION_DETAILS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  ADD_EVALUATION_DETAILS: {
    key: 'ADD_EVALUATION_DETAILS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  EDIT_ASSET: {
    key: 'EDIT_ASSET',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  ADD_ASSET: {
    key: 'ADD_ASSET',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  ADD_BONUS: {
    key: 'ADD_BONUS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  DELETE_BONUS: {
    key: 'ADD_BONUS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  EDIT_BONUS: {
    key: 'ADD_BONUS',
    disabledRoles: [
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST,
    ],
  },

  EDIT_TRAINING: {
    key: 'EDIT_TRAINING',
    disabledRoles: [AUTH_ROLES.EMPLOYEE, AUTH_ROLES.PAYROLL_MANAGER],
  },

  ADD_TRAINING: {
    key: 'ADD_TRAINING',
    disabledRoles: [
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.GUEST,
    ],
  },

  VIEW_ASSET_LOG: {
    key: 'VIEW_ASSET_LOG',
    disabledRoles: [
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
      AUTH_ROLES.GUEST_TRACKING_HOURS,
      AUTH_ROLES.GUEST,
    ],
  },
};
