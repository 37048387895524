import React, { useState } from 'react';
import { Button, Col, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import { fetchCandidate } from 'redux/candidates/actions';
import { CandidateNotesType } from 'types/CandidateNotes';
import { deleteCandidateNotes } from 'api/candidateNotesService';
import CardItem from '../Card';
import * as Styled from '../Task/TaskStyles';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import { NoteItemCandidate } from './CandidateNoteItem/NoteItemCandidate';
import AddCandidateNoteModal from './AddCandidateNotesModal/AddCandidateNoteModal';

interface IProps {
  notes: CandidateNotesType[];
  candidateId: string;
  loading: boolean;
}
export default function CandidateNotesComponent({
  notes,
  candidateId,
  loading,
}: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [editNoteId, setEditNoteId] = useState('');
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const onToggleModal = () => setModalVisible((state) => !state);

  const deleteNote = async (noteId: string) => {
    await deleteCandidateNotes(noteId)
      .then(() => {
        toast.success(t('noteDeleted'));
        dispatch(fetchCandidate(candidateId));
        setEditNoteId('');
      })
      .catch(() => {
        toast.error(t('errorDeletingthisNote'));
      });
  };
  const onEditClose = () => {
    setEditNoteId('');
  };
  const handleNoteItemClick = (id: string) => {
    setEditNoteId(id);
  };

  return (
    <CardItem title={t('notes')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          {notes.map(
            (n: CandidateNotesType, index: number) =>
              (n.isPublic || authUserRole !== AUTH_ROLES.CANDIDATE) && (
                <Col span={24}>
                  <NoteItemCandidate
                    item={n}
                    {...n}
                    onItemClick={handleNoteItemClick}
                    onDelete={() => deleteNote(n.id || '')}
                    isLast={index === notes.length - 1}
                  />
                </Col>
              )
          )}
          {!notes.length && (
            <Col>
              <Styled.Label>
                {authUserRole === AUTH_ROLES.CANDIDATE ? (
                  <Styled.Label>{t('youDontHaveAnyNotes')}</Styled.Label>
                ) : (
                  <Styled.Label>{t('assignANoteToThisCandidate')}</Styled.Label>
                )}
              </Styled.Label>
            </Col>
          )}
          {authUserRole !== AUTH_ROLES.CANDIDATE && (
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col span={24}>
                <Button
                  icon={
                    <Styled.AddSpaceIcon>
                      <PlusCircleOutlined />
                    </Styled.AddSpaceIcon>
                  }
                  size="large"
                  style={{
                    borderRadius: '8px',
                    width: '100%',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: '344054',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={onToggleModal}
                >
                  {t('addNote')}
                </Button>
              </Col>
            </RoleBasedButton>
          )}
        </Styled.RowItem>
        {modalVisible && authUserRole !== AUTH_ROLES.CANDIDATE && (
          <AddCandidateNoteModal
            open={modalVisible}
            closeModal={() => setModalVisible(false)}
            id=""
          />
        )}
        {editNoteId && authUserRole !== AUTH_ROLES.CANDIDATE && (
          <AddCandidateNoteModal
            id={editNoteId}
            open
            closeModal={onEditClose}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
