import React, { useCallback, useState } from 'react';
import { CSVDownload } from 'react-csv';
import { ExportOutlined } from '@ant-design/icons';

import { getTrackedHoursExport } from 'api/trackedHoursService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { TrackedHoursQueryParamDTO } from 'types/tracking';
import { TableBtn } from 'components/common/DataGrid/DataGridStyles';
import { trackedHoursFormatedExportData } from './utils';

const TrackingExport: React.FC = () => {
  const [exporting, setExporting] = useState(false);
  const [csvDate, setCsvData] = useState<string>();
  const { urlState } = useTrackedHoursData();

  const fetchCsvData = useCallback(async () => {
    if (csvDate?.length) {
      return;
    }
    setExporting(true);
    const queryPayload: TrackedHoursQueryParamDTO = {
      searchOptions: {
        startDate: urlState?.startDate,
        endDate: urlState?.endDate,
        employeeIds: urlState?.employeeIds,
        projectIds: urlState?.projectIds,
      },
      filterOptions: {
        filterText: urlState?.filterText,
      },
    };
    getTrackedHoursExport(queryPayload)
      .then((res) => {
        if (res.status === 200) {
          setCsvData(trackedHoursFormatedExportData(res.data));
        }
      })
      .catch(toastErrorMessages)
      .finally(() => {
        setExporting(false);
      });
  }, [urlState]);

  return (
    <>
      <TableBtn type="default" onClick={fetchCsvData} loading={exporting}>
        <ExportOutlined />
      </TableBtn>
      {csvDate && (
        <CSVDownload
          data={csvDate}
          headers={[
            'Employee',
            'Project',
            'Date',
            'Start Time',
            'End Time',
            'Break Start Time',
            'Break End Time',
            'Duration',
            'Description',
            'Type',
          ]}
          filename="tracking-hours-data.csv"
          target="_blank"
        />
      )}
    </>
  );
};

export default TrackingExport;
