import agent from 'api/agent';
import moment from 'moment';
import {
  TrackedHourCreateDTO,
  TrackedHourDTO,
  TrackedHourApproveDTO,
  TrackedHoursQueryParamDTO,
  TrackedHourUpdateDTO,
} from 'types/tracking';
import { apiURLs } from './constants';

export const getTrackedHoursDetails = async (
  queryParams?: TrackedHoursQueryParamDTO
) => agent.post(`${apiURLs.tackingHours}/detailed`, queryParams);

export const getTrackedHoursSummary = (
  queryParams?: TrackedHoursQueryParamDTO
) =>
  agent.post(`${apiURLs.tackingHours}/summary`, {
    searchOptions: queryParams?.searchOptions,
  });

export const getTrackedHoursBarChart = (filters: TrackedHoursQueryParamDTO) => {
  const range =
    moment(filters.searchOptions?.endDate).diff(
      filters?.searchOptions?.startDate,
      'months'
    ) > 0
      ? 'months'
      : 'days';
  return agent.post(`${apiURLs.tackingHours}/barChart/${range}`, {
    ...filters,
  });
};

export const getTrackedHoursPieCHart = (filters: TrackedHoursQueryParamDTO) =>
  agent.post(`${apiURLs.tackingHours}/pieChart`, filters);

export const approveTrackedHour = (payload: TrackedHourDTO) =>
  agent.put(`${apiURLs.tackingHours}/approveOvertime`, payload);

export const getTrackedHoursToApprove = (filters?: TrackedHoursQueryParamDTO) =>
  agent.post(`${apiURLs.tackingHours}${apiURLs.toApprove}`, filters);

export const deleteTrackedHourById = (id: string) =>
  agent.delete(`${apiURLs.tackingHours}/${id}`);

export const getTrackedHoursExport = (
  queryParams?: TrackedHoursQueryParamDTO
) => agent.post(`${apiURLs.tackingHours}/export`, queryParams);

export const approveTrackingHourOverTime = (payload: TrackedHourApproveDTO) =>
  agent.put(`${apiURLs.tackingHours}/approveOvertime`, payload);

export const updateTrackingHours = (
  id: string,
  payload: TrackedHourUpdateDTO
) => agent.put(`${apiURLs.tackingHours}/${id}`, payload);

export const createTrackingHour = (payload: TrackedHourCreateDTO) =>
  agent.post(`${apiURLs.tackingHours}`, payload);

export const getUserTrackingHoursTeamSkim = () =>
  agent.get(`${apiURLs.tackingHours}/employees/skim`);

export const getTimerState = () =>
  agent.get(`${apiURLs.tackingHours}/getTimer`);

export const startTimer = (assignmentId: string) =>
  agent.post(`${apiURLs.tackingHours}/startTimer`, { assignmentId });

export const pauseTimer = () =>
  agent.post(`${apiURLs.tackingHours}/pauseTimer`);

export const resumeTimer = () =>
  agent.post(`${apiURLs.tackingHours}/resumeTimer`);

export const stopTimer = () => agent.post(`${apiURLs.tackingHours}/stopTimer`);
