/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useState } from 'react';

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

import CardItem from 'ui-v2/components/Card';
import { DashboardBubbleStatsType } from 'types/DashboardStats';
import {
  getAllDashboardBubbleStats,
  getDashboardBubbleStatsCandidates,
} from 'api/dashboardStats';
import { Col, Row, Spin, Typography } from 'antd';
import { FAVORITE_PALETTE, PALETE } from 'components/styledComponents/colors';
import { useTranslation } from 'react-i18next';

export type BubbleDataItem = {
  value: number;
  r: number;
  x: number;
  y: number;
};

export type BubbleDataType = ChartData<'bubble', BubbleDataItem[]>;

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

type BubbleChartProps = {
  candidates?: boolean;
};

const BubbleChart = ({ candidates = false }: BubbleChartProps) => {
  const [dashboardBubbleStats, setDashboardBubbleStats] =
    useState<DashboardBubbleStatsType>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  async function fetchDashboardBubbleStats() {
    try {
      if (candidates) {
        const { data } = await getDashboardBubbleStatsCandidates();
        setDashboardBubbleStats(data);
        setLoading(false);
      } else {
        const { data } = await getAllDashboardBubbleStats();
        setDashboardBubbleStats(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchDashboardBubbleStats();
  }, []);

  const bubbleData: BubbleDataType = useMemo(
    () => ({
      datasets: dashboardBubbleStats
        ? dashboardBubbleStats.map((item, index) => ({
            label: item.subCategoryName,
            clip: { left: 0, top: 0, right: 0, bottom: 0 },
            data: [
              {
                value: candidates
                  ? parseInt(item.candidate_count, 10)
                  : parseInt(item.employee_count, 10),
                r: candidates
                  ? parseInt(item.candidate_count, 10) * 5
                  : parseInt(item.employee_count, 10) * 5,
                x: Math.floor(Math.random() * 80),
                y: Math.floor(Math.random() * 80),
              },
            ],
            backgroundColor: dashboardBubbleStats
              ? dashboardBubbleStats.map(
                  () => FAVORITE_PALETTE[index] ?? Object.values(PALETE)[index]
                )
              : [],
          }))
        : [],
    }),
    [dashboardBubbleStats]
  );
  return (
    <CardItem
      title={
        candidates
          ? t('group skills by candidate')
          : t('group skills by employee')
      }
      minHeight={400}
    >
      <Spin spinning={loading}>
        {!loading && dashboardBubbleStats?.length === 0 ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 350,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="employees/add-employee"
              >
                {t('addSomeEmployeesFirst!')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Bubble
            options={{
              // scales: {
              //   y: {
              //     display: false,
              //   },
              //   x: {
              //     display: false,
              //   },
              // },

              plugins: {
                tooltip: {
                  callbacks: {
                    label: (tooltipItems) => {
                      console.log();

                      return `${
                        candidates
                          ? t('candidatesWithSkill')
                          : t('employesWithSkill')
                      }: ${tooltipItems.label}, ${t('are')}: ${
                        (tooltipItems.raw as { value: string }).value
                      }`;
                    },
                  },
                },
              },
            }}
            data={bubbleData}
            plugins={[]}
          />
        )}
      </Spin>
    </CardItem>
  );
};

export default BubbleChart;
