import { MetaPagination } from 'types/GridTable';
import { SkillsetMatching } from 'types/SkillsetMatching';

export enum SkillsetMatchingActions {
  FETCH_SKILLSETMATCHING_START = 'FETCH_SKILLSETMATCHING_START',
  FETCH_SKILLSETMATCHING_SUCCESS = 'FETCH_SKILLSETMATCHING_SUCCESS',
  FETCH_SKILLSETMATCHING_ERRORED = 'FETCH_SKILLSETMATCHING_ERRORED',

  CLEAR_SKILLSETMATCHING = 'CLEAR_SKILLSETMATCHING',
}

export enum CandidateSkillsetAction {
  FETCH_CANDIDATESSKILLSET_START = 'FETCH_CANDIDATESSKILLSET_START',
  FETCH_CANDIDATESSKILLSET_SUCCESS = 'FETCH_CANDIDATESSKILLSET_SUCCESS',
  FETCH_CANDIDATESSKILLSET_ERRORED = 'FETCH_CANDIDATESSKILLSET_ERRORED',

  CLEAR_CANDIDATESSKILLSET = 'CLEAR_CANDIDATESSKILLSET',
}

export enum ShortListAction {
  FETCH_SHORTLIST_START = 'FETCH_SHORTLIST_START',
  FETCH_SHORTLIST_SUCCESS = 'FETCH_SHORTLIST_SUCCESS',
  FETCH_SHORTLIST_ERRORED = 'FETCH_SHORTLIST_ERRORED',

  CLEAR_SHORTLIST = 'CLEAR_SHORTLIST',
}

export interface SkillsetMatchingState {
  data: {
    data: Array<SkillsetMatching>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface CandidatesSkillsetState {
  data: {
    data: Array<SkillsetMatching>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface ShortListState {
  data: {
    data: Array<SkillsetMatching>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
