import React, { useEffect, useState } from 'react';
import { Calendar, Row } from 'antd';
import moment, { Moment } from 'moment';
import { ITimeOffRequestDTO } from 'types/TimeOff';
import { useTranslation } from 'react-i18next';
import { getHoliday } from 'api/holidays';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { HolidayDetail } from 'types/Holidays';
import { EmployeeType } from 'types/Employee';
import CellRender from './CellRender';
import { findRequestsAtDate } from '../TimeOffCalendar/utils';
import CustomCalendarHeader from './CustomCalendarHeader';
import { StyledCalendarHeader } from './CellRender/CellRenderStyles';

export default function TimeOffCalendarAdmin({
  requests,
  employees,
  setRequests,
}: {
  employees: EmployeeType[];
  requests: ITimeOffRequestDTO | null;
  setRequests: (requests: ITimeOffRequestDTO | null) => void;
}) {
  const [holidays, setHolidays] = useState<Moment[]>([]);
  const [holidayNames, setHolidayNames] = useState<Record<string, string>>({});
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());
  const { t } = useTranslation();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  function changeLanguage() {
    const weekDays = [
      t('Su'),
      t('Mo'),
      t('Tu'),
      t('We'),
      t('Th'),
      t('Fr'),
      t('Sa'),
    ];

    const table = document.querySelectorAll<HTMLElement>(
      '#timeoff-admin-calendar .ant-picker-content > thead > tr > th'
    );
    weekDays.forEach((item, index) => {
      table[index].innerText = item;
    });
  }
  useEffect(() => {
    const calendar = document.getElementById('timeoff-admin-calendar');
    if (calendar) {
      changeLanguage();
    }
  }, []);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await getHoliday(
          authUser?.employee?.tenantLocation?.id || ''
        );

        const holidayNamesMap: Record<string, string> = {};
        const holidayDates: Moment[] = [];

        if (response?.data?.holidayObject) {
          JSON.parse(response?.data?.holidayObject).forEach(
            (holiday: HolidayDetail) => {
              const holidayDate = moment(holiday.date);
              const adjustedHolidayDate = holidayDate.year(selectedYear);
              holidayNamesMap[adjustedHolidayDate.format('YYYY-MM-DD')] =
                holiday.name;
              holidayDates.push(adjustedHolidayDate);
            }
          );
        }

        setHolidayNames(holidayNamesMap);
        setHolidays(holidayDates);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    fetchHolidays();
  }, [authUser, selectedYear]);

  const onSelectDate = (requests: ITimeOffRequestDTO | null) => {
    setRequests(requests);
  };
  const dateCellRender = (cellDate: Moment) => {
    const isHoliday = holidays.some((holiday) =>
      holiday.isSame(cellDate, 'day')
    );
    const holidayName = holidayNames[moment(cellDate).format('YYYY-MM-DD')];

    const modifiedRequests: ITimeOffRequestDTO = {
      timeOffs: findRequestsAtDate(cellDate, requests),
      employees: requests?.employees || [],
      substitutes: requests?.substitutes || [],
    };

    return (
      <CellRender
        cellDate={cellDate}
        employees={employees}
        requests={modifiedRequests}
        onSelect={onSelectDate}
        isHoliday={isHoliday}
        holidayName={holidayName}
      />
    );
  };

  return (
    <Row id="timeoff-admin-calendar">
      <Calendar
        dateCellRender={dateCellRender}
        value={moment().year(selectedYear).month(selectedMonth)}
        headerRender={({ value }) => (
          <StyledCalendarHeader>
            <CustomCalendarHeader
              value={value}
              onSelectMonth={setSelectedMonth}
              onSelectYear={setSelectedYear}
            />
          </StyledCalendarHeader>
        )}
      />
    </Row>
  );
}
