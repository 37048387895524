import React from 'react';
import { Avatar, Col, List, Row, Skeleton, Tag } from 'antd';
import { ITimeOffRequestDTO, TimeOffRequest } from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import { getStatusColor } from 'ui-v2/components/Project/ProjectTimeOffs/ProjectTimeOffs';
import { useSearchParams } from 'react-router-dom';
import * as Styled from './TimeOffRequestListStyles';
import { WrapperOverFlow } from '../TimeOffRequestsStyles';

interface IProps {
  requests: ITimeOffRequestDTO | null;
  loading: boolean;
  onRequest: (request: TimeOffRequest) => void;
}

export default function TimeOffRequestList({
  requests,
  loading,
  onRequest,
}: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onItemClick = (request: TimeOffRequest) => {
    onRequest(request);
    if (request.id) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('requestId', request?.id);
      setSearchParams(newSearchParams);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { t } = useTranslation();

  const findEmployee = (request: TimeOffRequest) =>
    requests?.employees?.find((employee) => employee.id === request.employeeId);

  return (
    <CardItem title={t('requests')} wrapContentHeight>
      <WrapperOverFlow>
        <List
          loading={loading}
          itemLayout="horizontal"
          style={{ width: '100%' }}
          dataSource={requests?.timeOffs}
          renderItem={(request: TimeOffRequest) => {
            const employee = findEmployee(request);

            return (
              <Styled.Item
                onClick={() => onItemClick(request)}
                actions={[
                  <Row justify="center">
                    <Tag
                      color={getStatusColor(request.aprovalStatus)}
                      style={{ borderRadius: '8px' }}
                    >
                      {request.aprovalStatus}
                    </Tag>
                  </Row>,
                ]}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar>{employee?.firstName?.trim().charAt(0)}</Avatar>
                    }
                    description={
                      <Row justify="end">
                        <Col span={12}>
                          <Styled.Description ellipsis>
                            {`${employee?.firstName} ${employee?.lastName}`}
                          </Styled.Description>
                        </Col>
                        <Col span={12}>
                          <Row justify="center">
                            <Styled.Reason ellipsis>
                              {t(request.reason)}
                            </Styled.Reason>
                          </Row>
                        </Col>
                      </Row>
                    }
                  />
                </Skeleton>
              </Styled.Item>
            );
          }}
        />
      </WrapperOverFlow>
    </CardItem>
  );
}
