/* eslint-disable react/no-danger */
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { OpenPositionType } from 'interfaces/OpenPosition';
import {
  CopyFilled,
  CopyOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { StyledButton } from 'components/NewForms/FormStyled';
import { getOpenPositionById } from 'api/openRoutesService';
import { formatDate, toastErrorMessages } from 'utils/utilFunctions';
import { TenantDataType } from 'types/Tenant';
import CardItem from 'ui-v2/components/Card';
import { AvatarSizes } from 'pages/GuestPages/utils';
import { OpenPositionLogo } from 'pages/GuestPages/GuestPagesStyles';
import Content from 'ui-v2/components/Content';
import { ItemLabel } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TenantConfigAsyncActions } from 'redux/tenantConfig/actions';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';

type IProps = {
  tenant: TenantDataType;
  onToogleView: () => void;
};

const GuestOpenPositionDetails = ({ tenant, onToogleView }: IProps) => {
  const { data: tenantConfig } = useTenantConfigData();
  const [loading, setLoading] = useState(false);
  const [openPosition, setOpenPosition] = useState<OpenPositionType>();
  const [searchParams] = useSearchParams();
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const openpositionId = searchParams.get('openPositionId');

  const fetchOpenPositon = (openPositionId: string) => {
    setLoading(true);
    getOpenPositionById({ openPositionId, tenantId: tenant.id })
      .then((response) => {
        if (response.status === 201 && response?.data) {
          setOpenPosition(response.data);
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(TenantConfigAsyncActions.fetchCurrentTenantConfigurations());
    const openPositionId = searchParams.get('openPositionId');
    if (openPositionId) {
      fetchOpenPositon(openPositionId);
    }
  }, [searchParams, tenant.id]);

  const validity: string = openPosition?.noEndPeriod
    ? formatDate(openPosition?.validFrom)
    : `${formatDate(openPosition?.validFrom)} - ${formatDate(
        openPosition?.validTo
      )}`;

  const handleClick = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_API}open-routes/template/${tenantId}/${openpositionId}`;
      await navigator.clipboard.writeText(url);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  return (
    <Content position="center">
      <CardItem>
        <Row>
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col>
              <Button
                ghost
                type="link"
                icon={<LeftOutlined height={0.1} />}
                onClick={onToogleView}
              >
                {t('allJobs')}
              </Button>
            </Col>
            <Col>
              <Button
                ghost
                type="link"
                icon={isCopied ? <CopyFilled /> : <CopyOutlined />}
                onClick={handleClick}
              >
                {isCopied ? t('copied') : t('copyLink')}
              </Button>
            </Col>
          </Row>
          <Col span={24}>
            <Row justify="center">
              <Col span={24}>
                <Row justify="center">
                  {(openPosition?.image || tenantConfig?.logo) && (
                    <Col span={24}>
                      <Row justify="center" gutter={[0, 20]}>
                        <Col>
                          <OpenPositionLogo
                            size={AvatarSizes}
                            shape="square"
                            src={openPosition?.image ?? tenantConfig?.logo}
                          />
                        </Col>
                        <Col span={24} />
                      </Row>
                    </Col>
                  )}
                  <Col>
                    {loading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Typography.Title level={4}>
                        {openPosition?.name}
                      </Typography.Title>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col offset={2} span={20}>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <Typography.Title level={4}>
                  {t('About this opening')}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Row align="middle" gutter={[0, 10]}>
                  <Col span={24}>
                    <Row gutter={[5, 0]}>
                      <Col>
                        <FieldTimeOutlined />
                      </Col>
                      <Col>
                        <ItemLabel>{t('validTo:')}</ItemLabel>
                      </Col>
                      <Col>
                        <Typography.Text strong>{validity}</Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[5, 0]}>
                      <Col>
                        <EnvironmentOutlined />
                      </Col>
                      <Col>
                        <Typography.Text>{t('address:')}</Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text strong>
                          {`${tenant?.address}, ${tenant?.city}, ${tenant?.country}`}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Row gutter={[0, 20]}>
                  <Col span={24} />
                  <Col>
                    <Typography.Text>
                      {openPosition?.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: openPosition.description,
                          }}
                        />
                      )}
                    </Typography.Text>
                  </Col>
                  <Col span={24} />
                  <Col span={24}>
                    <Row justify="end" gutter={[20, 0]}>
                      <Col>
                        <StyledButton type="primary" size="large">
                          <Link
                            to={`/candidate-registration/${tenantId}?openPositionId=${openpositionId}`}
                          >
                            {t('Apply for this job')}
                          </Link>
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardItem>
    </Content>
  );
};

export default GuestOpenPositionDetails;
