import styled from 'styled-components';

export const SkillsetContainer = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;
  height: 70px;
  display: flex;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
    height: 0px !important;
    width: 0px !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
