import React, { useEffect } from 'react';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import { ShortListColumns } from 'table-configuration/SkillsetMatchingConfig';
import { fetchShortList } from 'redux/skillsetMatching/action';
import { useSkillsetMatchingData } from 'ui-v2/hooks/useSkillsetMatchingData';
import { StyledButton } from 'components/NewForms/FormStyled';
import { FilterByIcon } from 'Icons/FilterByIcon';
import { t } from 'i18next';
import { Popover } from 'antd';
import SkillsetMatchingFilter from 'ui-v2/components/TableFilterPopover/Filters/SkillsetMatchingFilter';
import { Title } from 'ui-v2/components/Card/CardStyles';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from '../../components/TableFilterPopover/TableFilterPopoverStyles';

export default function ShortList() {
  const { shortListData, shortListLoading, shortListPagination } =
    useSkillsetMatchingData();
  const [searchParams] = useSearchParams();
  const { take } = useHeader();
  const dispatch = useDispatch();

  useEffect(() => {
    const id: string = searchParams.get('id') as string;

    const params = {
      pageOptions: {
        page: 1,
        take,
      },
    };
    dispatch(fetchShortList(id, params));
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const id: string = searchParams.get('id') as string;
    const params = {
      pageOptions: {
        page,
        take,
      },
    };
    dispatch(fetchShortList(id, params));
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%', paddingTop: '40px' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 10,
          }}
        >
          <Popover
            overlayClassName="popover-content"
            overlayStyle={PopoverOverlayStyles}
            overlayInnerStyle={PopoverOverlayInnerStyles}
            content={<SkillsetMatchingFilter />}
            trigger="click"
          >
            <StyledButton id="filter-popover" icon={<FilterByIcon />}>
              {t('filterBy')}
            </StyledButton>
          </Popover>
        </div>
        <Content position="stretch">
          <>
            <Title>{t('shortList')}</Title>
            <DataGrid
              loading={shortListLoading}
              columns={ShortListColumns}
              data={shortListData}
              paginationName="Skillset"
              rowSelectionType="checkbox"
              totalItems={shortListPagination.itemCount}
              currentPage={shortListPagination.page}
              totalPages={shortListPagination.pageCount}
              onPageChange={onPageChange}
              multipleSelect={false}
            />
          </>
        </Content>
      </div>
    </>
  );
}
