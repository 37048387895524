import { Col, Form, Row } from 'antd';
import {
  createCandidateNotes,
  getOneCandidateNotes,
  updateCandidateNotes,
} from 'api/candidateNotesService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidate } from 'redux/candidates/actions';
import { CandidateNotesUpdateType } from 'types/CandidateNotes';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  id: string;
}

export default function AddCandidateNoteModal({
  open,
  closeModal,
  id,
}: IProps) {
  const [form] = Form.useForm();
  const { candidate } = useCandidateData();
  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState({
    note: '',
    isPublic: false,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNote = async () => {
      setLoading(true);
      try {
        const response = await getOneCandidateNotes(id);
        setNote(response.data);
      } catch (error) {
        console.error('Error fetching note:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchNote();
    }
  }, [candidate?.id]);

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'note',
          label: t('note'),
          type: 'input',
          defaultValue: note?.note,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'isPublic',
          label: t('isPublic'),
          defaultChecked: note?.isPublic,
          type: InputTypes.SWITCH,
        },
      ],
    ],
    [endDateDisabled, note]
  );

  const onSubmit = () => {
    if (loading || !candidate?.id) return;

    setLoading(true);
    const formValues: CandidateNotesUpdateType = {
      candidateId: candidate?.id,
      note: form.getFieldValue('note'),
      isPublic: form.getFieldValue('isPublic'),
    };

    if (id) {
      updateCandidateNotes(id, formValues)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(fetchCandidate(candidate?.id));
            toast.success(t('successfullyUpdatedNote'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createCandidateNotes({
        candidateId: candidate?.id,
        note: formValues?.note ?? '',
        isPublic: formValues?.isPublic ?? false,
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchCandidate(candidate?.id));
            toast.success(t('successfullyAddedNote'));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
          closeModal();
        });
    }
  };

  const onFieldsChange = (values: any) => {
    if (values[0]?.name[0] === 'present') {
      setEndDateDisabled((state) => !state);
    }
  };

  return (
    <GenericModal
      title={id ? t('editNote') : t('addNote')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
