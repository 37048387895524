/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton, Popover, Tooltip } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import moment from 'moment';
import { FileCVIcon } from 'Icons/FileCVIcon';
import { toast } from 'react-toastify';
import { ArrowDownIcon } from 'Icons/ArrowDownIcon';
import { IResume } from 'types/IResume';
import {
  getCandidatesResume,
  updateCandidateOpenPositionStage,
} from 'api/candidateService';
import { convertToKB, documentSizeInKb } from 'utils/utilFunctions';
import { Gender, GenderKey } from 'types/Candidates';
import {
  fetchCandidate,
  fetchOpenPositionStage,
  setSelectedOpenPosition,
} from 'redux/candidates/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import DropDown from '../DropDown';
import * as Styled from './PersonalInfoStyles';

export const Item = ({
  label,
  value,
  href,
  children,
}: {
  label: React.ReactNode | string;
  value?: any;
  href?: string;
  children?: React.ReactNode;
}) => (
  <Row style={{ rowGap: 0 }} gutter={[0, children ? 8 : 0]}>
    <Col span={24}>
      <Styled.ItemLabel>{label}</Styled.ItemLabel>
    </Col>
    <Col span={24}>
      <Styled.ItemValue as={href ? 'a' : ''} href={href} target="_blank">
        {value}
      </Styled.ItemValue>
      {children}
    </Col>
  </Row>
);

interface PersonalInfoProps {
  isDisabled: boolean;
}

export function FileItem({
  filename = 'Resume.pdf',
  filesize,
  filecontent,
  noActions,
  preview,
}: {
  filename?: string;
  filesize: string;
  filecontent: string | undefined;
  noActions?: boolean;
  preview?: boolean;
}) {
  return (
    <Styled.FileItemContainer noBorder={noActions}>
      <Col span={4}>
        <FileCVIcon />
      </Col>
      <Col span={14}>
        <Styled.FileItemName ellipsis={{ tooltip: true }}>
          {filename}
        </Styled.FileItemName>
        <Styled.FileItemSize>{convertToKB(filesize)}</Styled.FileItemSize>
      </Col>
      <Styled.Actions span={6} noActions={!preview && noActions}>
        <Row justify="space-around" align="top">
          <Styled.IconButton
            type="default"
            icon={<ArrowDownIcon />}
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              if (filecontent) {
                window.open(filecontent);
              }
            }}
          />
        </Row>
      </Styled.Actions>
    </Styled.FileItemContainer>
  );
}

export default function PersonalInfo({ isDisabled }: PersonalInfoProps) {
  const {
    candidate,
    candidateLoaded,
    candidateOffer,
    openPositionStage,
    selectedCandidateOpenPosition,
  } = useCandidateData();
  const [resume, setResume] = useState<IResume>();
  const { data: employeeSkimData } = useEmployeeSkimData();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (candidateLoaded && candidateOffer?.loaded) {
      dispatch(fetchOpenPositionStage());
    }
  }, [candidateLoaded, candidateOffer?.loaded]);

  const fetchResume = (id: string) => {
    getCandidatesResume(id)
      .then((response) => {
        if (response) {
          setResume(response.data);
        }
      })
      .catch(() => {
        console.log("Couldn't get candidate resume!");
      });
  };

  useEffect(() => {
    if (candidate?.id && candidateLoaded) {
      fetchResume(candidate.id);
    }
  }, [candidate?.id, candidateLoaded, candidateOffer?.loaded]);

  const onUpdateOpenPositionStage = (stageId: string) => {
    if (!selectedCandidateOpenPosition.data?.id || !candidate?.id) return;
    updateCandidateOpenPositionStage({
      candidateOpenPosId: selectedCandidateOpenPosition.data.id,
      stageId,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('openPositionStageChangedSuccessfully'));
          dispatch(
            setSelectedOpenPosition({
              ...res.data,
            })
          );
          dispatch(fetchCandidate(candidate.id));
        }
      })
      .catch(() => {
        toast.error(t('failedtoChangeOpenPositionStage'));
      });
  };

  // if (!candidate) return null;

  let referedBy = candidate?.referalType;
  if (candidate?.referalType === 'employee') {
    const referralEmployee = employeeSkimData?.filter(
      (e) => e.id === candidate?.referedBy
    );
    if (referralEmployee && referralEmployee?.length > 0) {
      referedBy = `${referralEmployee[0]?.firstName} ${referralEmployee[0]?.lastName}`;
    } else {
      referedBy = 'Employee';
    }
  }

  return (
    <CardItem
      disabled={isDisabled}
      title={t('personalInfo')}
      rightButton={
        <Row justify="end">
          {selectedCandidateOpenPosition?.data?.id && (
            <DropDown
              items={openPositionStage?.list.map((item) => ({
                label: t(item.name),
                id: item.id,
              }))}
              onChange={(stage) => {
                onUpdateOpenPositionStage(stage.id || '');
              }}
            >
              <>
                {`${t('stage')}: 
                ${t(selectedCandidateOpenPosition?.data?.stages?.name)}`}
              </>
            </DropDown>
          )}
        </Row>
      }
    >
      <Skeleton
        loading={!candidate}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <>
          <Row gutter={[0, 16]}>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('email')}
                  children={
                    <Styled.ItemValue ellipsis style={{ width: '80%' }}>
                      <Popover content={candidate?.email} placement="topLeft">
                        {candidate?.email}
                      </Popover>
                    </Styled.ItemValue>
                  }
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('phoneNumber')}
                  value={`${candidate?.phoneNumberPrefix} ${candidate?.phoneNumber}`}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('expectedSalary')}
                  value={
                    candidate?.currency?.symbol === 'ALL'
                      ? `${candidate?.expectedSalary
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                          candidate?.currency?.symbol
                        }`
                      : `${candidate?.expectedSalary} ${candidate?.currency?.symbol}`
                  }
                  children={undefined}
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('openPositions')}
                  value={candidate?.candidateOpenPositions
                    .map((i: any) => i.openPositions.name)
                    .join(', ')}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('age')}
                  value={moment()
                    .diff(candidate?.birthDate, 'years')
                    .toString()}
                  children={undefined}
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('gender')}
                  value={Gender[candidate?.gender as GenderKey]}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('source')}
                  value={referedBy || ''}
                  children={undefined}
                  href={
                    candidate?.referalType === 'employee'
                      ? `/employees/employee-profile?id=${candidate?.referedBy}`
                      : candidate?.referedBy
                  }
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Tooltip title={candidate?.linkedIn || ''}>
                  <Styled.Linkedin ellipsis>
                    <Item
                      label={candidate?.linkedIn ? 'LinkedIn' : ''}
                      value={candidate?.linkedIn || ''}
                      href={candidate?.linkedIn}
                      children={undefined}
                    />
                  </Styled.Linkedin>
                </Tooltip>
              </Col>
            </Styled.RowItem>
            <Styled.RowItem last disabled={false}>
              <Col
                md={{ span: 24 }}
                xs={{ span: 24 }}
                xl={{ span: 12 }}
                sm={{ span: 24 }}
              >
                <Item label="CV" value="">
                  <FileItem
                    filename={candidate?.resume as string}
                    filesize={documentSizeInKb()}
                    filecontent={resume?.content as string}
                  />
                </Item>
              </Col>
            </Styled.RowItem>
          </Row>
        </>
      </Skeleton>
    </CardItem>
  );
}

FileItem.defaultProps = {
  noActions: false,
};
