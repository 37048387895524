import { FeatureFlagsTenantType } from 'types/FeatureFlagsTenant';

export enum FeatureFlagsTenantAction {
  FETCH_ALL_FEATURE_FLAGS_TENANT_START = 'FETCH_ALL_FEATURE_FLAGS_TENANT_START',
  FETCH_ALL_FEATURE_FLAGS_TENANT_SUCCESS = 'FETCH_ALL_FEATURE_FLAGS_TENANT_SUCCESS',
  FETCH_ALL_FEATURE_FLAGS_TENANT_ERRORED = 'FETCH_ALL_FEATURE_FLAGS_TENANT_ERRORED',

  FETCH_PAGE_FEATURE_FLAGS_TENANT_START = 'FETCH_PAGE_FEATURE_FLAGS_TENANT_START',
  FETCH_PAGE_FEATURE_FLAGS_TENANT_SUCCESS = 'FETCH_PAGE_FEATURE_FLAGS_TENANT_SUCCESS',
  FETCH_PAGE_FEATURE_FLAGS_TENANT_ERRORED = 'FETCH_PAGE_FEATURE_FLAGS_TENANT_ERRORED',

  FETCH_FEATURE_FLAGS_TENANT_START = 'FETCH_FEATURE_FLAGS_TENANT_START',
  FETCH_FEATURE_FLAGS_TENANT_SUCCESS = 'FETCH_FEATURE_FLAGS_TENANT_SUCCESS',
  FETCH_FEATURE_FLAGS_TENANT_ERRORED = 'FETCH_FEATURE_FLAGS_TENANT_ERRORED',

  UPDATE_FEATURE_FLAGS_TENANT_START = 'UPDATE_FEATURE_FLAGS_TENANT_START',
  UPDATE_FEATURE_FLAGS_TENANT_SUCCESS = 'UPDATE_FEATURE_FLAGS_TENANT_SUCCESS',
  UPDATE_FEATURE_FLAGS_TENANT_ERRORED = 'UPDATE_FEATURE_FLAGS_TENANT_ERRORED',

  CLEAR_FEATURE_FLAGS_TENANT = 'CLEAR_FEATURE_FLAGS_TENANT',
}

export interface FeatureFlagsTenantState {
  data: Array<FeatureFlagsTenantType>;
  list: Array<FeatureFlagsTenantType>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
  updating: boolean;
  updated: boolean;
  updateErrored: boolean;
  updateError: any;
}
