import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PersonalInfo from 'ui-v2/components/PersonalInfo';
import Task from 'ui-v2/components/Task';
import Content from 'ui-v2/components/Content';
import WorkExperience from 'ui-v2/components/WorkExperience';
import Scoreboard from 'ui-v2/components/Scoreboard';
import CandidateDocuments from 'ui-v2/components/CandidateDocuments';
import SkillList from 'ui-v2/components/Skills';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import CandidateNotesComponent from 'ui-v2/components/CandidateNotesComponent/CandidateNotes';
import { CandidateNotesType } from 'types/CandidateNotes';
import { getCandidateNotes } from 'api/candidateNotesService';
import InterviewList from './InterviewList';
import OpenPositionsTabs from './OpenPositionsTabs/OpenPositionsTabs';
import CandidateOfferList from './CandidateOffer/CandidateOfferList/CandidateOfferList';

export default function CandidateProfile() {
  const { candidate } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useScreenSize();
  const [notes, setNotes] = useState<CandidateNotesType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNote = async () => {
      setLoading(true);
      try {
        const response = await getCandidateNotes(candidate?.id);
        setNotes(response.data);
      } catch (error) {
        console.error('Error fetching note:', error);
      } finally {
        setLoading(false);
      }
    };

    if (candidate?.id) {
      fetchNote();
    }
  }, [candidate]);

  useEffect(() => {
    if (searchParams.get('schedule') === 'true') {
      searchParams.delete('schedule');
      setSearchParams(searchParams);
    }
    if (searchParams.get('offer') === 'true') {
      searchParams.delete('offer');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const isCandidateHired = !!candidate?.employeeId;
  return (
    <Content position="stretch">
      <>
        <OpenPositionsTabs />
        <Content position={isMobile ? 'flexcol' : 'grid'}>
          <>
            <>
              <PersonalInfo isDisabled={isCandidateHired} />
              <WorkExperience isDisabled={isCandidateHired} />
              <SkillList
                isEmployeePortal={false}
                isDisabled={isCandidateHired}
              />
            </>
            <>
              <InterviewList isDisabled={isCandidateHired} />
              <Task isDisabled={isCandidateHired} />
              <Scoreboard isDisabled={isCandidateHired} />
              <CandidateOfferList isDisabled={isCandidateHired} />
              <CandidateDocuments isDisabled={isCandidateHired} />
              <CandidateNotesComponent
                notes={notes ?? []}
                candidateId={candidate?.id}
                loading={loading}
              />
            </>
          </>
        </Content>
      </>
    </Content>
  );
}
