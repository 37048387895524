import agent from 'api/agent';
import {
  CandidateNotesUpdateType,
  CreateCandidateNotesDTO,
} from 'types/CandidateNotes';
import { apiURLs } from './constants';

export const getAllCandidateNotes = () => agent.get(apiURLs.candidateNotes);

export const createCandidateNotes = (data: CreateCandidateNotesDTO) =>
  agent.post(`${apiURLs.candidateNotes}/create`, data);
export const getCandidateNotes = (id: string) =>
  agent.get(`${apiURLs.candidateNotes}/candidate/${id}`);
export const getOneCandidateNotes = async (id: string) =>
  agent.get(`${apiURLs.candidateNotes}/${id}`);

export const updateCandidateNotes = async (
  id: string,
  values: CandidateNotesUpdateType
) => agent.put(`${apiURLs.candidateNotes}/${id}`, values);

export const deleteCandidateNotes = async (id: string) =>
  agent.delete(`${apiURLs.candidateNotes}/${id}`);
