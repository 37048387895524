import { PageOptions } from 'types/QueryParams';
import agent from './agent';
import { apiURLs } from './constants';

export const getAllDashboardStats = () =>
  agent.get(`${apiURLs.dashboard}/stats`);

export const showDashboardPieStats = () =>
  agent.get(`${apiURLs.dashboard}/stats/pie`);

export const getAllDashboardBubbleStats = () =>
  agent.get(`${apiURLs.dashboard}/bubbleChart`);

export const getDashboardBubbleStatsCandidates = () =>
  agent.get(`${apiURLs.dashboard}/bubbleChartCandidates`);

export const getBarChartStats = () =>
  agent.get(`${apiURLs.dashboard}/stats/bar`);

export const getDashboardPieExpenseStats = () =>
  agent.get(`${apiURLs.dashboard}/expense/pie`);

export const getDashboardBarExpenseStats = () =>
  agent.get(`${apiURLs.dashboard}/expense/bar`);

export type DashboardAssetCountDTO = {
  purchaseDate?: string;
  location?: string;
  price?: number;
};

export const showDashboardAssetPieStats = (payload: DashboardAssetCountDTO) =>
  agent.post(`${apiURLs.asset}/getAssetsCountByStatus`, payload);

export const getHeatMap = (selectedData: string) =>
  agent.get(`${apiURLs.dashboard}/heatMap/${selectedData}`);

export const getHeadcount = (payload: PageOptions) =>
  agent.post(`${apiURLs.dashboard}/headcount`, payload);
