import React from 'react';
import { Col, Popconfirm, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'Icons/DeleteIcon';
import styled from 'styled-components';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import { CandidateNotesType } from 'types/CandidateNotes';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { AUTH_ROLES } from 'types/Auth';
import {
  ColItem,
  Note,
  NoteAvatar,
  NoteDate,
  NoteItemContainer,
  NoteName,
  PublicText,
} from '../../Notes/NoteItem/NoteItemStyled';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
const StyledCol = styled(Col)`
  margin-right: -20px;
`;
interface IProps {
  createdAt?: string;
  isLast: boolean;
  onDelete: () => void;
  item: CandidateNotesType;
  onItemClick: (id: string) => void;
}
export function NoteItemCandidate({
  createdAt,
  isLast,
  onDelete,
  item,
  onItemClick,
}: IProps) {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const handleItemClick = () => {
    onItemClick(item.id);
  };
  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };
  const { t } = useTranslation();
  return (
    <NoteItemContainer isLast={isLast}>
      <ColItem span={24} onClick={handleItemClick} isLast={isLast}>
        <Note gutter={[0, 4]} isLast>
          <Col span={24}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col span={21}>
                <NoteDate>{convertUTCtoFormatedLocalTime(createdAt)}</NoteDate>
              </Col>
              {authUserRole !== AUTH_ROLES.CANDIDATE && (
                <StyledCol span={3}>
                  <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                    <Popconfirm
                      placement="topLeft"
                      title={t('areYouSureYouWanttoDelete')}
                      okText={t('yes')}
                      cancelText={t('no')}
                      onConfirm={(e) => handleDeleteButtonClick(e)}
                    >
                      <IconButton onClick={(e) => e.stopPropagation()}>
                        <DeleteIcon />
                      </IconButton>
                    </Popconfirm>
                  </RoleBasedButton>
                </StyledCol>
              )}
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: '100%' }}
            >
              <Row>
                <NoteAvatar icon={<UserOutlined />} size={30} />
                <Row gutter={[0, 20]}>
                  <NoteName>{item.note}</NoteName>
                </Row>
              </Row>
              {authUserRole !== AUTH_ROLES.CANDIDATE && (
                <PublicText isPublic={item.isPublic ?? false}>
                  {item.isPublic ? t('Public') : t('Private')}
                </PublicText>
              )}
            </Row>
          </Col>
        </Note>
      </ColItem>
    </NoteItemContainer>
  );
}
