/* eslint-disable no-confusing-arrow */
/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  SelectStyled,
  StyledInput,
  StyledRate,
} from 'components/NewForms/FormStyled';
import { JobCategoryType } from 'types/JobCategory';
import styled from 'styled-components';

const { Option } = Select;
const { Title } = Typography;

const ResponsiveSpace = styled(Space)`
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  .ant-select {
    width: 210px;

    .ant-select-selection-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .ant-form-item {
      margin-bottom: 16px;
      width: 100%;
    }

    .ant-select {
      width: 100%;
    }

    .delete-button {
      align-self: flex-end;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .ant-form-item {
      margin-bottom: 16px;
      margin-right: 16px;
      flex: 1 1 auto;
    }

    .ant-form-item:last-child {
      margin-right: 0;
    }

    .years-input {
      width: 80px;
    }

    .score-input {
      flex: 0 0 auto;
    }
  }
`;

interface DynamicSkillsFormProps {
  form: FormInstance;
  listName: string;
  availableSkills?: JobCategoryType[] | undefined;
  defaultSkills?: any[];
  title?: string;
  hasYearsOfExperienceField?: boolean;
  hasScoreField?: boolean;
}

const SkillsDynamicForm: React.FC<DynamicSkillsFormProps> = ({
  listName,
  availableSkills,
  form,
  defaultSkills,
  title,
  hasYearsOfExperienceField = true,
  hasScoreField = true,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultSkills && !form.isFieldsTouched([listName])) {
      form.setFieldsValue({
        [listName]: defaultSkills,
      });
    }
  }, [defaultSkills, form, listName]);

  const getSelectedPairs = (listName: string) => {
    const values = form.getFieldsValue();
    let filteredValues = [];

    if (!values[listName]) filteredValues = [];

    if (values[listName]) {
      if (values['skills-matrix']) {
        filteredValues?.push(
          ...values['skills-matrix'].map((v: any) => ({
            categoryId: v?.categoryId?.value,
            subCategoryId: v?.subCategoryId?.value,
          }))
        );
      }

      if (values['all-skills']) {
        filteredValues?.push(
          ...values['all-skills'].map((v: any) => ({
            categoryId: v?.categoryId?.value,
            subCategoryId: v?.subCategoryId?.value,
          }))
        );
      }
    }

    return filteredValues;
  };

  const getAvailableCategories = (listName: string) => {
    const selectedPairs = getSelectedPairs(listName);

    const filteredCategories = availableSkills?.map((category) => ({
      ...category,
      skillSubCategory: category.skillSubCategory.filter(
        (sub) =>
          !selectedPairs.some(
            (pair: any) =>
              pair.categoryId === category.id && pair.subCategoryId === sub.id
          )
      ),
    }));

    return filteredCategories?.filter(
      (category) => category.skillSubCategory.length > 0
    );
  };

  const handleFieldChange = (
    listName: string,
    field: string,
    index: number,
    value: string
  ) => {
    const formValues = form.getFieldsValue();
    if (field === 'categoryId') {
      formValues[listName][index].subCategoryId = undefined;
    }
    formValues[listName][index][field] = value;
    form.setFieldsValue(formValues);
  };

  const renderFormList = (listName: string, title: string) => (
    <>
      <Title level={4} style={{ marginBottom: 16, fontSize: 18 }}>
        {title}
      </Title>
      <Form.List name={listName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <ResponsiveSpace key={field.key}>
                <Form.Item
                  {...field}
                  name={[field.name, 'categoryId']}
                  rules={[
                    { required: true, message: t('Please select category') },
                  ]}
                  style={{ paddingTop: '5px' }}
                >
                  <SelectStyled
                    placeholder={t('Select category')}
                    onChange={(value) =>
                      handleFieldChange(
                        listName,
                        'categoryId',
                        index,
                        value as string
                      )
                    }
                    labelInValue
                  >
                    {getAvailableCategories(listName)?.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.categoryName}
                      </Option>
                    ))}
                  </SelectStyled>
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, 'subCategoryId']}
                  rules={[
                    { required: true, message: t('Please select subcategory') },
                  ]}
                  style={{ paddingTop: '5px' }}
                >
                  <SelectStyled
                    placeholder={t('Select subcategory')}
                    onChange={(value) =>
                      handleFieldChange(
                        listName,
                        'subCategoryId',
                        index,
                        value as string
                      )
                    }
                    disabled={
                      !form.getFieldValue([listName, index, 'categoryId'])
                    }
                    labelInValue
                  >
                    {getAvailableCategories(listName)
                      ?.find(
                        (c) =>
                          c.id ===
                          form.getFieldValue([listName, index, 'categoryId'])
                            ?.value
                      )
                      ?.skillSubCategory.map((sub) => (
                        <Option
                          key={sub.id}
                          value={sub.id}
                          label={sub.subCategoryName}
                        >
                          {sub.subCategoryName}
                        </Option>
                      ))}
                  </SelectStyled>
                </Form.Item>

                {hasYearsOfExperienceField && (
                  <Form.Item
                    name={[field.name, 'yearsOfExperience']}
                    rules={[
                      {
                        required: true,
                        message: t('Please enter years of experience'),
                      },
                      {
                        validator: (_, value) =>
                          value >= 0.3
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  t('Minimum of 3 months (0.3) required')
                                )
                              ),
                      },
                    ]}
                    className="years-input"
                  >
                    <StyledInput
                      type="number"
                      min={0}
                      placeholder="Years"
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                        (e.target as HTMLInputElement).blur()
                      }
                    />
                  </Form.Item>
                )}

                {hasScoreField && (
                  <Form.Item
                    name={[field.name, 'score']}
                    rules={[
                      { required: true, message: t('Please enter a score!') },
                      {
                        validator: (_, value) =>
                          value >= 1
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(t('Minimum score of 1 required'))
                              ),
                      },
                    ]}
                    className="score-input"
                  >
                    <StyledRate />
                  </Form.Item>
                )}

                <MinusCircleOutlined
                  onClick={() => remove(field.name)}
                  className="delete-button"
                />
              </ResponsiveSpace>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                disabled={getAvailableCategories(listName)?.every(
                  (s) => s.skillSubCategory?.length === 0
                )}
                style={{ marginTop: 16 }}
              >
                {t('Add Skill')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );

  return <>{renderFormList(listName, t(title ?? ''))}</>;
};

export default SkillsDynamicForm;
