import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { fetchCandidates } from 'redux/candidates/actions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { CandidatesQueryParamsType } from 'types/QueryParams';
import {
  fetchOpenPositionOptions,
  fetchStages,
  fetchSubCategorySkills,
} from 'components/NewForms/SelectWithLoad/utils';

import i18next from '../../../../i18n';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

const CandidateStatus = [
  { label: 'New', value: 'new', id: 'new' },
  { label: 'Verify', value: 'verify', id: 'verify' },
  { label: 'Hired', value: 'hired', id: 'hired' },
  { label: 'Rejected', value: 'rejected', id: 'rejected' },
];

export default function CandidateFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();

  async function onFormChange() {
    const openPosition: string =
      form.getFieldValue('openPositionId')?.value ?? '';
    const generalStatus: string = form.getFieldValue('generalStatus') ?? '';
    const stages = form.getFieldValue('appliedPositionStage')?.value ?? '';
    const skillSubCategories = form.getFieldValue('skills') ?? '';
    const filterCandidatesTable: CandidatesQueryParamsType = {
      openPosition,
      stages,
      generalStatus,
      skillSubCategories,
    };
    dispatch(fetchCandidates(filterCandidatesTable));
  }

  const CandidateFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'openPositionId',
          label: i18next.t('openPosition'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchOpenPositionOptions,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'appliedPositionStage',
          label: i18next.t('appliedPositionStage'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchStages,
        },
      ],

      [
        {
          col: 24,
          offset: 0,
          name: 'generalStatus',
          label: i18next.t('generalStatus'),
          type: InputTypes.SELECT,
          selectOptions: CandidateStatus,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'skills',
          label: i18next.t('skills'),
          type: InputTypes.SELECT_LOAD,
          isMultiSelect: true,
          mode: 'multiple',
          fetchOptions: fetchSubCategorySkills,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={CandidateFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchCandidates());
            }}
          >
            {i18next.t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
