import React, { useMemo } from 'react';
import { Form, Select, Spin } from 'antd';
import { PrefixType, RulesType } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { FormItemStyled, PrefixInputStyle } from '../FormStyled';

const { Option } = Select;

interface Props {
  placeholder: string;
  selectOptions?: OptionType[];
  prefix?: PrefixType;
  name: string;
  rules?: RulesType[];
  inputProps?: {
    type: 'number' | 'email' | 'text' | 'password' | 'decimal';
    min?: number;
    max?: number;
  };
  hint?: React.ReactNode | string;
  loading?: boolean;
  prefixWidth?: number;
}

const PrefixInput: React.FC<Props> = ({
  placeholder,
  prefix,
  name,
  rules,
  inputProps,
  hint,
  loading,
}) => {
  const prefixSelector = useMemo(
    () => (
      <Form.Item name={prefix?.name} noStyle {...(hint && { tooltip: hint })}>
        {loading ? (
          <Spin size="small" />
        ) : (
          <Select
            showSearch
            defaultValue={prefix?.defaultValue}
            placeholder={prefix?.placeholder}
            dropdownStyle={{
              minWidth: 110,
            }}
            style={{
              width: prefix?.prefixWidth || 110,
              textAlign: 'center',
              fontSize: (prefix?.prefixWidth || 93) * 0.12,
            }}
            filterOption={(input, option) =>
              (option?.value as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {prefix?.selectOptions?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    ),
    [prefix?.selectOptions, loading]
  );

  return (
    <FormItemStyled
      name={name}
      label={placeholder}
      rules={rules}
      {...(hint && { tooltip: hint })}
    >
      <PrefixInputStyle
        placeholder={placeholder}
        addonBefore={prefixSelector}
        style={{ width: '100%' }}
        {...inputProps}
      />
    </FormItemStyled>
  );
};

PrefixInput.defaultProps = {
  selectOptions: [],
  prefix: {
    placeholder: '',
    selectOptions: [],
    name: '',
  },
  rules: [],
  inputProps: {
    type: 'text',
  },
};

export default PrefixInput;
