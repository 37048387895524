import React from 'react';
import { Link } from 'react-router-dom';
import { IconWrapper, StyledDrawerItem } from './SideBarMobileStyles';

interface ItemProp {
  item: any;
  active: any;
  grouped?: boolean;
  toggleDrawer: () => void;
}

export default function DrawerItem({
  item,
  active,
  toggleDrawer,
  grouped,
}: ItemProp) {
  return (
    <StyledDrawerItem active={active}>
      <IconWrapper grouped={grouped}>{item?.icon}</IconWrapper>
      <Link to={item.path} style={{ color: 'white' }} onClick={toggleDrawer}>
        {item?.name}
      </Link>
    </StyledDrawerItem>
  );
}
