import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { StyledSidebarInput } from './StyledSidebarSearch';

const DEBOUNCE_MILLISECONDS = 250;

interface SidebarSearchProps {
  onSearch?: (value: string) => void;
  elementRef: any;
  isMobile?: boolean;
}

const SidebarSearch: React.FC<SidebarSearchProps> = ({
  onSearch,
  elementRef,
  isMobile,
}) => {
  const { t } = useTranslation();

  const handleEvent = useCallback((e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === '/') {
      elementRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', { handleEvent });
    return () =>
      document.removeEventListener('keydown', {
        handleEvent,
      });
  }, []);

  const debounced = debounce((searchedValue: string) => {
    onSearch?.(searchedValue);
  }, DEBOUNCE_MILLISECONDS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounced(e.target.value);
  };

  return (
    <StyledSidebarInput
      placeholder={t('sidebarSearchPlaceholder')}
      onChange={onChange}
      ref={elementRef}
      prefix={<SearchOutlined />}
      isMobile={isMobile ?? false}
    />
  );
};

export default SidebarSearch;
