/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { InterviewsState } from 'redux/candidates/types';
import { RootState } from 'redux/store';
import { InterviewTask } from 'types/Task';
import CardItem from 'ui-v2/components/Card';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import TaskList from 'ui-v2/routes/CandidateProfile/TaskList';
import CandidateTaskModal from 'ui-v2/routes/CandidateProfile/CandidateTaskModal';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AUTH_ROLES } from 'types/Auth';
import * as Styled from './TaskStyles';
import { groupTasks } from './utils';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import { Link } from '../WorkExperience/WorkExperienceStyles';

interface TaskCardProps {
  isDisabled: boolean;
  authUserRole?: any;
}

export default function TaskCard({ isDisabled, authUserRole }: TaskCardProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const { loading: candidateLoading } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: { interviews },
    loading,
  } = useSelector((state: RootState) => state.interviews as InterviewsState);
  const { t } = useTranslation();

  const toogleModalVisible = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const tasks: Array<InterviewTask> = useMemo(
    () => groupTasks(interviews),
    [interviews]
  );

  const onAddToggle = () => {
    if (!interviews.length) {
      toast.error(t('First schedule an interview'));
      return;
    }
    toogleModalVisible();
  };
  const onTaskItemClick = (id: string) => {
    searchParams.append('taskId', id);
    setSearchParams(searchParams);
    toogleModalVisible();
  };

  const onModalClose = () => {
    searchParams.delete('taskId');
    setSearchParams(searchParams);
    toogleModalVisible();
  };

  const onAddInterview = () => {
    document.getElementById('schedule-interview-popover')?.click();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <CardItem title={t('task')} minHeight={100} disabled={isDisabled}>
      <Skeleton
        loading={loading || candidateLoading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          <Col span={24}>
            {!loading && tasks.length > 0 && (
              <TaskList list={tasks} onItemClick={onTaskItemClick} />
            )}
          </Col>
          {tasks.length === 0 && (
            <Col span={24}>
              <Row justify="center">
                <Styled.Label>
                  {t('assign a task to this candidate')}
                </Styled.Label>
              </Row>
            </Col>
          )}
          {!interviews?.length ? (
            <Col>
              <Link>
                {t('youNeedTo')}
                <Button
                  style={{ padding: '5px', fontStyle: 'italic' }}
                  type="link"
                  onClick={onAddInterview}
                >
                  {t('scheduleAnInterviewFirst.')}
                </Button>
              </Link>
            </Col>
          ) : (
            authUserRole !== AUTH_ROLES.CANDIDATE && (
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col span={24}>
                  <Row justify="center">
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={onAddToggle}
                      size="large"
                      style={{
                        borderRadius: '8px',
                        width: '100%',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '344054',
                      }}
                    >
                      {t('addtask')}
                    </Button>
                  </Row>
                </Col>
              </RoleBasedButton>
            )
          )}
        </Styled.RowItem>
        {modalVisible && <CandidateTaskModal closeModal={onModalClose} />}
      </Skeleton>
    </CardItem>
  );
}
