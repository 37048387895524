import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { t } from 'i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { removeFromShortlist } from 'api/candidateService';
import { useDispatch } from 'react-redux';
import { fetchShortList } from 'redux/skillsetMatching/action';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const ShortListActions = ({ row }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const onView = () => {
    if (row.employee !== null) {
      navigate(`/employees/employee-profile?id=${row?.employee?.id}`);
    } else {
      navigate(`/candidates/profile?id=${row?.candidate?.id}`);
    }
  };

  const onRemove = async () => {
    await removeFromShortlist(row?.id);
    dispatch(
      fetchShortList(searchParams.get('id') || '', {
        pageOptions: { page: 1, take: 10 },
      })
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('viewProfile')}>
                <IconButton onClick={onView}>
                  <ViewProfileIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('remove')}>
                <IconButton onClick={onRemove}>
                  <DeleteOutlined style={{ fontSize: '19px' }} />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};
export default ShortListActions;
