export const apiURLs = {
  openRoutes: '/open-routes',
  feed: '/feed',
  publicSuperAdmin: 'public-super-admin',
  authSuperAdminLogin: '/public-super-admin/login',
  tenants: '/tenants',
  tenantConfig: '/config',
  ipWhiteListing: '/ip-whitelisting',
  usersSetPassword: '/users/set-password',
  usersResendVerificationEmail: '/users/resend-verification-email',
  publicUsers: '/public-users',
  publicUsersCheckEmail: '/public-users/check-email',
  authLogin: '/auth/login',
  authMe: '/auth/me',
  roles: '/roles',
  skills: '/skills',
  files: '/files',
  vendor: '/vendor',
  knowledgeTransfer: '/knowledge-transfer',
  companies: '/companies', // is not in the new be
  candidates: '/candidates',
  candidatesNewEmployee: '/candidates/newEmployee',
  certifications: '/certifications',
  certificationsEmployees: '/certifications-employees',
  openPositions: '/open-positions',
  openPositionsSkillCategories: '/open-positions/skillCategories',
  department: '/department',
  departmentEmployees: '/department/employees',
  workPositionEmployees: '/department/work-positions/employees',
  workExperience: '/work-experience',
  departmentWorkPositions: '/department/work-positions',
  training: '/training',
  trainingStatus: '/training/status',
  employeesAttendingTraining: '/training/employees',
  employees: '/employees',
  vendorCategories: '/vendor-category',
  purchaseRequests: 'purchase-request',
  vendorQuestionnaire: '/vendor-questionnaire',
  employeesBulkUpload: '/employees/upload',
  getEmployeesBulkUploadDetails: '/employees/bulk/upload-details',
  project: '/project',
  goal: '/goal',
  goalFeedback: '/goal-feedback',
  tenantLocation: '/tenantLocation',
  projectEmployees: '/project/employees',
  interviewRankings: '/interviewRankings', // is not in the new be
  employeeNote: '/employee-note',
  emergencyContact: '/emergency-contact',
  employeeAsset: '/employee-asset',
  jobCategories: 'jobCategories', // is not in the new be
  workPositionById: '/work-position/',
  currency: '/currency',
  assignment: '/assignment',
  candidateDocuments: '/candidateDocuments',
  twoFactorAuthentication: '/2fa',
  tenantedTwoFactorAuthentication: '2fa-tenant',
  users: '/users',
  usersLang: '/users/lang',
  usersVerify: '/users/verify',
  asset: '/asset',
  assetAuditLog: '/assetAuditLog',
  interviews: '/interviews',
  interviewsCandidateOpenPosition: '/interviews/candidateOpenPosition',
  interviewsInterviewTypeOrder: '/interviews/interviewType/order',
  interviewTypes: '/interviewType',
  salaryHistory: '/salary-history',
  stages: '/stages',
  openPositionsAdmin: '/open-positions/admin',
  timeOff: '/timeOff',
  employeeDocument: '/employee-document',
  benefits: '/benefits',
  benefitTemplates: '/benefit-template',
  benefitTemplatePlans: '/benefit-template-plan',
  evaluation: '/evaluation',
  employeeTraining: '/employee-training',
  tasks: '/tasks',
  taskDocuments: '/task-documents',
  workPosition: '/work-position',
  projectDocuments: '/project-documents',
  candidateOffers: '/candidate-offers',
  onboardingTasks: '/onboarding-tasks',
  candidateOpenPositions: '/candidateOpenPositions',
  candidatesBulkUpload: '/candidates/upload',
  comments: '/comments',
  employeeTasks: '/employee-tasks',
  onBoarding: '/onboarding',
  skillsCategory: '/skills-category',
  skillsSubCategory: '/skills-sub-category',
  tackingHours: '/tracking-hours',
  request: '/request',
  employeeRequest: 'employee-request',
  overtimeTrackingHours: '/tracking-hours/overtimeHours',
  tracing: '/tracing',
  employeeEmployementTypes: '/employeeEmployementTypes',
  employmentType: '/employement-type',
  dashboard: '/dashboard',
  employeeDashboard: '/employee-dashboard',
  toApprove: '/toApprove',
  paymentDetails: '/payment-details',
  employee: '/employee',
  bonus: '/bonus',
  hrDuties: '/hrDuties',
  payrollRules: '/payroll-rules',
  payrollReport: '/payroll-report',
  questionnaires: '/questionnaires',
  questionnaireAssignment: '/questionnaire-assignment',
  reviewsSaveAnswers: '/reviewsSaveAnswers',
  notifications: 'notifications',
  listener: 'listener',
  assetCategories: 'asset-categories',
  assetSubCategory: 'asset-sub-category',
  purchaseOrder: '/purchase-order',
  invoice: '/invoice',
  employeeAttendance: 'employee-attendance',
  offboarding: '/offboarding',
  offboardingTask: '/offboarding-task',
  ideabox: '/ideabox',
  requestBenefits: '/request-benefits',
  expenses: '/expense-report',
  holidays: '/holidays',
  featureFlagsTenant: '/feature-flags-tenant',
  featureFlags: '/feature-flags',
  legalDaysOff: '/legal-days-off',
  candidateEmployeeRank: '/candidateEmployeeRank',
  tenantHierarchy: '/tenant-hierarchy',
  checkin: '/checkin',
  confiremdList: '/confirmed-list',
  candidate: '/candidate',
  candidateNotes: '/candidatenotes',
};
