import { Input } from 'antd';
import styled from 'styled-components';

export const StyledSidebarInput = styled(Input)<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    marginBottom: '1rem',
    backgroundColor: theme.colors.palette.primary.gray['800'],
    border: `1px solid ${theme.colors.palette.primary.gray['600']}`,
    borderRadius: '.25rem',
    input: {
      backgroundColor: 'inherit',
      color: 'white',
    },
    ...(isMobile && { width: '95%', marginLeft: '0.7rem' }),
    svg: {
      color: theme.colors.palette.primary.gray['400'],
      marginRight: '.5rem',
    },
  })
);

export const StyledSearchCode = styled.code(({ theme }) => ({
  backgroundColor: theme.colors.palette.primary.gray['600'],
  padding: '0 .2rem',
  borderRadius: '.3rem',
  color: theme.colors.palette.primary.gray['400'],
  fontSize: '.7rem',
}));
