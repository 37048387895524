import React from 'react';
import { Col, Typography } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { DextrioLogo, DextrioLogoText } from 'Icons/DextrioLogo';
import { useLocation } from 'react-router-dom';
import i18next from '../../../../i18n';
import {
  StyledHeaderLinkBtn,
  StyledHeaderLogoBtn,
  StyledHeaderRow,
} from './HeaderStyles';

const Header: React.FC = () => {
  const location = useLocation();

  return (
    <StyledHeaderRow
      align="middle"
      justify="space-around"
      gutter={[0, 10]}
      wrap
    >
      <Col>
        <StyledHeaderLogoBtn type="link" href="/login">
          <DextrioLogo />
          <DextrioLogoText />
        </StyledHeaderLogoBtn>
      </Col>
      <Col>
        {location.pathname.startsWith('/jobs') ? (
          <StyledHeaderLinkBtn type="link" href="/login">
            <UserOutlined />
            <Typography.Text>{i18next.t('login')}</Typography.Text>
          </StyledHeaderLinkBtn>
        ) : (
          <StyledHeaderLinkBtn type="link" href="/register-company">
            <MailOutlined />
            <Typography.Text>{i18next.t('registerCompany')}</Typography.Text>
          </StyledHeaderLinkBtn>
        )}
      </Col>
    </StyledHeaderRow>
  );
};

Header.defaultProps = {};
export default Header;
