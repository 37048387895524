/* eslint-disable no-confusing-arrow */
import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { fetchCandidateOptions } from 'components/NewForms/SelectWithLoad/utils';
import { addCandidateManualy } from 'api/candidateService';

interface IProp {
  open: boolean;
  closeModal: () => void;
  existingCandidates: any[];
  setSubmitting: any;
}

function AddCandidateModal({
  open,
  closeModal,
  existingCandidates,
  setSubmitting,
}: IProp) {
  const [form] = Form.useForm();
  const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  async function onFormFinish(values: any) {
    const valuesToSend: any = {
      candidateIds: values?.candidates,
    };

    setLoading(true);
    const res = await addCandidateManualy(
      searchParams.get('id') as string,
      valuesToSend
    );

    if (res.status === 200) {
      setLoading(false);
      setSubmitting((prev: any) => !prev);
      closeModal();
      toast.success(t('candidateSuccessfullyfAdded'));
    } else {
      toast.error(toastErrorMessages(res));
      closeModal();
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const all = await fetchCandidateOptions('', 1);

      const filteredCandidates = all?.data
        ?.filter(
          (candidate) =>
            !existingCandidates?.some((existing) =>
              existing?.candidate
                ? existing?.candidate?.id === candidate?.value
                : existing?.id === candidate?.value
            )
        )
        .map((candidate) => ({
          label: candidate.label,
          value: candidate.value,
          id: candidate.value,
        }));

      setFilteredCandidates(filteredCandidates);
    };
    fetchData();
  }, [existingCandidates]);

  const AddCandidateForm: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'candidates',
          label: t('candidates'),
          type: InputTypes.SELECT,
          isMultiSelect: true,
          mode: 'multiple',
          showSearch: true,
          selectOptions: filteredCandidates,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [filteredCandidates]
  );

  return (
    <GenericModal
      title={t('selectCandidates')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={AddCandidateForm}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default AddCandidateModal;
