import { BellOutlined } from '@ant-design/icons';
import React from 'react';

export default function NotificationFixed({ handleOpenModal }: any) {
  return (
    <button
      onClick={() => {
        handleOpenModal();
      }}
      type="button"
      style={{
        position: 'fixed',
        bottom: '40px',
        right: '25px',
        zIndex: '999',
        backgroundColor: '#101828',
        color: '#ccc',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
    >
      <div style={{ position: 'relative' }}>
        <BellOutlined style={{ fontSize: '24px' }} />
        <span
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '8px',
            height: '8px',
            backgroundColor: '#ff4d4f',
            borderRadius: '50%',
          }}
        />
      </div>
    </button>
  );
}
