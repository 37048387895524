import React, { useEffect } from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { WorkWeekType } from 'types/tracking';

interface MultiRangeDatePickerProps {
  selectedDates: string[];
  defaultDatesRange: boolean;
  onSelectedDatesChange: (dates: string[]) => void;
  workWeekType: WorkWeekType | undefined;
}

const getDatesBetween = (startDate: string, endDate: string): string[] => {
  const dates: string[] = [];
  let currentDate = moment(startDate);
  const end = moment(endDate);
  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'days');
  }
  return dates;
};

const MultiRangeDatePicker: React.FC<MultiRangeDatePickerProps> = ({
  defaultDatesRange,
  selectedDates,
  onSelectedDatesChange,
  workWeekType,
}) => {
  const today = moment().format('YYYY-MM-DD');
  const { t } = useTranslation();

  useEffect(() => {
    onSelectedDatesChange([]);
  }, [defaultDatesRange]);

  const momentDates: [moment.Moment | null, moment.Moment | null] = [
    selectedDates.length > 0 ? moment(selectedDates[0]) : null,
    selectedDates.length > 0
      ? moment(selectedDates[selectedDates.length - 1])
      : null,
  ];

  const handleChange = (
    dates: [moment.Moment | null, moment.Moment | null] | null
  ) => {
    if (!dates) {
      onSelectedDatesChange([]);
      return;
    }

    const startDate = dates[0] ? dates[0].format('YYYY-MM-DD') : today;
    const endDate = dates[1] ? dates[1].format('YYYY-MM-DD') : null;

    if (endDate) {
      const allDates = getDatesBetween(startDate, endDate);
      onSelectedDatesChange(allDates);
    } else {
      onSelectedDatesChange([startDate]);
    }
  };

  const disabledDate = (current: moment.Moment) => {
    const day = current.day();
    switch (workWeekType) {
      case 'mondayToFriday':
        return day === 0 || day === 6;
      case 'mondayToSaturday':
        return day === 0;
      case 'fullWeek':
        return false;
      default:
        return false;
    }
  };

  return (
    <Space
      direction="vertical"
      size={16}
      style={{ display: 'flex', marginTop: '16px' }}
    >
      <DatePicker.RangePicker
        key={defaultDatesRange.toString()}
        value={momentDates as [moment.Moment, moment.Moment]}
        onChange={handleChange}
        style={{ width: '320px', borderRadius: '8px' }}
        placeholder={[t('startDate'), t('endDate')]}
        disabledDate={disabledDate}
      />
    </Space>
  );
};

export default MultiRangeDatePicker;
