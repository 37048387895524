import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { InputTypes } from 'types/FormTypes';
import {
  convertBase64,
  convertDateToUTC,
  getUserDateFormat,
  isDuplicateEmailError,
  isOfUrlValid,
  toastErrorMessages,
} from 'utils/utilFunctions';
import moment from 'moment';
import GenericForm from 'components/NewForms/Form';
import { toast } from 'react-toastify';
import {
  registerEmployee,
  registerEmployeeFromScratch,
} from 'api/employeeService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllCandidates, getCandidate } from 'api/candidateService';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { Gender } from 'types/Candidates';
import { RcFile } from 'antd/lib/upload';
import { useForm } from 'antd/es/form/Form';
import { MartialStatus, PayType } from 'types/Employee';
import { useTranslation } from 'react-i18next';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';
import { DEFAULT_LANGUAGE } from 'utils/constants';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'redux/authUser/actions';
import { getAllTenantLocations } from 'api/tenantLocationService';
import { phoneNumberValidator, prefixSelector } from '../utils';

function CreateEmployeeForm() {
  const [form] = useForm();
  const [isHourlyRated, setIsHourlyRated] = useState(false);
  const [switched, setSwitched] = useState<boolean>(false);
  const [loading, isLoading] = useState<boolean>(false);
  const [noCandidates, setNoCandidates] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();
  const { filteredWorkPositions } = useWorkPositionsSkimData();
  const authUserData: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const authUser = authUserData?.authUser;
  const dispatch = useDispatch();
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});
  const [locations, setLocations] = useState<any>([]);
  const fetchLocations = () => {
    getAllTenantLocations()
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data || []);
        }
      })
      .catch(() => {
        toast.error(
          t("Something went wrong! Can't get locations for this tenant")
        );
      });
  };

  const { optionsType: employmentTypes } = useGetSelectOptions({
    type: 'employement-type',
  });
  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const { optionsType: certifications } = useGetSelectOptions({
    type: 'certifications',
  });

  async function fetchUserList(
    name: string,
    page: number
  ): Promise<SelectLoadPayload> {
    return getAllCandidates({ filterText: name, page }).then((response) => {
      setNoCandidates(response.data?.candidates?.length === 0);
      return {
        data: response.data?.data?.map((candidate: any) => ({
          label: `${candidate?.firstName} ${candidate?.lastName}`,
          value: candidate?.id,
        })),
        pageCount: response?.data?.meta?.pageCount || 1,
      };
    });
  }

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64 });
    return false;
  }

  function registerNewEmployee(valuesToSend: any) {
    registerEmployee(valuesToSend)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('Employee registered successfully!'));
          navigate(`/employees/employee-profile?id=${response.data?.id}`);
        } else {
          toast.error(response?.data?.error);
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => isLoading(false));
  }

  const onPayTypeSelect = useCallback(
    (selectedPayType: PayType) => {
      if (selectedPayType === PayType.HOURLY) {
        setIsHourlyRated(true);
      } else {
        setIsHourlyRated(false);
      }
    },
    [form]
  );

  useEffect(() => {
    dispatch(getAuthUser());
    fetchLocations();
  }, []);

  const FormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'candidate',
          label: t('Candidate'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchUserList,
          disabled: switched || noCandidates,
          rules: [
            {
              required: !switched && !noCandidates,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'switched',
          label: t('candidateOptions'),
          type: InputTypes.SWITCH,
          disabled: noCandidates,
        },
      ],
      switched || noCandidates
        ? [
            {
              col: 8,
              offset: 0,
              name: 'firstName',
              label: t('firstName'),
              type: 'input',
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 6,
              offset: 2,
              name: 'lastName',
              label: t('lastName'),
              type: InputTypes.INPUT,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 6,
              offset: 2,
              name: 'gender',
              label: t('gender'),
              type: 'select',
              selectOptions: Object.values(Gender).map((item: Gender) => ({
                id: item,
                value: item,
                label: item,
              })),
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
          ]
        : [],
      switched || noCandidates
        ? [
            {
              col: 11,
              offset: 0,
              name: 'email',
              label: t('emailAddress'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'email',
              },
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 11,
              offset: 2,
              name: 'birthDate',
              label: t('birthday'),
              type: 'datepicker',
              birthday: true,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
          ]
        : [],
      [
        {
          col: 11,
          offset: 0,
          name: 'workEmail',
          label: t('workEmail'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector?.map((country: any) => ({
              id: country.value,
              label: (
                <div>
                  <img
                    src={country.flag}
                    alt=""
                    style={{ width: 20, marginRight: 10 }}
                  />
                  {country.value}
                </div>
              ),
              value: country.value,
            })),
            defaultValue:
              authUser?.phoneNumberPrefix ||
              locations[0]?.countryCode ||
              prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'ssn',
          label: t('ssn'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'linkedIn',
          label: 'LinkedIn',
          type: InputTypes.INPUT,
        },
      ],
      [
        {
          col: 8,
          offset: 0,
          name: 'hireDate',
          label: t('hireDate'),
          type: InputTypes.DATEPICKER,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'onBoardingStartDate',
          label: t('onboardStart'),
          type: InputTypes.DATEPICKER,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'onBoardingEndDate',
          label: t('onboardEnd'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(
                form.getFieldValue('onBoardingStartDate')
              ),
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 8,
          offset: 0,
          name: 'employmentTypeId',
          label: t('employmentType'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: employmentTypes,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'employeeIdentifier',
          label: t('employeeCode'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'string',
            rows: 1,
            min: 0,
          },
        },
        {
          col: 6,
          offset: 2,
          name: 'martialStatus',
          label: t('martialStatus'),
          type: InputTypes.SELECT,
          selectOptions: Object.values(MartialStatus).map((value) => ({
            id: value,
            value,
            label: t(value),
          })),
          showSearch: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      isHourlyRated
        ? [
            {
              col: 24,
              offset: 0,
              name: 'payType',
              label: t('payType'),
              type: InputTypes.SELECT,
              selectOptions: Object.values(PayType).map((value) => ({
                id: value,
                value,
                label: t(value),
              })),
              onSelect: onPayTypeSelect,
              showSearch: true,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 11,
              offset: 0,
              name: 'hourlyRate',
              label: t('hourlyRate'),
              type: 'selectPrefix',
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
              inputProps: {
                type: 'number',
                rows: 1,
                min: 0,
              },
              prefix: {
                name: 'currencyId',
                selectOptions: currencyOptions,
                placeholder: '',
                defaultValue: currencyOptions[1]?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
            },
            {
              col: 11,
              offset: 2,
              name: 'maxWorkHoursPerMonth',
              label: t('maxworkhours/Month'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'number',
              },
              rules: [
                {
                  required: false,
                  message: t('fieldRequired'),
                },
              ],
            },
          ]
        : [
            {
              col: 24,
              offset: 0,
              name: 'payType',
              label: t('payType'),
              type: InputTypes.SELECT,
              selectOptions: Object.values(PayType).map((value) => ({
                id: value,
                value,
                label: t(value),
              })),
              onSelect: onPayTypeSelect,
              showSearch: true,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
          ],
      isHourlyRated
        ? []
        : [
            {
              col: 8,
              offset: 0,
              name: 'salary',
              label: t('grossSalary'),
              type: 'selectPrefix',
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
              inputProps: {
                type: 'number',
                rows: 1,
                min: 0,
              },
              prefix: {
                name: 'currencyId',
                selectOptions: currencyOptions,
                placeholder: '',
                defaultValue: currencyOptions[1]?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
            },
            {
              col: 6,
              offset: 2,
              name: 'salaryNeto',
              label: t('netSalary'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'number',
              },
              rules: [
                {
                  required: false,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 6,
              offset: 2,
              name: 'maxWorkHoursPerMonth',
              label: t('maxworkhours/Month'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'number',
              },
              rules: [
                {
                  required: false,
                  message: t('fieldRequired'),
                },
              ],
            },
          ],
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: filteredWorkPositions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      switched || noCandidates
        ? [
            {
              col: 24,
              offset: 0,
              name: 'certifications',
              label: t('certifications'),
              type: 'select',
              selectOptions: certifications,
              isMultiSelect: true,
            },
          ]
        : [],
      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      switched || noCandidates
        ? [
            {
              col: 24,
              offset: 0,
              name: 'resume',
              type: 'upload',
              label: t('uploadCV'),
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
                {
                  validator: (
                    _: any,
                    value: { file: RcFile; fileList: Array<RcFile> }
                  ) => {
                    if (value?.fileList?.length > 0) {
                      return Promise.resolve();
                    }
                    window.scrollTo(0, document.body.scrollHeight);
                    return Promise.reject();
                  },
                },
              ],
              uploadProps: {
                accept: ".pdf,'data:application/pdf;base64,'",
                beforeUpload: (file: RcFile) => uploadAction(file),
                maxCount: 1,
                listType: 'picture',
              },
            },
          ]
        : [],
    ],
    [
      switched,
      employmentTypes,
      currencyOptions,
      certifications,
      filteredWorkPositions,
      noCandidates,
      isHourlyRated,
      fetchTenantLocationsOptions,
    ]
  );

  function onFormFinish() {
    const values = form.getFieldsValue(true);
    if (values?.salary < 0 || values?.hourlyRate < 0) {
      toast.warning(t('pleaseEnterAValidSalary!'));
      isLoading(false);
      return;
    }
    if (values?.hourlyRate) {
      delete values?.salary;
      delete values?.salaryNeto;
    }
    let formValues = values;
    delete formValues?.currency;
    formValues = {
      ...formValues,
      onBoardingStatus:
        employmentTypes.find((e) => e.id === formValues.employmentTypeId)
          ?.label || '',
      onBoardingStartDate: convertDateToUTC(formValues.onBoardingStartDate),
      onBoardingEndDate: convertDateToUTC(formValues.onBoardingEndDate),
      startDate: formValues.onBoardingStartDate,
      endDate: formValues.onBoardingEndDate,
      ...(formValues?.hourlyRate
        ? {
            hourlyRate: Number(values.hourlyRate),
          }
        : {
            salary: Number(values.salary),
            salaryNeto: Number(values.salaryNeto),
          }),
      maxWorkHoursPerMonth: Number(values.maxWorkHoursPerMonth),
      sendingAccountDetails: values.email,
      martialStatus: values.martialStatus,
      employeeIdentifier: values.employeeIdentifier,
      workPositionId: formValues.workPositionId.value,
      tenantLocationId: formValues.tenantLocationId.value,
      sendAccountEmail: true,
      lang: DEFAULT_LANGUAGE,
      phoneNumberPrefix: formValues?.phoneNumberPrefix || prefixSelector[0].id,
    };

    delete formValues.candidate;
    delete formValues.switched;

    if (
      !formValues.linkedIn ||
      String(formValues.linkedIn).replace(/\s/g, '').length === 0
    ) {
      delete formValues.linkedIn;
    } else if (!isOfUrlValid(formValues.linkedIn)) {
      toast.warning(t('LinkedIn URL is not valid!'));
      return;
    } else if (isDuplicateEmailError(formValues.email)) {
      toast.warning(t('Email Already Exists'));
      return;
    }
    isLoading(true);
    try {
      if (switched || noCandidates) {
        formValues = {
          ...formValues,
          birthDate: convertDateToUTC(values.birthDate),
          resume: {
            name: fileToUpload.name || '',
            content: fileToUpload.content?.split(',').pop() || '',
          },
          onBoardingStatus:
            employmentTypes.find((e) => e.id === formValues.employmentTypeId)
              ?.label || '',
          gender: formValues.gender.charAt(0),
          startDate: formValues.onBoardingStartDate,
          workPositionId: values.workPositionId,
          tenantLocationId: values.tenantLocationId?.value,
          endDate: formValues.onBoardingEndDate,
          sendAccountEmail: true,
          lang: DEFAULT_LANGUAGE,
          phoneNumberPrefix:
            formValues?.phoneNumberPrefix || prefixSelector[0].id,
        };
        if (!fileToUpload.content) {
          delete formValues.resume;
        }

        registerEmployeeFromScratch({ ...formValues })
          .then((response) => {
            if (response.status === 200 && response.data) {
              toast.success(t('Employee registered successfully!'));
              navigate(`/employees/employee-profile?id=${response.data?.id}`);
            } else {
              toast.error(response.data.error);
            }
          })
          .catch((error) => {
            toastErrorMessages(error);
          })
          .finally(() => isLoading(false));
      } else {
        getCandidate(values.candidate?.value)
          .then((response) => {
            if (response.status === 200) {
              registerNewEmployee({
                ...response.data,
                ...formValues,
                resume: {
                  name: '',
                  content: '',
                },
                gender: String(response.data.gender),
                sendingAccountDetails: response?.data?.email,
                lang: DEFAULT_LANGUAGE,
              });
            } else {
              toast.error(response.data.error);
            }
          })
          .catch((error) => {
            toastErrorMessages(error);
          })
          .finally(() => isLoading(false));
      }
    } catch (error) {
      toast.error(t('errorDuringThisAction'));
      isLoading(false);
    }
  }

  const onFieldChange = () => {
    setSwitched(form.getFieldValue('switched'));
  };

  useEffect(() => {
    if (searchParams.get('add-employee-save') === 'true') {
      searchParams.delete('add-employee-save');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-employee-save') === 'true') {
      searchParams.delete('edit-employee-save');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <GenericForm
      formConfiguration={FormConfiguration}
      onFinish={onFormFinish}
      onFieldsChange={onFieldChange}
      form={form}
      loading={loading}
    />
  );
}

export default CreateEmployeeForm;
