import React, { useEffect, useState } from 'react';

import { Button, Col } from 'antd';
import SelectComponent from 'components/NewForms/Select';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getFeatureFlags } from 'api/featureFlagService';
import { createFeatureFlagsTenant } from 'api/featureFlagsTenantService';
import { useDispatch } from 'react-redux';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';
import { fetchFeatureFlagTenantsPagination } from 'redux/featureFlagsTenant/action';
import * as Styles from './TenantFeatureComponentStyles';

export default function TenantFeatureComponent() {
  const { list } = useTenantFeatureData();
  const [searchParams] = useSearchParams();
  const [features, setFeatures] = useState<any>();
  const [selectedFeature, setSelectedFeature] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    getFeatureFlags().then((response) => {
      const filteredFeatures = response.data.data.filter(
        (feature: any) =>
          !list?.some(
            (tenantFeature: any) => tenantFeature.featureFlag.id === feature.id
          )
      );
      setFeatures(filteredFeatures);
    });
  }, [list]);

  const onSelect = (value: string) => {
    setSelectedFeature(value);
  };
  const onSubmit = async () => {
    if (!selectedFeature) {
      return;
    }

    if (!searchParams.get('id')) {
      return;
    }
    createFeatureFlagsTenant({
      featureId: selectedFeature,
      tenantId: searchParams.get('id') || '',
      status: false,
    })
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            fetchFeatureFlagTenantsPagination(searchParams.get('id') || '', {
              pageOptions: { page: 1, take: 10 },
            })
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const capital = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  const { t } = useTranslation();
  return (
    <Styles.Container gutter={[0, 10]}>
      <Styles.Container align="middle">
        {searchParams.get('id') !== null && searchParams.get('id') !== '' ? (
          <Col span={24}>
            <SelectComponent
              placeholder={t('records')}
              name="records"
              showSearch
              defaultValue="Features"
              onSelect={onSelect}
              selectOptions={
                features
                  ? features.map((feature: any) => ({
                      label: capital(feature.featureName),
                      value: feature.id,
                      id: feature.id,
                    }))
                  : []
              }
            />
            <Button onClick={onSubmit}>Add</Button>
          </Col>
        ) : (
          <Col span={24}>Select a tenant to add features.</Col>
        )}
      </Styles.Container>
    </Styles.Container>
  );
}
