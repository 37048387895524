import { Avatar, Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const EmergencyContactContainer = styled.div<{ isLast: boolean }>`
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  padding: 0 0 10px 0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;

export const StyledText = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 600 !important;
`;

export const Name = styled(Typography.Text)`
  font-weight: bold;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-top: 3.5px;
`;
export const AvatarStyle = styled(Avatar)`
  &.ant-avatar {
    width: 100px;
    font-size: 100%;
    color: #004fed;
    background-color: #dee9ff;
  }
`;

export const StyledRow = styled(Row)<{ isLast: boolean }>`
  cursor: pointer;
  @media (max-width: 1550px) {
    gap: 15px;
  }
`;

export const StyledCol = styled(Col)<{ hasSkills?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ hasSkills }) =>
    !hasSkills &&
    `
    align-items: center;
  `}
`;
