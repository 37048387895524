import { Col, Row, Switch } from 'antd';
import { updateFeatureFlagsTenants } from 'api/featureFlagsTenantService';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFeatureFlagTenantsPagination } from 'redux/featureFlagsTenant/action';

interface FeatureFlagsProps {
  row: any;
}

const FeatureFlagActions: React.FC<FeatureFlagsProps> = ({ row }) => {
  const [isOn, setIsOn] = useState(row.status);
  const dispatch = useDispatch();

  const onChange = () => {
    setIsOn((prev: boolean) => !prev);
    updateFeatureFlagsTenants({
      tenantId: row.tenantId,
      featureId: row.featureFlag.id,
      status: !isOn,
    }).then((res) => {
      if (res.status === 200) {
        dispatch(
          fetchFeatureFlagTenantsPagination(row.tenantId, {
            pageOptions: { page: 1, take: 10 },
          })
        );
      }
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Switch checked={row.status} onChange={onChange} />
        </Row>
      </Col>
    </Row>
  );
};

export default FeatureFlagActions;
