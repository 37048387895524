import { Col, Row, Spin } from 'antd';
import {
  deleteSkillSubCategory,
  getSkillsForCategories,
} from 'api/skillService';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SkillSubCategory } from 'types/Skills';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import RightSection from 'ui-v2/components/RightSection';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchCandidates } from 'redux/candidates/actions';
import { SkillSubCategoryItem } from './SkillSubCategoryItem';
import SkillSubCategoryForm from './SkillSubCategoryForm';

export default function SkillCategoriesDetails() {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SkillSubCategory>();
  const [subCategories, setSubCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onToggleModal = () => setModalVisible((state) => !state);
  const { t } = useTranslation();

  const onOpenModal = () => {
    onToggleModal();
    setSelectedSubCategory(undefined);
  };

  const onCandidates = (id: string, name: string) => {
    dispatch(fetchCandidates({ skillSubCategories: [{ value: id }] }));
    navigate(`/candidates?subskill=${name}`);
  };

  const id = searchParams.get('id');

  const fetchSubCategories = async (skillCategoryId: string | null) => {
    if (!skillCategoryId) return;
    setIsLoading(true);
    try {
      const response = await getSkillsForCategories(skillCategoryId as any);
      if (response.status === 200) {
        setSubCategories(response.data?.subCategories || []);
      }
    } catch {
      toast.error(
        t("Something went wrong! Can't get skills for this category")
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSubCategories(id);
    }
  }, [id]);

  const onEdit = (subCategory: SkillSubCategory) => {
    setSelectedSubCategory(subCategory);
    setModalVisible(true);
  };

  const onDelete = async (subCategoryId: string) => {
    try {
      const response = await deleteSkillSubCategory(subCategoryId);
      if (response.status === 200) {
        toast.success(t('successfullyDeletedSkill!'));
        fetchSubCategories(id);
        searchParams.append('deleted-sub-category', 'true');
        setSearchParams(searchParams);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  useEffect(() => {
    if (
      searchParams.get('deleted-sub-category') === 'true' ||
      searchParams.get('added-sub-category') === 'true' ||
      searchParams.get('edited-sub-category') === 'true'
    ) {
      fetchSubCategories(id);
      searchParams.delete('deleted-sub-category');
      searchParams.delete('added-sub-category');
      searchParams.delete('edited-sub-category');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Content position="center">
      <CardItem
        title={t('skills')}
        rightButton={
          <RightSection
            buttonCallBack={() => onOpenModal()}
            buttonText={t('addSkill')}
          />
        }
      >
        <Row gutter={[0, 24]} justify="center">
          <Styled.RowItem gutter={[0, 10]} last>
            <Col
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
            >
              {isLoading ? (
                <Row justify="center">
                  <Spin />
                </Row>
              ) : (
                <>
                  {!subCategories.length && (
                    <Row justify="center">
                      {t('thereAreNoSkillsRelatedtoThisCategory')}
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Styled.RowItem>

          {!isLoading &&
            subCategories.map((subCategory: SkillSubCategory) => (
              <SkillSubCategoryItem
                key={subCategory.id}
                name={subCategory.subCategoryName}
                onEdit={() => onEdit(subCategory)}
                onDelete={() => onDelete(subCategory.id)}
                onCandidates={() =>
                  onCandidates(subCategory.id, subCategory.subCategoryName)
                }
              />
            ))}

          {modalVisible && (
            <SkillSubCategoryForm
              open={modalVisible}
              closeModal={onToggleModal}
              selectedSubCategory={selectedSubCategory}
              skillCategoryId={id}
              fetchSubCategories={fetchSubCategories}
            />
          )}
        </Row>
      </CardItem>
    </Content>
  );
}
