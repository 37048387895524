import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import {
  SkillsetMatching,
  SkillsetMatchingTypes,
} from 'types/SkillsetMatching';
import { t } from 'i18next';
import { UserAddOutlined } from '@ant-design/icons';
import { addCandidateInShortList } from 'api/candidateService';
import { toast } from 'react-toastify';
import { ShortListDto } from 'types/Candidates';
import { useDispatch } from 'react-redux';
import { fetchShortList } from 'redux/skillsetMatching/action';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface IProps {
  row: SkillsetMatching;
}

const SkillsetMatchingActionsComponent: React.FC<IProps> = ({ row }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const onView = () => {
    if (row?.type === SkillsetMatchingTypes.EMPLOYEE) {
      navigate(`/employees/employee-profile?id=${row?.id}`);
    } else {
      navigate(`/candidates/profile?id=${row?.id}`);
    }
  };

  const addToShortlist = async () => {
    try {
      const payload: ShortListDto = {
        openPositionId: searchParams.get('id') as string,
        ...(row.type === 'employee'
          ? { employeeId: row.id }
          : { candidateId: row.id }),
      };

      await addCandidateInShortList(payload);
      toast.success(t('successfullyAddedToShortlist'));
      dispatch(
        fetchShortList(searchParams.get('id') || '', {
          pageOptions: { page: 1, take: 10 },
        })
      );
    } catch (error) {
      if ((error as any).response?.status === 409) {
        toast.warning(t('hasAlreadyBeenAddedToShortlist'));
      } else {
        console.error(error);
      }
    }
  };
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('viewProfile')}>
                <IconButton onClick={onView}>
                  <ViewProfileIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('addToShortlist')}>
                <IconButton onClick={addToShortlist}>
                  <UserAddOutlined style={{ fontSize: '18px' }} />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default SkillsetMatchingActionsComponent;
