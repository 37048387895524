import { Dropdown } from 'antd';
import React, { useMemo, useState } from 'react';
import { Notification } from 'types/Notification';
import {
  clearAllNotifications,
  deleteNotification,
  markAsRead,
} from 'api/notificationService';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNotificationsData } from 'ui-v2/hooks/useNotificationsData';
import { fetchNotifications } from 'redux/notifications/actions';
import EmployeeReview from 'components/Reviews/EmployeeReviewModal';
import { VerticalDots } from 'Icons/VerticalDots';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  ActionButton,
  Actions,
  ClearButton,
  NotificationActions,
  NotificationContent,
  NotificationCreatedTime,
  NotificationDescription,
  NotificationDetails,
  NotificationHeader,
  NotificationTitle,
  ReviewNotification,
} from '../NotificationStyles';

interface IProps {
  loaded: boolean;
  closeModal?: () => void;
}
export default function NotificationsList({ loaded, closeModal }: IProps) {
  const navigate = useNavigate();
  const [error, setError] = useState<any>('');
  const { t } = useTranslation();
  const { data: notifications } = useNotificationsData();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [employeeIds, setEmployeeIds] = useState<Array<string>>([]);

  async function markAsReadNotification(id: string | null) {
    if (!id) return;
    try {
      await markAsRead(id)
        .then(() => {
          dispatch(fetchNotifications());
        })
        .catch((err: any) => {
          setError(err);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function markAsReadMultipleNotifications(ids: string[]) {
    if (!ids.length) return;

    try {
      const markAsReadPromises = ids.map((id) => markAsRead(id));

      await Promise.all(markAsReadPromises)
        .then(() => {
          dispatch(fetchNotifications());
        })
        .catch((err: any) => {
          setError(err);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteNotificationById(id: string | null) {
    if (!id) return;
    deleteNotification(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchNotifications());
        }
      })
      .catch((err: any) => {
        setError(err);
        console.log(error);
      });
  }
  const onToggleModal = () => setModalVisible((state) => !state);

  async function clearNotifications() {
    try {
      await clearAllNotifications()
        .then((response) => {
          dispatch(fetchNotifications());
          console.log(response);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const notificationId = useMemo(
    () => notifications.map((notification: Notification) => notification.id),
    [notifications]
  );

  return (
    <>
      <NotificationHeader>
        {notifications.length !== 0 ? (
          <div style={{ display: 'flex', gap: '10px' }}>
            <ClearButton
              onClick={() => markAsReadMultipleNotifications(notificationId)}
            >
              {t('markAllAsRead')}
            </ClearButton>
            <ClearButton onClick={clearNotifications}>
              {t('clearNotifications')}
            </ClearButton>
          </div>
        ) : (
          ''
        )}
      </NotificationHeader>
      <ReviewNotification onClick={onToggleModal}>
        Tap to review
      </ReviewNotification>
      {notifications.length > 0 &&
        notifications.map((notification: Notification) => (
          <NotificationDetails
            isRead={notification.isRead}
            key={notification.id}
            onClick={
              notification?.information
                ? () => {
                    try {
                      const parsedInformation = JSON.parse(
                        notification.information as string
                      );
                      if (parsedInformation.link) {
                        closeModal?.();
                        navigate(`/${parsedInformation.link}`);
                      }
                      if (parsedInformation.employeeReviews) {
                        setModalVisible(true);
                        setEmployeeIds(parsedInformation.employeeReviews);
                      }
                    } catch (error) {
                      console.error('Error parsing JSON:', error);
                    }
                  }
                : undefined
            }
          >
            <NotificationContent>
              <NotificationTitle>
                {' '}
                {notification.title.replace(/\s+!/g, '!')}
              </NotificationTitle>
              <NotificationDescription>
                {' '}
                {notification?.content}
              </NotificationDescription>

              <NotificationCreatedTime>
                {moment(notification.createdAt).calendar()}
              </NotificationCreatedTime>
            </NotificationContent>
            <NotificationActions onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Actions>
                    {!notification.isRead && (
                      <ActionButton
                        onClick={() => markAsReadNotification(notification.id)}
                      >
                        {t('markAsRead')}
                      </ActionButton>
                    )}
                    <ActionButton
                      onClick={() => deleteNotificationById(notification.id)}
                    >
                      {t('delete')}
                    </ActionButton>
                  </Actions>
                }
                trigger={['click']}
                placement="bottomLeft"
              >
                <a href="/#">
                  <VerticalDots />
                </a>
              </Dropdown>
            </NotificationActions>
          </NotificationDetails>
        ))}
      {loaded && notifications.length === 0 && (
        <NotificationContent>
          <NotificationDetails>
            {`${t('noNotificationsYet')}!`}
          </NotificationDetails>
        </NotificationContent>
      )}
      {modalVisible && (
        <EmployeeReview
          open={modalVisible}
          employeeIds={employeeIds}
          closeModal={() => setModalVisible(false)}
        />
      )}
    </>
  );
}
