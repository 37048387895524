import {
  Avatar,
  Col,
  List,
  Popconfirm,
  Row,
  Skeleton,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { EmployeeTimeOffRequest } from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import { IconButton } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import TrashIcon from 'Icons/TrashIcon';

import { useTranslation } from 'react-i18next';
import {
  getStatusColor,
  getStatusStyles,
} from 'ui-v2/components/Project/ProjectTimeOffs/ProjectTimeOffs';
import * as Styled from './RequestListStyles';

export default function RequestList({
  employeeTimeOffList,
  loading,
  onDeleteRequest,
  currentPage,
  pageSize,
  onChange,
  total,
}: {
  employeeTimeOffList: any;
  loading: any;
  onDeleteRequest: (timeOffId: string) => void;
  currentPage: number;
  pageSize: number;
  onChange: (page: number, size: number) => void;
  total: number;
}) {
  const { t } = useTranslation();

  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
    onChange,
  };

  const parseDates = (dates: string) => {
    if (dates) {
      try {
        return JSON.parse(dates);
      } catch (error) {
        console.error('Error parsing dates:', error);
        return [];
      }
    }
    return [];
  };

  const formatDateRange = (dateArray: { date: string }[]) => {
    if (!dateArray || dateArray.length === 0) return '';

    const sortedDates = dateArray
      .map((d) => moment(d.date))
      .sort((a, b) => a.diff(b));

    const startDate = sortedDates[0];
    const endDate = sortedDates[sortedDates.length - 1];

    if (startDate.isSame(endDate, 'day')) {
      return startDate.format('DD/MM/YYYY');
    }

    return `${startDate.format('DD/MM/YYYY')} - ${endDate.format(
      'DD/MM/YYYY'
    )}`;
  };

  return (
    <CardItem title={t('yourRequests')} wrapContentHeight>
      <List
        loading={loading}
        itemLayout="horizontal"
        style={{ width: '100%' }}
        dataSource={employeeTimeOffList}
        pagination={paginationProps}
        renderItem={(request: EmployeeTimeOffRequest, index: number) => (
          <Skeleton avatar title={false} loading={loading} active>
            <Styled.Item
              align="middle"
              last={index === employeeTimeOffList.length - 1}
            >
              <Col span={24}>
                <Row>
                  <Styled.Reason>{request.reason}</Styled.Reason>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={11}>
                    {formatDateRange(parseDates(request.dates))}
                  </Col>
                  <Col span={24}>
                    <Row justify="space-between" align="middle">
                      <Col xs={10} sm={8} lg={6}>
                        <Avatar.Group>
                          {request?.approver?.map((approver) => (
                            <Tooltip
                              title={`${approver?.employee?.firstName} ${approver?.employee?.lastName}`}
                              placement="top"
                            >
                              <Avatar style={getStatusStyles(approver?.action)}>
                                {approver?.employee?.firstName
                                  ?.trim()
                                  .charAt(0)}
                              </Avatar>
                            </Tooltip>
                          ))}
                        </Avatar.Group>
                      </Col>

                      <Col xs={8} sm={8} lg={6}>
                        <Tag
                          color={getStatusColor(request.aprovalStatus)}
                          style={{ borderRadius: '8px' }}
                        >
                          {request.aprovalStatus}
                        </Tag>
                      </Col>

                      <Col xs={6} sm={4} lg={2}>
                        <Popconfirm
                          placement="topLeft"
                          title={t('areYouSureYouWanttoDelete')}
                          onConfirm={() => onDeleteRequest(request.id)}
                          okText={t('yes')}
                          cancelText={t('no')}
                        >
                          <IconButton>
                            <TrashIcon />
                          </IconButton>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Styled.Item>
          </Skeleton>
        )}
      />
    </CardItem>
  );
}
