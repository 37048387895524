import { CurrencyType } from './Candidates';
import { LegalDaysOff } from './LegalDaysOff';

export enum WorkWeekType {
  MondayToFriday = 'mondayToFriday',
  MondayToSaturday = 'mondayToSaturday',
  FullWeek = 'fullWeek',
}

export interface TeanantLocation {
  id: string;
  country: string;
  city: string;
  streetName: string;
  postalCode: string;
  countryCode: string;
  timezone?: string;
  workingHourStart?: string;
  workingHourEnd?: string;
  weekWork: WorkWeekType;
  zipCode?: string;
  address?: string;
  currency?: CurrencyType;
  dateTimeFormat?: string;
  isDefaultLocation?: boolean;
  legalDaysOff?: LegalDaysOff[];
}
