import { Row } from 'antd';
import DataGrid from 'components/common/DataGrid/DataGrid';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';

const FeatureFlagsList: React.FC = () => {
  const { columns, take } = useHeader();
  const {
    list,
    pagination,
    loading,
    updating,
    getAllFeatureFlagTenants,
    getTenantFeatureListPagination,
  } = useTenantFeatureData();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('id')) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    const params = {
      pageOptions: { page: 1, take },
    };
    if (searchParams.get('id')) {
      getTenantFeatureListPagination(searchParams.get('id') || '', params);
    } else {
      getAllFeatureFlagTenants(params);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const params = {
      pageOptions: { page, take },
    };
    if (searchParams.get('id') === null || searchParams.get('id') === '') {
      getAllFeatureFlagTenants(params);
      return;
    }
    getTenantFeatureListPagination(searchParams.get('id') || '', params);
  };

  return (
    <Content position="stretch">
      <>
        {searchParams.get('id') !== null && searchParams.get('id') !== '' ? (
          <Row>
            {`${i18next.t('youHaveSelectedTenant')} ${list[0]?.tenant?.name}`}
          </Row>
        ) : (
          <Row>{`${i18next.t('youHaveNotSelectedTenant')}`}</Row>
        )}
        <DataGrid
          loading={loading || updating}
          columns={columns}
          data={list || []}
          paginationName="FeatureFlags"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
        />
      </>
    </Content>
  );
};

export default FeatureFlagsList;
