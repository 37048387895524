// import { Tag } from 'antd';
import React from 'react';
import OpenPositionActions from 'components/Actions/OpenPositionActions';
import { OpenPositionType } from 'interfaces/OpenPosition';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import i18next from '../i18n';

export const OpenPositionsColumnsMobile = [
  {
    dataIndex: 'openPositions',
    key: 'openPositions',
    render: (_: string, item: OpenPositionType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('positionName')}: `}</strong>
          {item?.name}
        </div>

        <div>
          <strong>{`${i18next.t('workPosition')}: `}</strong>
          {item?.workPosition?.name}
        </div>
        <div>
          <strong>{`${i18next.t('employmentType')}: `}</strong>
          {item?.employeeType?.name}
        </div>
        <div>
          <strong>{`${i18next.t('validFrom')}: `}</strong>
          {convertUTCtoFormatedLocalTime(item?.validFrom)}
        </div>
        <div>
          <strong>{`${i18next.t('validTo')}: `}</strong>
          {convertUTCtoFormatedLocalTime(item?.validTo)}
        </div>
        <div>
          <OpenPositionActions id={item.id} name={item.name} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const OpenPositionsColumns = [
  {
    title: i18next.t('positionName'),
    dataIndex: 'name',
    key: 'name',
    width: 150,
    ellipsis: true,
    sorter: (a: OpenPositionType, b: OpenPositionType) =>
      a.name.localeCompare(b.name),
    render: (_: string, item: OpenPositionType) => (
      <Styled.TextBold>{item?.name}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('workPosition'),
    dataIndex: 'workPositions',
    key: 'workPositions',
    ellipsis: true,
    width: 150,
    render: (_record: string, row: OpenPositionType) => (
      <Styled.Text key={`${row.id}`}>{row?.workPosition?.name}</Styled.Text>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('employmentType'),
    dataIndex: 'Employment Type',
    key: 'employmentType',
    width: 120,
    render: (_record: string, row: OpenPositionType) => (
      <Styled.Text key={`${row.id}`}>{row?.employeeType?.name}</Styled.Text>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('validFrom'),
    dataIndex: 'validFrom',
    key: 'validFrom',
    width: 120,
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('validTo'),
    dataIndex: 'validTo',
    key: 'validTo',
    width: 120,
    // eslint-disable-next-line no-confusing-arrow
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 120,
    margin: '10px',
    render: (record: string, row: OpenPositionType) => (
      <OpenPositionActions id={row.id} name={row?.name} />
    ),
    default: false,
    order: 5,
  },
];
